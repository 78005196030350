.order-return-page {
    .order-return-card {
        border-bottom: 1px solid $off-white;
        margin-top: 5px;

        .return-card-header {
            display: flex;
            padding: 15px;
            border: 1px solid $off-white;
            justify-content: space-between;

            @include media-breakpoint-up(md) {
                padding: 30px;
            }
        }

        .row-spacer {
            height: 15px;
            border-left: 1px solid $off-white;
            border-right: 1px solid $off-white;

            @include media-breakpoint-up(md) {
                height: 30px;
            }
        }

        .return-row-first {
            .return-product-info,
            .return-product-actions {
                padding-top: 0;
            }
        }

        .return-row-last {
            .return-product-info,
            .return-product-actions {
                padding-bottom: 0;
                border-bottom: 0;
            }
        }

        .return-card-col-1 {
            border-left: 1px solid $off-white;
            border-right: 1px solid $off-white;
        }

        .return-card-col-2 {
            border-left: 1px solid $off-white;
            border-right: 1px solid $off-white;

            @include media-breakpoint-up(md) {
                border-left: none;
            }
        }

        .return-product-info,
        .return-product-actions {
            margin-left: 15px;
            margin-right: 15px;

            @include media-breakpoint-up(md) {
                margin-left: 30px;
                margin-right: 30px;
            }
        }

        .return-product-info {
            height: 100%;
            padding-top: 30px;
            border-bottom: none;

            @include media-breakpoint-up(md) {
                border-bottom: 1px solid $off-white;
                padding-top: 30px;
                padding-bottom: 30px;
            }

            .item-image {
                display: inline-block;
                margin-bottom: 25px;

                .product-image {
                    width: 100px;
                    height: auto;
                    margin-right: 10px;
                }
            }

            .line-item-name {
                display: inline-block;
                vertical-align: top
            }

            .return-qty {
                margin-bottom: 15px;

                @include media-breakpoint-up(md) {
                    margin-bottom: 0px;
                }
            }

            .strike-through {
                text-decoration: line-through;
            }

            .non-adjusted-price {
                display: none;
            }
        }

        .return-product-actions {
            height: 100%;
            padding-top: 30px;
            padding-bottom: 30px;
            border-bottom: 1px solid $off-white;

            .return-product-btn {
                width: 100%;
                margin-bottom: 25px;
            }

            .get-label-btn {
                width: 100%;
            }
        }

        .return-reason-select-form-group {
            margin-bottom: 10px;
        }

        .form-group ~ .form-group {
            margin-top: 0;
        }
    }

    .submit-return-btn {
        width: 100%;
        display: block;
    }

    #print-label-link {
        width: 100%;
        text-transform: none;

        @include media-breakpoint-up(md) {
            width: auto;
            min-width: 400px;
        }
    }

    .return-row {
        background-color: $white;
    }
}

.order-return-success-asset-container, .ship-label-img {
    padding-top: 20px;
}

#order-print-label-container {
    position: absolute;
    left: 0;
    top: 0;
    visibility: hidden;
    z-index: 2147483601;
}

@media print {
    header, footer, .page-designer, .breadcrumb-styling, #print-label-link {
        display: none;
    }

    #accountHeaderOrderReturnSuccess {
        display: block;
    }

    #order-print-label-container {
        position: relative;
        visibility: visible;
    }

    .page-break {
        page-break-after: always;
        page-break-inside: avoid;
        position: relative;
    }
}

.return-items {
    margin: 20px 0;

    .return-items-table {
        font-weight: bold;

        tr {
            border: 1px solid $black;
            &.return-items-table-header {
                background-color: $off-white;

                th {
                    border: 1px solid $black;
                    text-align: center;
                }
            }

            td {
                border: 1px solid $black;
                text-align: center;
                .return-image-container img {
                    max-width: 120px;
                }
            }
        }
    }
}

.page-break {
    page-break-after: always;
    page-break-inside: avoid;
    position: relative;
}

.return-row {
    background-color: $brand-secondary;
}