@import "~org_citizen/helperClasses";

p,
.p {
    font-family: $secondary-font;
    font-weight: $default-text-font-weight;
}

ul, ol {
    list-style-position: outside;
    
    li {
        padding: 5px 0;
    }
}

// Create button classes, e.g. .btn-primary
.btn {
    @include button;
    &-primary {
        @include button(primary);
    }
    &-secondary,
    &-outline-primary {
        @include button(secondary);
    }
    &-accent-red {
        @include button(accent-red);
    }
    &-solid-black {
        @include button(solid-black);
    }
    &-link {
        @include button(link);
    }
    &-sm {
        @include button(null, null, null, null, small);
        &.btn-primary {
            @include button(primary, null, null, null, small);
        }
        &.btn-secondary,
        &.btn-outline-primary {
            @include button(secondary, null, null, null, small);
        }
        &.btn-accent-red {
            @include button(accent-red, null, null, null, small);
        }
        &.btn-solid-black {
            @include button(solid-black, null, null, null, small);
        }
        &.btn-link {
            @include button(link, null, null, null, small);
        }
    }
    &-block {
        @include full-width;
    }
}


.btn-checkout {
    color: white;
    background-color: $black;
    border-color: $black;
    
    &:not(:disabled) {
        &:active {
            background-color: $black;
            border-color: $black
        }

        &:hover {
            color: $white;
            background-color: $pure-black;
            border-color: $pure-black;
        }

        &:focus {
            background-color: $pure-black;
            border-color: $pure-black;
        }
    }
}

.btn-checkout-inverse {
    color: $black;
    background-color: $white;
    border-color: $black;
    
    &:not(:disabled) {
        &:active {
            color: $pure-black;
            border-color: $pure-black;
        }

        &:hover {
            color: $pure-black;
            border-color: $pure-black;
        }

        &:focus {
            color: $black;
            border-color: $black;
        }
    }
}

.add-to-cart-product-option,
.open-option-modal {
    @include button(solid-black);
}