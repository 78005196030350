// product line item dividers
@import '~core/components/productCard';

.product-info {
    .product-card-footer {
        .line-item-total-price, del, .sales {
            word-wrap: normal;
        }
    }
}

.product-info,
.product-summary,
.multi-shipping {
    .line-item-name {
        white-space: normal;
    }
}

.line-item-option {
    display: flex;
    flex-direction: column;
    width: 100%;

    .edit {
        margin-top: 5px;
    }
}