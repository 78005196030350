@import "~core/components/common";

body {
    font-family: $secondary-font;
}

.shipping-discount {
    color: $dark-gray;
}

// add padding-bottom for transparent-header on PDP page.
// this ensures top content like breadcrumbs arent obscured by the transparent-header
[data-action=Product-ShowInCategory],
[data-action=Product-Show] {
    .transparent-header {
        padding-bottom: 80px;

        @include media-breakpoint-up(md){
            padding-bottom: 70px;
        }

        @include media-breakpoint-up(md){
            padding-bottom: 80px;
        }
    }
}

.login-oauth .oauth-google {
    margin-bottom: 0;
}