@import "~core/components/productTiles";

.product-tile:not(.product-tile-horizontal) {
    background-color: $brand-secondary;
    margin-bottom: 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .product-grid & {
        margin-bottom: 0;
    }
    

    @include media-breakpoint-up(md) {
        padding: 16px;
    }

    .product-tile-image-container,
    .image-container {
        padding: 35px 0 0;

        @include media-breakpoint-up(md) {
            padding: 30px 30px 0;
        }
    }

    .tile-image {
        padding: 0;
    }

    .tile-body {
        position: relative;
        padding: 0 5px 10px;
        flex: 1 auto;
        display: flex;
        flex-flow: column nowrap;

        .price {
            margin-bottom: 0;
            font-style: normal;
            font-weight: 600;
            line-height: 21px;
            font-size: 12px;
            color: $product-tile-price-color;

            @include media-breakpoint-up(lg) {
                font-size: 14px;
                font-weight: 600;
            }

            del {
                margin-right: 5px;
            }

            del,
            .strike-through {
                color: $product-tile-price-strike-through-color;
            }

            del ~ .sales {
                float: none;
                margin-right: 0;
                color: $brand-primary;
            }

            .sales {
                .value {
                    @include media-breakpoint-down(sm) {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .image-container {
        flex: 0 auto;
    }

    .pdp-link {
        margin-top: 5px;
        margin-bottom: 13px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 24px;
        }

        .link {
            margin-bottom: 5px;
            font-family: $primary-font;
            font-size: 24px;
            font-weight: 400;
            line-height: 26px;
            color: $product-tile-title-color;

            @include media-breakpoint-up(lg) {
                margin-bottom: 8px;
                font-size: 28px;
                line-height: 32px;
            }
        }

        .subtitle {
            font-family: $secondary-font;
            font-size: 10px;
            font-weight: 500;
            line-height: 12px;
            letter-spacing: 0.08em;
            text-transform: uppercase;

            @include media-breakpoint-up(lg) {
                line-height: 16px;
                text-align: inherit;
            }
        }

        // if no subtitle, then pad link to ensure all product tiles have even height
        &.no-subtitle {
            padding-bottom: 12px;
            @include media-breakpoint-up(lg) {
                padding-bottom: 16px;
            }
        }
    }

    .product-id {
        display: none;
        width: calc(100% - 30px);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: rotate(90deg) translate(0, 0);
        text-align: center;
        color: $gray;
        font-size: 11px;
        line-height: 16px;
        font-weight: 500;

        @include media-breakpoint-up(sm) {
            display: inline-block;
        }

        @include media-breakpoint-up(md) {
            font-size: 13px;
            transform: rotate(90deg) translate(7px, 0);
        }
    }

    .quickview {
        right: -$spacer;

        &.btn {
            position: absolute;
            width: auto;
            left: -16px;
            right: -16px;
            bottom: -16px;
            border:none;
        }

        &:hover {
            color: $white;
            opacity: 1;
            z-index: 1;
        }
    }

    &:hover .product-tile-image-container:after {
        content: none;
    }

    .wishlist {
        display: none;

        .wishlist-icon {
            background-color: transparent;

            &.before:before {
                font-size: 15px;

                @include media-breakpoint-up(md) {
                    font-size: 20px;
                }
            }
        }

        @include media-breakpoint-up(md) {
            display: block;
        }
    }
}

.product-tile-horizontal {
    color: $dark-gray;
    text-align: center;
    font-style: normal;

    @include media-breakpoint-up(md) {
        text-align: left;
    }

    &:hover .product-tile-image-container:after {
        content: none;
    }

    .product-tile-horizontal-inner-wrap {
        padding-top: 20px;
        padding-bottom: 10px;

        .product-tile-image-container {
            margin-top: auto;
            margin-bottom: 0;

            @include media-breakpoint-up(md) {
                max-width: 284px;
            }
        }

        .product-tile-details-container {
            margin-top: 0;
            margin-bottom: auto;
        }
    }

    .image-container {
        margin-bottom: 24px;
        margin-top: 24px;
        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    //image
    .product-tile-image-link {
        max-width: 224px;
        margin-left: auto;
        margin-right: auto;
        display: block;

        @include media-breakpoint-up(md) {
            max-width: none;
        }
    }

    .product-tile-image {
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .tile-body {
        padding-bottom: 0;
        padding-right: 24px;
    }

    // name
    .tile-headline {
        font-family: $primary-font;
        font-weight: 400;
        font-size: 24px;
        line-height: 1.333em;
        text-transform: none;

        @include media-breakpoint-up(md) {
            font-size: 28px;
            line-height: 1.142em;
        }

        .pdp-link {
            line-height: inherit;
            font-family: inherit;
            text-transform: inherit;
            font-weight: inherit;
            font-size: inherit;
            color: inherit;
        }
    }

    .tile-headline {
        margin-bottom: 4px;
        @include media-breakpoint-up(md) {
            margin-bottom: 3px;
        }
    }

    .tile-subHeadline {
        font-family: $secondary-font;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.08em;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
            font-size: 13px;
            line-height: 16px;
            letter-spacing: 0.1em;
        }
    }

    .btn {
        letter-spacing: normal;
    }

    .tile-description {
        font-family: $secondary-font;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 265px;
        padding-top: 0;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;

        @include media-breakpoint-up(md) {
            font-size: 16px;
            line-height: 22px;
            -webkit-line-clamp: 3;
            margin-left: 0;
            margin-top: 37px;
            margin-bottom: 16px;
            max-width: 375px;
        }
    }

    .product-tile-horizontal-button {
        .btn {
            margin-top: 20px;
        }

        .btn-tertiary {
            text-decoration: none;
            position: relative;

            &:after {
                content: '';
                display: block;
                height: 2px;
                width: 100%;
                background: currentColor;
            }
        }
    }
}

.product {
    position: relative;
}

.product-tile {
    position: relative;
    .tile-body {
        padding-top: 0;
    }

    .badge-product-container {
        &.badge-image-over {
            top: 5px;
            left: 5px;
        }

        @include media-breakpoint-up(md) {
            &.badge-image-over {
                top: 16px;
                left: 16px;
            }
        }

        .badge-product {
            display: none;
            // only show first badge on mobile
            &:first-of-type {
                display: unset;
            }

            // show all badges on desktop
            @include media-breakpoint-up(md) {
                display: unset;
            }
        }
    }
}

/** ensure product tiles that have color class utility have prices that inherit color  **/
[class*=text-] {
    .product-tile:not(.product-tile-horizontal) .tile-body .price {
        del, 
        .strike-through {
            color: inherit;
        }

        del ~ .sales {
            color: inherit;
        }
    }
}

// Review.io Dark / Light rating star themeing

// reviews.io theme styling
.light-theme {
    &.reviews-container {
        .review-star-svg.review-star-svg {
            fill: $product-tile-rating-star-fill-color;

            &.empty-star.empty-star {
                fill: $product-tile-rating-star-light-empty-fill-color;
            }
        }
    }
}

.dark-theme {
    &.reviews-container {
        .review-star-svg.review-star-svg {
            fill: $product-tile-rating-star-fill-color;

            &.empty-star.empty-star { 
                fill: $product-tile-rating-star-dark-empty-fill-color;
            }
        }
    }
}


/* dark / light theme updates */
.star-rating-body {
    background-color: $white;

    .star-graph {
        &:hover {
            background-color: #F7F7F7;
        }
    }
}

.dark-theme {
    .star-rating-body {
        background-color: $medium-gray;

        .star-graph {
            &:hover {
                background-color: $black;
            }
        }
    }
}

// Review start rating styles necessary for product Tiles
.reviews-star-average-rating {
    height: 20px; 
}
  
.rating-star {
    position: relative;
    margin-right: 2px;
    align-self: start; 

    .partial {
        display: inline-block;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0; 
    }
}

.review-star-svg.review-star-svg {
    width: 18px;
    height: 18px;
    fill: $product-tile-rating-star-fill-color;

    &.empty-star.empty-star {
        fill: $product-tile-rating-star-light-empty-fill-color;
    }
}

// product tile carousel
.product-tile-slider-container {
    &.slider-container {
        .tns-controls {
            button {
                opacity: 0;
                transition: opacity 160ms ease-in;

                &:before {
                    background-color: transparent;
                    color: $black;
                }
            }
        }

        &:hover {
            button {
                opacity: 1;
            }
        }

        .tns-controls {
            button {
                &:before {
                    color: #C8C8C8;
                }

                &:hover {
                    &:before {
                        color: currentColor;
                    }
                }
            }

            [data-controls=prev]:before {
                content: "\f060";
                font-family: $font-awesome;
            }
    
            [data-controls=next]:before {
                content: "\f061";
                font-family: $font-awesome;
            }

            @include media-breakpoint-up(md) {
                [data-controls=prev] {
                    left: -30px;
                }

                [data-controls=next] {
                    right: -30px;
                }
            }
        }
    }
}

// dark theme override
.dark-theme {
    .product-tile-slider-container {
        &.slider-container {
            .tns-controls {
                button {
                    &:before {
                        color: $white;
                    }
                }
            }
        }
    }   
}


// these importants are required to override styles from .product-tile:not(.product-tile-horizontal) on line 3
.product-tile-alternate {
    .product-tile-image-container,
    .image-container {
        padding: 35px 0 !important;

        @include media-breakpoint-up(md) {
            padding: 0 !important;
        }
    }

    .badge-container-overflow {
        min-height: 28px;
        max-height: 28px;
        overflow: hidden;
    }

    .badge-container-placeholder {
        min-height: 28px;
    }
}

.reviews-stats-container {
    .star-rating-body {
        .star-bar {
            .star-bar-fill.star-bar-fill {
                background-color: $product-tile-rating-star-fill-color;
            }
        }

        .star-graph-star.star-graph-star {
            fill: $product-tile-rating-star-fill-color;
        }
    }
}

.product-tile {
    &.product-id-hide {
        .product-id {
            display: none;
        }
    }
}