@import "~core/components/slider";

.slider-container {
    &[data-slider-mode=gallery],
    &[data-slider-animatein=fade-in],
    &[data-slider-animatein=zoom-in] {
        [id^=tns],
        .slider[id^=tns] {
            > .tns-item {
                // OVER WRITE DEFAULT SLIDER SCSS
                padding-right: 0 !important;
            }
        }
    }
    
    // slider bullets
    &.pagination-style-dots {
        .tns-nav {
            button {
                border-radius: 100%;
            }
        }
    }

     // slider nav buttons
     .tns-controls {
        button {
            color: $off-white;

            &:before,
            &:after {
                transition: all 200ms ease-in-out;
                color: inherit;
            }

            &:before {
                height: $slider-button-icon-size;
                width: $slider-button-icon-size;
                line-height: $slider-button-icon-size;

                border-width: $slider-button-border-width;
                border-color: currentColor;
                border-style: solid;
                border-radius: 50%;
                
                background-color: $white;
            }

            &:hover {
                &:before {
                    color: inherit;
                }
            }
        }

        [data-controls=prev],
        [data-controls=next] {
            &:before {
                font-size: 0;
            }
            &:after {
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                font: var(--fa-font-thin);
                font-size: $slider-button-font-size;
                font-weight: 300;
                display: inline-block;
                width: $slider-button-icon-size;
                position: absolute;
                top: 50%;
                transform: translate3d(0, -50%, 0);
            }
        }
    
        [data-controls=prev] {
            &:after {
                content: "\f060";
            }
        }
    
        [data-controls=next] {
            justify-content: flex-end;
    
            &:after {
                content: "\f061";
            }
        }
     }

    &.slider-buttons-bottom,
    &.slider-container-cross-sells.slider-buttons-bottom {
        margin-bottom: 48px;

        .tns-controls {
            button {
                height: 36px;
                top: calc(100% + 24px);
            }
        }

        [data-controls=prev] {
            left: calc(50% - 60px);
        }

        [data-controls=next] {
            right: calc(50% - 60px);
        }
    }

    &.slider-buttons-hidden {
        .tns-controls {
            @include media-breakpoint-down(sm) {
                display: block;   
                opacity: 0;
            }
        }
    }

    &.slider-buttons-hidden-md {
        .tns-controls {
            @include media-breakpoint-between(md, lg) {
                display: block;   
                opacity: 0;
            }
        }
    }
    &.slider-buttons-hidden-lg {
        .tns-controls {
            @include media-breakpoint-up(lg) {
                display: block;   
                opacity: 0;
            }
        }
    }
}

// Thumbnails
.slider-thumbnails {
    .slide {
        &::after {
            content: '';
            pointer-events: none;
            @include absolute-cover;
            width: 6px;
            height: 6px;
            border-radius: 100%;
            background-color: transparent;
            transition: background-color $transition-speed ease-out;
        }
        &.tns-nav-active::after {
            background-color: transparent;
        }
    }
}

// product tile slider class - ensures that all product tiles have same height
.product-tile-slider {
    display: flex;
    align-items: stretch;

    .product,
    .category-tile,
    .image-text-block,
    .photo-tile-figure,
    .photo-hotspots {
        height: 100%;
    }
    .photo-hotspots,
    .photo-tile-figure {
        overflow: hidden;
    }

    .photo-tile-container {
        height: 100%;

        > .row {
            height: 100%;

            > .col-12 {
                height: 100%;

                .photo-tile-image {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .photo-hotspots {
        .photo-tile-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .category-tile,
    .image-text-block {
        &:not(.text-placement-below) {
            .image-cropper {
                height: 100%;
                > .image-wrapper {
                    height: 100%;
                }
            }
        }

        &.text-placement-below {
            display: flex;
            flex-direction: column;

            .image-cropper {
                max-height: none;
                margin: 0;
                flex-grow: 1;

                .image-wrapper {
                    height: 100%;
                }
            }
        }
    }
}

// tighter padding on sliders that have 4 product tiles on screen at a time
[data-slider-items-md="4"] {
    @include media-breakpoint-up(lg) {
        .product-tile:not(.product-tile-horizontal) {
            padding: 4px;

            .badge-product-container {
                &.badge-image-over {
                    top: 9px;
                    left: 9px;
                }
            }

            .compare-badge-location:not(.move) {
                top: 5px;
                right: 5px;
            }

            .quickview {
                &.btn {
                    left: -4px;
                    right: -4px;
                    bottom: -4px;
                }
            }
        }
    }
}

[data-slider-items-lg="4"] {
    @include media-breakpoint-up(xl) {
        .product-tile:not(.product-tile-horizontal) {
            padding: 4px;

            .badge-product-container {
                &.badge-image-over {
                    top: 9px;
                    left: 9px;
                }
            }

            .compare-badge-location:not(.move) {
                top: 5px;
                right: 5px;
            }

            .quickview {
                &.btn {
                    left: -4px;
                    right: -4px;
                    bottom: -4px;
                }
            }
        }
    }
}

// Gift Certficate slider options
.gift-card-slider-container {
    position: relative;
    padding-left: $slider-button-width;
    padding-right: $slider-button-width;
    letter-spacing: 0; // this prevents sizing issues caused by inherited letter-spacing
    @include media-breakpoint-down(sm) {
        &:not(.slider-bullets-inside):not(.slider-bullets-hidden) {
            margin-bottom: $bullets-outside-size;
        }
    }
    @include media-breakpoint-only(md) {
        &:not(.slider-bullets-inside-md):not(.slider-bullets-hidden-md) {
            margin-bottom: $bullets-outside-size;
        }
    }
    @include media-breakpoint-up(lg) {
        padding-left: 0;
        padding-right: 0;

        &:not(.slider-bullets-inside-lg):not(.slider-bullets-hidden-lg) {
            margin-bottom: $bullets-outside-size;
        }
    }
    .gift-card-slider {
        &.tns-gallery .tns-slide-active {
            z-index: 1;
        }
    }
    .slide {
        transform: translateZ(0); // fix for text flicker bug in chrome
    }

    // slider nav buttons
    .tns-controls button {
        @include vertical-center;
        cursor: pointer;
        user-select: none;
        z-index: 2;
        width: 60px;
        height: 100%;
        background-color: rgba(0, 0, 0, 0);
        border: none;
        display: flex;
        text-align: center;
        transition: background-color $transition-speed ease-out;
        font-size: 0;
        padding: 0;

        &:before {
            align-self: center;
            transition: color $transition-speed ease-out;
            background-color: $black;
            color: $white;
            border-radius: 50%;
            width: 36px;
            height: 36px;
            line-height: 36px;
        }
        &:focus {
            outline: none;
        }
    }

    [data-controls='prev'] {
        @include icon($slider-button-prev-icon, before, $slider-button-icon-size, $slider-button-color);
        left: 0;
    }
    [data-controls='next'] {
        @include icon($slider-button-next-icon, before, $slider-button-icon-size, $slider-button-color);
        right: 0;
        justify-content: flex-end;
    }

    // slider bullets
    .tns-nav {
        @include horizontal-center;
        display: flex;
        bottom: 0;
        z-index: 2;
        bottom: -$bullets-outside-size;

        button {
            display: block;
            background: $slider-bullet-background;
            border: $slider-bullet-border;
            width: $slider-bullet-size;
            height: $slider-bullet-size;
            margin: $slider-bullet-margin-top $slider-bullet-margin-right $slider-bullet-margin-bottom $slider-bullet-margin-left;
            padding: 0;
            cursor: pointer;
            transition: transform $transition-speed ease-out;
            &:focus {
                outline: none;
            }
            &.tns-nav-active {
                background: $slider-bullet-background-active;
                border: $slider-bullet-border-active;
                transform: scale(#{$slider-bullet-size-active / $slider-bullet-size});
            }
        }
    }

    &.pagination-style-dots {
        .tns-nav {
            align-items: center;

            button {
                border-radius: 50%;
            }
        }
    }

    &.pagination-style-shortLines {
        .tns-nav {
            align-items: flex-end;

            button {
                background-color: $slider-short-lines-bullet-background-color;
                border: none;
                width: $slider-short-lines-bullet-size-width;
                height: $slider-short-lines-bullet-size-height;

                &.tns-nav-active,
                &:hover:not(.tns-nav-active) {
                    background-color: $slider-short-lines-bullet-active-background-color;
                    height: $slider-short-lines-bullet-active-size-height;
                    transition: background-color $transition-speed ease-in-out, height $transition-speed ease-in-out;
                }
            }
        }
    }

    // themes
    &.light {
        .tns-controls button {
            background-color: $slider-light-button-background;
            &::before {
                color: $slider-light-button-color;
            }
        }

        .tns-nav {
            button {
                background: $slider-light-bullet-background;
                border: $slider-light-bullet-border;
                &.tns-nav-active  {
                    background: $slider-light-bullet-background-active;
                    border: $slider-light-bullet-border-active;
                }
            }
        }
        .tns-nav-controller {
            &::before {
                color: $slider-light-bullet-play-icon-color;
            }
            &.pause::before {
                color: $slider-light-bullet-pause-icon-color;
            }
        }
        &.pagination-style-shortLines {
            .tns-nav {
                button {
                    background-color: $slider-light-short-lines-bullet-background-color;
                    &.tns-nav-active,
                    &:hover:not(.tns-nav-active) {
                        background-color: $slider-light-short-lines-bullet-active-background-color;
                    }
                }
            }
        }
    }

    [data-action='stop'],
    [data-action='start'] {
        display: none;
    }

    // style helper classes
    @include media-breakpoint-down(sm) {
        &.slider-buttons-hidden {
            padding-left: 0;
            padding-right: 0;
            .tns-controls {
                display: none;
            }
        }
        &.slider-bullets-hidden {
            .tns-nav {
                display: none;
            }
        }
        &.slider-buttons-inside {
            padding-left: 0;
            padding-right: 0;
        }
        &.slider-bullets-inside {
            .tns-nav {
                bottom: 0;
            }
        }
        @for $i from 1 through 40 {
            &.next-slide-#{$i} {
                .tns-inner {
                    padding-right: $i * 1%;
                }
                &.slider-buttons-hidden {
                    margin-right: -$spacer;
                }
            }
            &.prev-slide-#{$i} {
                .tns-inner {
                    padding-left: $i * 1%;
                }
                &.slider-buttons-hidden {
                    margin-left: -$spacer;
                }
            }
        }
    }
    @include media-breakpoint-only(md) {
        &.slider-buttons-hidden-md {
            padding-left: 0;
            padding-right: 0;
            .tns-controls {
                display: none;
            }
        }
        &.slider-bullets-hidden-md {
            margin-bottom: 0;
            .tns-nav {
                display: none;
            }
        }
        &.slider-buttons-inside-md {
            padding-left: 0;
            padding-right: 0;
        }
        &.slider-bullets-inside-md {
            margin-bottom: 0;
            .tns-nav {
                bottom: 0;
            }
        }
        @for $i from 1 through 40 {
            &.next-slide-md-#{$i} {
                .tns-inner {
                    padding-right: $i * 1%;
                }
            }
            &.prev-slide-md-#{$i} {
                .tns-inner {
                    padding-left: $i * 1%;
                }
            }
        }
    }
    @include media-breakpoint-up(lg) {
        &.slider-buttons-hidden-lg {
            padding-left: 0;
            padding-right: 0;
            .tns-controls {
                display: none;
            }
        }
        &.slider-bullets-hidden-lg {
            margin-bottom: 0;
            .tns-nav {
                display: none;
            }
        }
        &.slider-buttons-inside-lg {
            padding-left: 0;
            padding-right: 0;
        }
        &.slider-bullets-inside-lg {
            margin-bottom: 0;
            .tns-nav {
                bottom: 0;
            }
        }
        @for $i from 1 through 40 {
            &.next-slide-lg-#{$i} {
                .tns-inner {
                    padding-right: $i * 1%;
                }
            }
            &.prev-slide-lg-#{$i} {
                .tns-inner {
                    padding-left: $i * 1%;
                }
            }
        }
    }
}

// slider light theme
.slider-container.light {
    .tns-controls {
        button {
            color: $white;

            &:before {
                color: inherit;
                background-color: $dark-gray;
                border-color: currentColor;
            }
        }
    }

    .tns-nav {
        button {
            background-color: $slider-dark-bullet-background;
        }

        &.tns-nav-active {
            background-color: $slider-dark-bullet-background-active;
        }
    }

    .slider-pagination-longlines {
        .line {
            &:after {
                background-color: $slider-dark-bullet-background;
            }   

            &.tns-nav-active {
                &:after {
                    background-color: $slider-dark-bullet-background-active;
                }
            }
        }
    }
}

// slider longlines navigation
.slider-pagination-longlines {
    z-index: 10;
    margin-top: 38px;
    
    .line {
        flex-grow: 1;
        cursor: pointer;
        height: $slider-long-lines-button-height;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: $slider-long-lines-gutter;
        margin-left: $slider-long-lines-gutter;

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: $slider-long-lines-height;
            flex-grow: 1;
            align-self: center;
            justify-self: center;
            background-color: $slider-long-lines-background-color;
            transition: all 200ms ease-in-out;
        }

        &.tns-nav-active {
            &:after {
                background-color: $slider-long-lines-background-color-active;
            }
        }
    }
}