@import "~core/components/hero";

// set css variables for use when calculating first screen-height hero (homepage)
:root {
    --promo-bar-height: 32px;
    --header-height: 46px;

    @include media-breakpoint-up(md) {
        --header-height: 70px;
    }

    @include media-breakpoint-up(lg) {
        --promo-bar-height: 40px;
        --header-height: 80px;
    }
}

.hero {
    // hero half and half layout
    &.half-and-half {
        @include media-breakpoint-up(md) {
            .full-width-content & {
                .hero-media {
                    max-width: 50%;
                }
            }
        }
    }

    // remove max-width class from hero-container
    .hero-container {
        @include media-breakpoint-up(xl) {
            .full-width-content & {
                max-width: none;
            }
        }
    }
}

.hero-text {
    .hero-title:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6) {
        text-transform: none;
        font-family: inherit;
    }

    a {
        &:not(.btn) {
            font-family: inherit;
            color: inherit;
        }

        &.btn {
            &:hover {
                opacity: 1;
            }
        }
    }

    .hero-button {
        margin-top: 60px;
    }

    .megamenu-link-list ul {
        li a {
            @include navigation-link-mega-menu;
        }
    }
}

.hero-banner-static-hotspot {
    .hero-embedded-component {
        width: 0;
        height: 0;
        position: absolute;
        left: 40px;
        bottom: 40px;
    }
}

.hero {
    &.half-screen {
        min-height: 50vh;
    }

    &.screen-height {
        // first hero on page (full screen hero)
        .experience-main > .experience-component:first-child & {
            min-height: calc(100vh - var(--header-height));
            .multi-brand & {
                min-height: calc(100vh - var(--header-height));
            }
            @include media-breakpoint-up(md) {
                min-height: calc(100vh - var(--header-height));
                .multi-brand & {
                    min-height: calc(100vh - var(--header-height));
                }
            }
            @include media-breakpoint-up(lg) {
                min-height: calc(100vh - var(--header-height));
                .multi-brand & {
                    min-height: calc(100vh - var(--header-height));
                }
            }
        }

        // first hero on page - transparent header setting is on
        .transparent-header~div .experience-main > .experience-component:first-child & {
            min-height: 100vh;
            .multi-brand & {
                min-height: 100vh;
            }
            @include media-breakpoint-up(md) {
                min-height: 100vh;
                .multi-brand & {
                    min-height: 100vh;
                }
            }
            @include media-breakpoint-up(lg) {
                min-height: 100vh;
                .multi-brand & {
                    min-height: 100vh;
                }
            }
        }

        // ensure video always goes full height on screenheight
        .hero-media.video-container .video-aspect-ratio video {
            width: auto;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }

    // if showing down down arrow, and hero content is aligned to bottom
    // then add padding to hero so arrow doesn't overlap hero content (mobile)
    &.hero-down-down-arrow-mobile {
        &.align-items-end {
            @include media-breakpoint-down(sm) {
                padding-bottom: 100px;
            }
        }
    }

    // if showing down down arrow, and hero content is aligned to bottom
    // then add padding to hero so arrow doesn't overlap hero content (desktop)
    &.hero-down-down-arrow-desktop {
        &.align-items-md-end {
            @include media-breakpoint-up(md) {
                padding-bottom: 100px;
            }   

            @include media-breakpoint-up(lg) {
                padding-bottom: 70px;
            }   
        }
    }
}
