@import "~org_citizen/components/quickView";

.quick-view-dialog {
    .modal-body {
        .product-detail .promotions .promotion {
            color: $red;
        }
        .product-data {
            background-color: $white;
        }

        .product-detail .primary-images, 
        .pdp-collapsible-content-section .primary-images {
            margin-top: 0;
        }

        .add-to-cart-global {
            &:not(.disabled) {
                background-color: $black;
                color: $white;

                &:hover {
                    background-color: $white;
                    color: $black;
                }
            }
        }
    }
}