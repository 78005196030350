@import '~org_citizen/product/detail';

// 850px and the matching 85vh is maximum supported value by the container if requested larger
$pdpMaxHeight: 800px;

.pdp-wrapper,
.pdp {
    color: $brand-primary;
    background-color: $white;

    /** LIGHT THEME PDP **/
    &.light-theme {
        color: $brand-primary;
        background-color: $white;

        .product-detail {
            .custom-select {
                color: $black;
                border-color: currentColor;
                filter: none;
            }

            .product-number .product-id {
                color: inherit;
                text-transform: uppercase;
            }

            .pdp-shipping-icon {
                filter: none;
            }

            .cart-and-ipay {
                .add-to-cart {
                    &.btn {
                        color: $black;
                        background-color: transparent;
                        border-color: currentColor;
       
                        &:hover {
                            color: $white;
                            background-color: $black;
                        }
                    }
                }
            }
        }

        .addtocart-sticky-bar {
            background: $pdp-sticky-addtocart-background;
        }
    }
}

.product-detail,
.pdp-collapsible-content-section {
    color: $black;
    background-color:$white;

    .product-data {
        @include media-breakpoint-up(md) {
            margin-top: 36px;
            margin-bottom: 100px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 25px;
        }

        .badge-product-container {
            margin-bottom: 10px;
        }

        .badge-product {
            padding: 0;
            margin-right: 0;
            margin-bottom: 0;
        }
        
        .badge-product + .badge-product::before {
            content: " | ";
            padding-right: 3px;
        }
    }

    .product-subtitle {
        font-size: $h3-font-size;
        line-height: $h3-line-height;
        letter-spacing: $h3-letter-spacing;
        font-weight: 700;
        text-transform: uppercase;
    }

    .promotions {
        .promotion {
            color: $brand-primary;
        }
    }

    .product-name-container {
        margin-bottom: 0;
    }

    .product-name,
    h1.product-name {
        font-weight: 200;
        font-size: $h2-font-size;
        line-height: 1.5;
        white-space: break-spaces;
        color: inherit;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }

    h2.product-name {
        text-transform: none;
        font-weight: 400;
        font-size: 32px;
        line-height: $h2-line-height;
        white-space: break-spaces;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }

    .product-number {
        p {
            font-size: $h4-font-size;
        }

        .product-id {
            color: $gray;
            text-transform: $h4-text-transform;
            font-weight: 500;
            font-size: 14px;
        }
    }
   
    .pdp-text-content {
        font-family: $primary-font;
        font-size: $default-text-font-size;
        font-weight: $default-text-font-weight;
        letter-spacing: $default-text-letter-spacing;
        line-height: $default-text-line-height;

        @include media-breakpoint-up(md) {
            font-size: $default-text-font-size;
        }
    }

    .show-more-text-actions {
        font-size: $default-text-font-size;
        letter-spacing: normal;
        font-weight: 600;
        padding-right: 14px;
    }
    
    .custom-select {
        color: $brand-primary;
        border-color: currentColor;
        filter: none;
    }

    .qty-cart-container {
        margin-top: 12px;
        
        .quantity {
            @include media-breakpoint-up(md) {
                margin-top: 0;
            }
        }
    }

    .prices {
        margin-top: 0;
        margin-bottom: 0;
        
        .price {
            margin-top: 0;
            font-family: $primary-font;
            font-size: 17px;
            font-weight: 700;
            letter-spacing: 2px;
            color: $brand-primary;
        }
    }

    .cart-and-ipay {
        justify-content: flex-start;

        .add-to-cart {
            &.btn {
                letter-spacing: normal;
                font-size: 17px;
                line-height: 1;
                font-weight: 400;
                text-decoration: none;
                width: $select-width;
                flex-grow: inherit;
                flex-basis: auto;
                color: $white;
                background-color: $black;
                border-color: $black;

                &:hover {
                    color: $black;
                    background-color: $white;
                }
            }
        }
    }

    button.dw-apple-pay-button.applePaySpecificityOverride {
        border-width: 1px;
    }

    .prices-add-to-cart-actions {
        display: flex;

        .cart-and-ipay {
            flex-grow: 1;
        }
        
        .product-list-enhancements-toggle-product:not(.move) {
            position: relative;
            background-color: $black;
            color: $white;
            width: 48px;
            margin-left: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        button.wishlist.product-list-enhancements-toggle-product {
            transition: all 200ms ease-out;
            &:hover {
                background-color: $white;
                color: $black;
                border: solid 1px;
                border-color: $black;
            }

            .wishlist-icon:before {
                font-size: 20px;
                line-height: 1;
            }
        }
    }

    .pdp-shipping-icon {
        filter: none;
    }

    .size-guide-asset-container {
        margin-left: auto;

        .text-link-secondary {
            font-size: $link-font-size;
            line-height: $link-line-height;
            letter-spacing: $link-letter-spacing;
            font-weight: $link-font-weight;
            padding-right: 14px;
        }
    }

    .product-description-label {
        font-weight: 500;
    }

    .availability-container {
        display: flex;
        align-items: center;
        margin-top: 0;
        margin-bottom: 0;

        p {
            @include pdp-availability;
            margin: 0;
            color: $brand-off-white;
        }
    }

    .primary-images {
        margin-top: 25px;
    }

    .primary-images-backdrop {
        background-color: $lighter-gray;
        padding-top: 16px;
        padding-bottom: 40px;

        @include media-breakpoint-up(md) {
            max-height: none;
            margin-left: auto;
            margin-right: auto;
            top: 25px;
        }
        
        @include media-breakpoint-up(lg) {
            padding-top: 0;
            padding-left: 0;
            padding-bottom: 20px;
        }
    }

    .primary-images {
        .video-js {
            background-color: transparent;
        }

        @include media-breakpoint-up(lg) {
            .primary-images-thumbnails {
                flex-basis: auto;
                max-width: none;
            }  

            .primary-images-main {
                &.product-has-thumbnails {
                    flex-basis: auto;
                    max-width: none;
                }
            }
        }
    }

    .primary-images-main,
    .primary-images-thumbnails {
        padding-right: 0;
        padding-left: 0;
    }

    // hide slide image when zoom is shown
    .primary-images-main {
        .zoom-is-open {
            opacity: 0;
        }

        &.product-has-thumbnails {
            @include media-breakpoint-up(lg) {
                margin: auto;
                max-width: none;
                flex-basis: auto;
            }
        }

        .slide {
            img {
                height: auto;

                @include media-breakpoint-up(md) {
                    height: auto;
                }

                @include media-breakpoint-down(lg) {
                    max-height: none;
                }
            }
        }

        .slide {
            img, 
            .video-container {
                @include media-breakpoint-up(lg) {
                    height: calc(80vh - 112px);
                    max-height: $pdpMaxHeight;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }

        .slider-container {
            @include media-breakpoint-up(lg) {
                padding: 0;
                height: calc(80vh - 112px);
                max-height: $pdpMaxHeight;
                object-fit: cover;
                object-position: center;
            }
        }
    }

    .zoomImg:hover {
        cursor: zoom-in;
    }

    .primary-images-thumbnails {
        display: block;
        margin-top: 22px;

        @include media-breakpoint-up(lg) {
            max-width: none;
            flex-basis: auto;
            margin-left: auto;
            margin-right: auto;
            margin-top: 0;
            padding-top: 20px;
        }

        .slider {
            .slide {
                margin-bottom: 0;
            }
        }

        .video-container { 
            .position-relative {
                img {
                    width: 100%;
                    max-width: 74px;
                }
            }

            .video-thumbnail-button {
                right: calc(50% + 6px);
            }
        }

        .primary-images-thumbnail {
            img {
                width: 100%;
                max-width: 74px;
                border: 1px solid $lightest-rain-cloud;
            }

            &:hover,
            &.tns-nav-active {
                img {
                    border-color: $black;
                }
            }
        }
    }

    .pdp-detail-attribute-list {
        .pdp-detail-attribute-list-item {
            font-family: $primary-font;

            &.pdp-detail-attribute-list-item-bold {
                font-family: $primary-font;
            }
        }
    }

    .pdp-component {
        h4,
        li,
        .setting-instructions-link,
        .pdp-setting-instructions-video-link {
            color: inherit;

            &:after {
                background-color: currentColor;
            }
        }
    }

    .features-attributes-row {
        color: inherit;
    }

    .features-col-2 {
        background-color: inherit;
    }

    .feature-text-container {
        text-align: left;

        h2 {
            margin-bottom: 10px;
            color: inherit;

            @include media-breakpoint-up(md) {
                margin-bottom: 25px;
            }
        }
    }

    .feature-text-list-item {
        margin-bottom: 6px;
        color: inherit;
        font-family: $primary-font;

        @include media-breakpoint-up(md) {
            margin-bottom: 12px;
        }
    }

    .pdp-additional-functions-list-item {
        font-family: $primary-font;
    }

    .addtocart-sticky-bar {
        background: $pdp-sticky-addtocart-background;

        h2.product-name {   
            font-size: 32px;
            font-weight: 700;
            letter-spacing: 2px;
        }

        .product-number {
            margin-bottom: 0;
        }

        .badge-product-container {
            margin-bottom:5px;
        }
    }

    .product-tile {
        .wishlist {
            &:not(.move) {
                top: initial;
            }
        }
    }

    .product-options {
        .options-select {
            width: $select-width;
        }

        .size-guide-link {
            opacity: 1;
        }

        .pdp-size-guide-trigger {
            cursor: pointer;
            text-decoration: none;
        }
    }
}

.internal-links {
    &:after {
        background-color: currentColor;
    }

    .internal-links-nav {
        .internal-links-nav-item {
            .internal-links-nav-link {
                color: inherit;

                &:after {
                    background-color: currentColor;
                }
            }
        }
    }
}

.pdp-collapsible-content {
    margin-bottom: 0;
    
    &.card {
        margin-bottom: 0;
    }

    .card-header {
        .card-title {
            h3 {
                font-family: $h3-font-family;
                font-weight: $h3-font-weight;
                text-transform: $h3-text-transform;
                letter-spacing: $h3-letter-spacing;
                font-style: $h3-font-style;
                color: $brand-primary;
                padding: 0;
            }
        }
    }
}

[class^=collapsible],
.collapsible-xl {
    &.active {
        .title {
            &::after {
                color: inherit;
            }
        }
    }
}

.collapsible-xl {
    &.active {
        .card-header {
            .title {
                &:after {
                    color: $brand-primary;
                }
            }
        }
    }
}

.collapsible-xl {
    .title {
        &:after {
            color: $brand-primary;
        }
    }
}

// PDP lower section
.pdp-content-section {
    .pdp-detail-attribute-list {
        .pdp-detail-attribute-list-item {
            font-family: $tertiary-font;
            font-weight: 200;
            font-size: 15px;
            line-height: 17px;
            padding-top: 14px;
            padding-bottom: 5px;

            &:first-child {
                padding-top: 17px;
            }

            &.pdp-detail-attribute-list-item-bold {
                font-family: $primary-font;
            }
        }
    }

    .pdp-detail-attribute-display-name {
        white-space: nowrap;
    }
    
    .pdp-component-headline {
        font-weight: 300;
        font-size: 20px;
    }

    .pdp-component {
        h4,
        li,
        .setting-instructions-link,
        .pdp-setting-instructions-video-link {
            color: inherit;
            font-size: 19px;
            line-height: 1;
            padding-top: 18px;
            padding-bottom: 18px;
            padding-left: 0;
            font-family: $primary-font;
            font-weight: 700;

            &:after {
                background-color: currentColor;
            }
        }

        h4 {
            font-weight: 200;
            font-size: 15px;
        }

        .collapsible-xl {
            .title {
                @include icon($icon-expand, after, $collapsible-header-icon-size, $collapsible-header-icon-color);

                &:after {
                    top: 12px;
                    right: 0;
                }
            }

            .card-header {
                background-color: transparent;
                border-bottom: 1px solid $lighter-gray;
            }

            .card-body {
                height: 0;
                overflow: hidden;
                transition: height 180ms ease-in-out;
            }

            &.active {
                .title {
                    @include icon($icon-collapse, after, $collapsible-header-icon-size, $collapsible-header-icon-color);
                }

                &:after {
                    top: 12px;
                    right: 0;
                }
            }

            &.card {
                border-top: 0;
                border-right: 0;
                border-left: 0;
                border-bottom: 1px solid $lighter-gray;
        
                .card-title {
                    padding-top: 0;
                    padding-bottom: 0;
                    padding-right: 0;
                }

                &:last-child {
                    border-bottom: 1px solid $lighter-gray;

                    .card-header,
                    .card-body {
                        border: 0;
                    }
                }
            }
        }       
    }

    .product-alternate-image-shadow {
        box-shadow: 12px 26px 49px -30px #555;
    }

    .pdp-attributes-col-1 {
        @include media-breakpoint-up(md) {
            // !important needed or overwritten from bootstrap
            padding-right: 28px !important;
        }
    }

    .product-alternate-image {
        margin-bottom: 10px;

        @include media-breakpoint-up(md) {
            margin-bottom: 28px;
        }
    }

    .pdp-feature-container {
        flex-basis: 430px;
    }

    .features-attributes-row {
        color: inherit;
    }

    .features-col-2 {
        background-color: inherit;
    }

    .feature-text-container {
        text-align: left;

        h2 {
            margin-bottom: 10px;
            color: inherit;

            @include media-breakpoint-up(md) {
                margin-bottom: 25px;
            }
        }
    }

    .feature-text-list-item {
        margin-bottom: 6px;
        color: inherit;
        font-family: $tertiary-font;

        @include media-breakpoint-up(md) {
            margin-bottom: 12px;
        }
    }

    .pdp-additional-functions-list-item {
        font-family: $tertiary-font;
    }

    .pdp-component-name-container {
        margin-bottom: 20px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 80px;
        }

        h2.product-name {
            text-transform: none;
            font-weight: 400;
            font-size: 36px;
            line-height: $h2-line-height;
            letter-spacing: normal;
            white-space: break-spaces;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }
        }

        img {
            margin-bottom: 2px;
        }
    }
}

// target product tiles outside of .product-detail
.page {
    &[data-action^=Product-] {

        .product-tile {
            .wishlist {
                &:not(.move) {
                    top: initial;
                }
            }
        }
    }
}

// 3/4 height grey box (part of frederique design)
.truncated-background {
    position: relative;

    &:before {
        display: block;
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 75%;
        background-color: $lighter-gray;
    }

    > img {
        position: relative;
    }
}

.product-feature-image {
    margin-bottom: 50px;
}

@include media-breakpoint-up(lg) {
    .product-detail {
        .primary-images-backdrop {
            top: 25px;
            transition: top 400ms ease-out;
        }
    }
    
    .scroll-direction-up.sticky-header {
        .product-detail {
            .primary-images-backdrop {
                top: 125px;
            }
        }
    }
}

// ensure pdp-attributes always take up available space
.pdp-attributes-col-1,
.pdp-attributes-col-2 {
    .product-alternate-image {
        flex-grow: 1;
        img {
            min-width: 100%;
            min-height: 100%;
            object-fit: cover;
        }
    }
}

.product-id-price-container,
.promotions-container {
    margin-bottom: 15px;
}

.promotions-container {
    .promotion-callout {
        color: $accent-red;
        font-weight: 800;
    }
}

// on pd pdp pages with the title completed on PD component
// then hide title coming from the content slot on productSlider.isml
.pdp-recommendations {
    &.pd-text-override {
        .product-slider-title {
            display: none;
        }
    }
}

.flagship-sub-headline {
    text-transform: unset;
}

.light-theme {
    .flagship-form-group-wrap.flagship-form-group-wrap {
        .btn-primary {
            border-color: $black;
            background-color: $black;
            color: $white;
    
            &:hover, &:focus {
                border-color: $black;
                background-color: $white;
                color: $black;
            }
        }
    }
}

.stock-notification-form {
    .modal-footer {
        .btn-primary {
            border-color: $black;
            background-color: $black;
            color: $white;
    
            &:hover, &:focus {
                border-color: $black;
                background-color: $white;
                color: $black;
            }
        }

        .btn-tertiary {
            &:hover, &:focus {
                border-color: $black;
                background-color: $black;
                color: $white;
            }
        }
    }
}