.navbar {
    padding: 0;
    &.bg-inverse {
        @include media-breakpoint-up(lg) {
            position: static; // needed for megamenu positioning
            .navbar-nav {
                flex-wrap: nowrap; // IE11 fix
                &+.nav-item {
                    margin-left: 0;
                }
                .nav-link {
                    padding: 0;
                    margin: $dropdown-item-padding $header-navbar-link-spacing/2;
                    white-space: nowrap;
                    padding-bottom: 10px;
                    margin-bottom: 0;
                }
            }
            .logo-center & {
                .nav-item {
                    &>.nav-link {
                        color: $header-navbar-color;
                        border-bottom: 2px solid transparent;
                    }
                    &.show {
                        .nav-link {
                            &:hover,
                            &:focus {
                                background-color: $header-navbar-background-hover;
                                color: $header-navbar-color-hover;
                                border-bottom: 2px solid $brand-primary;
                            }
                        }
                    }
                    &.highlight {
                        .nav-link {
                            color: $header-navbar-highlight-color;
                            &:hover,
                            &:focus {
                                background-color: $header-navbar-highlight-background-hover;
                                color: $header-navbar-highlight-color-hover;
                            }
                        }
                    }
                }
            }
        }
    }
}

.multilevel-dropdown {
    .dropdown-menu {
        top: 100%;
        border: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        @include media-breakpoint-up(lg) {
            box-shadow: $dropdown-menu-shadow;
            left: auto;
        }
    }
    .dropdown-item.custom-dropdown > .dropdown-toggle::after {
        transform: rotate(-90deg);
    }
    .dropdown-menu > .custom-dropdown > .dropdown-menu {
        @include media-breakpoint-up(lg) {
            top: auto;
            left: 99%;
        }
    }
    .navbar > .close-menu {
        display: none;
    }
    .close-menu .back {
        .icon-arrow-left {
            @include icon($icon-arrow-left, before, 25px, inherit);
        }

        .caret-left {
            @include icon($icon-arrow-left, after, 10px, inherit);
        }
    }
    .dropdown-menu.show {
        &>.dropdown-item.show > .dropdown-toggle::after {
            transform: rotate(90deg);
        }
    }
    .menu-group {
        width: 100%;
    }
    .menu-subcategories {
        padding-left: 0;
    }
    .last-item {
        > .dropdown-link {
            font-weight: 700;
        }
    }
}

// Megamenu
.navbar-nav .megamenu {
    @include media-breakpoint-up(lg) {
        position: static;

        &.show {
            z-index: 1;
        }

        &>.dropdown-menu {
            transform: none;
            padding: 0;
            box-shadow: none;
            background: $megamenu-background;
            z-index: 0;
            justify-content: center;
            margin-left: 0;
            left: 0;
            right: 0;

            &.mega-left{ 
                justify-content: flex-start; 
                right: 0;
                left: 0;
                margin-right: auto;
                margin-left: auto;
                max-width: 1340px;
            }
            &.show {
                display: flex;
            }
            &:before {
                content: '';
                @include horizontal-center;
                top: 0;
                bottom: 0;
                // 100 percent viewport minus scrollbar width
                width: calc(100vw - 15px);
                z-index: -1;
                background: $megamenu-background;
                box-shadow: $megamenu-box-shadow;
                border-top: 1px solid $mega-menu-border-gray;
                .logo-left & {
                    border-top: $dropdown-menu-border;
                }
            }
            .menu-subcategories {
                display: flex;
                flex-wrap: wrap;
                align-self: flex-start;
                padding-top: $megamenu-padding-top-bottom;
                padding-bottom: $megamenu-padding-top-bottom;
                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    flex-grow: 1; // IE11 fix for overlapping columns
                }
                &>.dropdown-item {
                    padding-left: $spacer;
                    padding-bottom: $spacer;
                }
                .dropdown-toggle {
                    .plus-icon {
                        display: none;
                    }
                    &:after {
                        display: none;
                    }
                }
                .dropdown-item {
                    width: auto;
                    border: none;
                    white-space: normal;
                    &:hover {
                        background: none;
                    }
                }
                .nav-link {
                    font-family: $megamenu-header-sub-category-font;
                    font-size: $megamenu-header-sub-category-font-size;
                    font-weight: $megamenu-header-sub-category-font-weight;
                    line-height: $megamenu-header-line-height;
                    text-transform: $megamenu-header-text-transform;
                    pointer-events: none;
                }
                .dropdown-link {
                    padding: $megamenu-link-padding-top $megamenu-link-padding-right $megamenu-link-padding-bottom $megamenu-link-padding-left;
                    display: inline-block;
                }
                .dropdown-menu {
                    margin-left: $megamenu-secondary-indentation;
                    position: static;
                    display: flex;
                    box-shadow: none;
                    border-radius: 0;
                    background: transparent;
                    flex-flow: row wrap;
                    align-content: flex-start;
                    .dropdown-item {
                        border: none;
                        flex-basis: 100%;
                        &.custom-dropdown {
                            flex-basis: 25%;
                        }
                        &.last-item {
                            display: block;
                            order: 1000; // pulls the "all (category)" to the bottom
                            padding-top: $spacer;
                            a {
                                font-weight: 600;
                                text-transform: capitalize;
                                line-height: 24px;
                                text-decoration: underline;
                            }
                           &:before {
                                content: '';
                                position: absolute;
                                border-top: 1px solid $light-gray;
                                width: $spacer*3;
                                left: calc(#{$spacer} + #{$megamenu-secondary-indentation} + #{$megamenu-link-padding-left});
                           }
                        }
                        + .dropdown-item.custom-dropdown { //hides any styling on deeper "all (category)" links
                            .dropdown-item.last-item {
                                display: none;
                                padding-top: unset;
                                a {
                                    font-weight: $megamenu-secondary-font-weight;
                                    text-transform: $megamenu-secondary-text-transform;
                                }
                                &:before { display:none; }
                            }
                            + .dropdown-item.custom-dropdown {
                                .dropdown-item.last-item {
                                    padding-top: unset;
                                    a {
                                        font-weight: $megamenu-secondary-font-weight;
                                        text-transform: $megamenu-secondary-text-transform;
                                    }
                                    &:before { display:none; }
                                }
                            }
                        }
                    }
                    .dropdown-link {
                        color: $megamenu-secondary-color;
                        font-family: $megamenu-secondary-font;
                        font-size: $megamenu-secondary-font-size;
                        font-weight: $megamenu-secondary-font-weight;
                        text-transform: $megamenu-secondary-text-transform;
                        line-height: 30px;
                        &:hover {
                            color: $megamenu-secondary-color-hover;
                        }
                        &.mega-left-title {
                            font-weight: $megamenu-left-nav-tertiary-title-font-weight;
                            font-size: $megamenu-left-nav-tertiary-title-font-size;
                            text-transform: $megamenu-left-nav-tertiary-title-text-transform;
                            line-height: $megamenu-left-nav-tertiary-title-line-height;
                            font-family: $megamenu-left-nav-tertiary-title-font-family;
                            pointer-events: none;
                        }
                    }
                    .dropdown-menu {
                        flex-flow: column;
                    }
                }
                &.mega-left {
                    flex: 1 1 calc(#{$megamenu-center-nav-width});
                    &.has-sub-asset {
                        flex: 1 1 calc(100% - #{$megamenu-left-nav-width});
                        + [class*="megamenu-asset-"] {
                            display: none; //hide the top level category asset when theres a third level
                            + [class*="megamenu-asset-"] {
                                display: none; //hide the top level category asset when theres a third level
                            }
                        }
                    }
                    > .dropdown-item { // hide all the tertiary navs until one is active
                        display: none;
                        &.active-list {
                            display: flex;
                        }
                    }
                }
            }
            > li > a.title {
                margin-bottom: $megamenu-title-margin-bottom;
                padding: 0 $spacer-sm;
                text-decoration: $megamenu-title-text-decoration;
            }

            .megamenu-asset-2 {
                padding-top: $megamenu-padding-top-bottom;
            }

            .megamenu-asset-only {
                width: 100%;
            }
            .left-nav {
                flex-direction: column;
                padding-top: $megamenu-padding-top-bottom;
                padding-bottom: $megamenu-padding-top-bottom;
                flex: 0 0 $megamenu-left-nav-width;
                border-right: $megamenu-left-nav-border-width solid $megamenu-left-nav-border-color;
                li {
                    border-top: none;
                    padding: 0;
                    display: flex;
                    &:hover {
                        background: unset;
                    }
                }
                a {
                    padding: $spacer-sm*2 $spacer-sm*3;
                    font-size: $megamenu-left-nav-font-size;
                    text-transform: $megamenu-left-nav-text-transform;
                    letter-spacing: 0;
                    color: $megamenu-left-nav-font-color;
                    display: flex;
                    &.active {
                        color: $megamenu-left-nav-border-color-hover;
                        @include icon($icon-arrow-right, after, 30px);
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {
        &>.dropdown-menu {
            margin-left: 0;
        }
    }

    // Megamenu content assets
    .has-asset-1 .megamenu-asset-1 {
        padding: $spacer;
        border-top: $dropdown-menu-border;
        @include media-breakpoint-up(lg) {
            padding: $megamenu-padding-top-bottom $spacer;
            margin: 0 auto;
            padding-left: $spacer;
            border-top: none;
            border-right: $dropdown-menu-border;
            flex-basis: 25%;
        }
    }
    .mega-left {
        .active-list {
            & + [class*="sub-asset-"] {
                @include media-breakpoint-up(lg) {
                    display: flex;
                    padding: 0;
                    flex: 0 0 50%;
                }
            }
        }
        .nav-content-pd-only {
            & + [class*="sub-asset-"] {
                @include media-breakpoint-up(lg) {
                    flex: 0 0 100%;
                }
            }
        }

        .nav-content-pd-split {
            & + [class*="sub-asset-"] {
                @include media-breakpoint-up(lg) {
                    flex: 0 0 28%;
                }
            }
        }

        .sub-asset-1,
        .sub-asset-2 {
            display: none;
            padding-top: 0;
            padding-right: 0;
            padding-bottom: 0;
            list-style: none;
            .page-designer{
                flex: 0 0 100%;
                padding-right: $spacer;
            }
        }
        .sub-asset-1.sub-asset-2 {
            .page-designer{
                flex: 0 0 50%;
            }
        }
        &.has-asset-1,
        &.has-asset-2 {
            .megamenu-asset-1,
            .megamenu-asset-2 {
                @include media-breakpoint-up(lg) {
                    flex: 1 1 calc(#{$megamenu-right-nav-width});
                    padding: $megamenu-padding-top-bottom $spacer $megamenu-padding-top-bottom 0;
                }
            }
        }
        &.has-asset-1.has-asset-2 {
            .megamenu-asset-1,
            .megamenu-asset-2 {
                @include media-breakpoint-up(lg) {
                    flex: 1 1 calc((100% - #{$megamenu-left-nav-width})/4);
                }
            }
        }
    }
    // hiding the top level megamenu assets on mobile when viewing a deeper category
    @include media-breakpoint-down(md) {
        &.show {
            [class*="has-asset-"] {
                .thirdCatOpen{
                    + [class*="megamenu-asset-"] {
                        display: none;
                        + [class*="megamenu-asset-"] {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    // Megamenu subcategory columns, dependent on presence of populated content slots
    @include media-breakpoint-up(lg) {
        // 5 column if no assets
        .menu-subcategories>.dropdown-item {
            flex-basis: 20%;
        }
        // 3 column if single asset
        .has-asset-1 .menu-subcategories,
        .has-asset-2 .menu-subcategories {
            flex-grow: 1;
            &>.dropdown-item {
                flex-basis: 33.33%;
            }
        }
        // 2 column if both assets
        .has-asset-1.has-asset-2 .menu-subcategories>.dropdown-item {
            flex-basis: 50%;
        }
    }
    @include media-breakpoint-up(xl) {
        // 5 column if single asset
        .has-asset-1 .menu-subcategories>.dropdown-item,
        .has-asset-2 .menu-subcategories>.dropdown-item {
            flex-basis: 20%;
        }
        // 3 column if both assets
        .has-asset-1.has-asset-2 .menu-subcategories>.dropdown-item {
            flex-basis: 33.33%;
        }
    }
}

.has-asset-2 {
    .megamenu-asset-2 {
        padding: $spacer;
        border-top: $dropdown-menu-border;
        display: none;
        @include media-breakpoint-up(lg) {
            display: block;
            padding: 0;
            margin: 0 auto;
            padding-left: $spacer;
            border-top: none;
            border-left: $dropdown-megamenu-border;
            flex-basis: 25%;
            img {
                margin-bottom: -$megamenu-padding-top-bottom;
                margin-top: -$megamenu-padding-top-bottom;
                margin-left: $spacer;
                margin-right: -$spacer;
            }
            .category-tile {
                max-width: 224px;
                &::before {
                    position: hidden;
                    background-image: none;
                }
            }

            .image-cropper {
                max-height: 70%;
                margin-left: 0;
            }

            .primary-text-styling {
                font-family: $primary-font;
                font-weight: normal;
                font-size: 18px;
                line-height: 32px;
            }

            .secondary-text-styling {
                font-family: $secondary-font;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
}

// Mobile menu
.main-menu.menu-toggleable-left {
    @include media-breakpoint-down(md) {
        transform: translateX(-100%);
        transition: transform $transition-speed ease-out;
        display: block;
        width: $header-suggestions-results-width;
        height: calc(100vh - #{$header-height-md});
        background-color: $white;
        z-index: $z-index-header;
        overflow-x: hidden;
        overflow-y: auto;
        &:not(.demo) {
            position: fixed;
            top: 0;
            left: 0;
        }
        &.in {
            transform: translateX(0);
        }
        &>.container {
            height: 100%;
            padding-left: 0;
            padding-right: 0;
        }
        .menu-group {
            padding-bottom: 80px;
        }

        .dropdown-menu {
            .dropdown-item {
                + .dropdown-item {
                    border-top: 0;
                }
            }
        }

        .search-mobile {
            .search-field {
                padding: 0 $dropdown-item-padding;
                border-radius: 0;
                border-left: none;
                border-right: none;
                font-size: $header-search-text-font-size;
                line-height: normal;
            }
            .fa-close,
            .fa-search,
            .fa-magnifying-glass {
                right: $spacer;
            }
            .reset-button {
                display: none !important;
            }
            .suggestions-wrapper {
                background-color: $light-gray;
                overflow: hidden;
                .suggestions {
                    position: static;
                    width: 100%;
                    margin-bottom: $spacer;
                    box-shadow: none;
                    &>.container {
                        max-width: $header-suggestions-results-width;
                        margin-left: 0;
                    }
                }
            }
        }
        
        .hide-navigation-arrow {
            &:after {
                display: none;
            }
        }
        .dropdown-item {
            &:hover {
                background: transparent;
            }
        }
        .top-category {
            &~.menu-subcategories {
                padding-left: $spacer;
                padding-right: $spacer;
                &>.dropdown-item {
                    &>.dropdown-link {
                        padding: 10px 20px 10px 0px;
                    }
                    .top-category {

                        &~.dropdown-item {
                            margin-left: $spacer;
                            margin-right: $spacer;
                            width: auto;
                            &>.dropdown-link {
                                padding-left: 0;
                                padding: 10px 20px 10px 0px;
                            }
                        }
                    }
                }
            }
            .user-message {
                display: none;
            }
        }
        .support .top-category {
            border-bottom: $dropdown-menu-border;
        }
        .navbar {
            //display: block; - causing mobile menu search to be huge on homepage
        }
        .nav-item {
            .nav-link {
                padding: 10px $dropdown-item-padding;
                color: $dropdown-item-color;
                &.dropdown-toggle:hover {
                    color: $dropdown-item-hover-color;
                }
            }
            &.highlight {
                .nav-link {
                    color: $header-link-highlight-color;
                }
            }
        }
        .nav-bar-bottom {
            border-top: 1px solid $mega-menu-mobile-border-gray;
            border-bottom: 1px solid $mega-menu-mobile-border-gray;
            margin-top: 50px;
            font-size: 13px;
            line-height: 24px;

            .nav-link {
                display: flex;
                span {
                    padding-left: 8px;
                }
            }
            .menu-icons {
                &:before {
                    font-size: 30px;
                }
            }
        }
        .close-menu {
            background-color: none;
            border-top: 1px solid $mega-menu-mobile-border-gray;
            border-bottom: 1px solid $mega-menu-mobile-border-gray;
            button {
                display: block;
                width: 100%;
                padding: $dropdown-item-padding;
                text-align: left;
            }
            .icon {
                float: left;
                display: block;
                margin-top: 3px;
                margin-right: 10px;
            }
        }
        .flag-icon {
            margin-right: 5px;
        }
        .store-locator-header-button {
            @include icon($icon-location);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: calc(100% - #{$dropdown-item-padding});
            &::before {
                padding-right: 4px;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        height: calc(100vh - #{$header-height});
    }
    @include media-breakpoint-down(xs) {
        width: calc(100% - 50px);
        max-width: $header-suggestions-results-width;
    }
}

.mega-nav-brand-styling {
    .brand-left-content {
        ul {
            list-style-type: none;
            li {
                font-family: $primary-font;
                font-size: 18px;
                font-weight: 400;
                line-height: 32px;
                color: $brand-primary;
                a {
                    font-family: $secondary-font;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 30px;
                }
            }
        }
    }

    .category-tile, .image-text {
        &:before {
            content: none;
        }
        .primary-text {
            font-family: $primary-font;
            font-size: 18px;
            font-weight: 400;
            line-height: 32px;
            color: $brand-primary;
        }
        .secondary-text {
            font-family: $primary-font;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: $brand-primary;
        }
    }
}

.mega-menu-right-content {
    @include media-breakpoint-up(lg) {
        display: block;
        padding: 0;
        margin: 0 auto;
        padding-left: $spacer;
        border-top: none;
        border-left: $dropdown-megamenu-border;
        flex-basis: 25%;
        img {
            margin-bottom: -$megamenu-padding-top-bottom;
            margin-top: -$megamenu-padding-top-bottom;
            margin-left: $spacer;
            margin-right: -$spacer;
        }
        .category-tile {
            max-width: 224px;
            &::before {
                position: hidden;
                background-image: none;
            }
        }

        .image-cropper {
            max-height: 70%;
            margin-left: 0;
        }

        .primary-text-styling {
            font-family: $primary-font;
            font-weight: normal;
            font-size: 18px;
            line-height: 32px;
        }

        .secondary-text-styling {
            font-family: $secondary-font;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
        }
    }
}

@include media-breakpoint-down(md) {
    .main-menu.menu-toggleable-left {
        width: 100%;
        max-width: 350px;

        .dropdown-toggle {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:after {
                content: none;
                display: none;
            }
        }

        .dropdown-menu {
            padding-bottom: 0;
            .dropdown-item .dropdown-link {
                padding: 16px;
            }
        }

        .country-selector {
            cursor: pointer;
            .dropdown-toggle {
                cursor: pointer;
            }

            .flag-icon {
                margin-left: 5px;
                margin-right: 8px;
            }

            .dropdown-country-selector {
                flex-direction: column;
            }
        }
    }

    .main-menu {
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;  /* Safari and Chrome */
        }
    }
}

.hide-navigation-arrow {
    .plus-icon,
    .fa-chevron-right {
        display: none;
    }
}

.show {
    > .hide-navigation-arrow {
        .dropdown-menu {
            display: none;
        }
    }
}

@include media-breakpoint-down(sm) {
    body .main-menu.menu-toggleable-left {
        width: 100%;
        max-width: none;
    }
}

@include media-breakpoint-up(lg) {
    .main-menu {
        align-self: stretch;
        display: flex;

        .plus-icon,
        .fa-chevron-right,
        .fa-chevron-right {
            display: none;
        }

        .menu-group {
            align-self: stretch;

            .navbar-nav {
                height: 100%;
                flex-wrap: nowrap;

                > .nav-item {
                    > .nav-link {
                        height: 100%;
                        margin: 0;
                        padding: 0 18px;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }
}

// hide cat in main menu if 'disable in header keep in burger'
// if set to true for category inside buger-menu header or library folder (navbar-nav-secondary)
.navbar-nav-secondary,
.mega-desktop-burger .main-menu {
    .disable-in-header-keep-in-burger {
        display: none;
    }
}

// SFCC-1458 Mobile nav updates
.back-btn-close-btn,
.active-category-container {
    font-size: 18px;
    line-height: 20px;
}

.active-category-container {
    height: 52px;
}

.back-btn-close-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 46px;
    
    @include media-breakpoint-up(md) {
        height: 70px;
    }

    .active-category-back-btn {
        padding-left: 16px;
        margin-right: 16px;
        display: flex;
        align-items: center;
        cursor: pointer;
        
        &:before {
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            font-family: $font-awesome; 
            font-weight: 300; 
            padding-right: 10px;
            content: "\f053";
        }
    }
}

.mobile-nav-ui {
    @include media-breakpoint-up(lg) {
        display: none;
    }
    
    &[data-current-active-category="0"] {
        .active-category-container,
        .active-category-back-btn {
            pointer-events: none;
            cursor: initial;
            opacity: 0;
            height: 0;
    
            &:before {
                opacity: 0;
                display: none;
            }
        }
    }

    .mobile-nav-ui-logo {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }
}

.active-category-container {
    display: none;
    align-items: center;
    text-align: center;
    padding-left: 16px;
    padding-right: 16px;

    .active-category {
        display: inline-block;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }

        &.active-category-href {
            position: relative;

            &:after {
                content: '';
                display: block;
                position: absolute;
                height: 2px;
                background-color: currentColor;
                width: 100%;
                left: 0;
                bottom: -5px;
            }
        }
    }
}

// ensure view all link is last in
.view-all-link {
    order: 1;

    @include media-breakpoint-down(md) {
        &:not(.view-all-link-mobile) {
            display: none;
        }
    }

    @include media-breakpoint-up(lg) {
        &:not(.view-all-link-desktop) {
            display: none;
        }
    }
}

@include media-breakpoint-up(lg) {
    .main-menu .navbar-nav {
        .megamenu {
            .menu-subcategories > .dropdown-item,
            .has-asset-1 .menu-subcategories > .dropdown-item,
            .has-asset-2.menu-subcategories > .dropdown-item {
                &.view-all-link-desktop {
                    flex-basis: 100%;        
                    padding: 0;
                    height: 42px;
                    position: static;
                    
                    .dropdown-link {
                        text-decoration: underline;
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
        }
    }
}

// SFCC-1458
@include media-breakpoint-down(md) {
    .mobile-nav-horizontal-flyout {
        // with new updates, main nav-bar toggler should always have a 'burger icon'
        .header {
            .main-menu {
                .navbar-toggler:before {
                    content: "\E912";
                }
            }
        }

        .active-category-container {
            display: flex;
        }

        .main-menu {
            will-change: transform;
        }
        
        .main-menu,
        .main-menu.menu-toggleable-left {
            .navbar-toggler {
                height: 40px;
                width: 40px;
            }

            .dropdown-link,
            .nav-link {
                line-height: 20px;
                padding: 16px;
            }

            .menu-subcategories {
                display: flex;
                flex-direction: column;
            }
            // main nav - ul #1
            .navbar-nav {
                transition: transform 250ms ease;
                will-change: transform;

                .dropdown-menu {
                    position: absolute;
                    transform: unset;
                    width: 100%;
                    left: 100%;
                    top: 0;
                    overflow: initial;
                    height: auto;
                    flex-direction: column;
                    display: none;
                }

                .fa-chevron-right {
                    font-size: 18px;
                }

                // ul #1 > li - top level li only
                .nav-item {
                    z-index: 1;
                    
                    > .nav-link {
                        font-size: 18px;
                        padding-top: 16px;
                        padding-bottom: 16px;
                    }

                    &.nav-bar-bottom {
                        > .nav-link {
                            align-items: center;
                            padding-top: 11px;
                            padding-bottom: 11px;
                        }
                    }

                    &.active {
                        > .dropdown-menu {
                            display: flex;
                        }
                    }

                    // ul #2
                    > .dropdown-menu,
                    > .dropdown-menu > .menu-subcategories {
                        .dropdown-item {
                            a {
                                color: $nav-link-sub-categories-color;    
                            }
                        }

                        // ul #2 li
                        > .dropdown-item {
                            position: static;

                            &.active {
                                z-index: 1;
                                > .dropdown-menu {
                                    display: flex;
                                }
                            }
                            // ul #3 
                            > .dropdown-menu {
                                // ul #3 li
                                > .dropdown-item {
                                    position: static;

                                    &.active {
                                        z-index: 1;
                                        > .dropdown-menu {
                                            display: flex;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// SFCC-1486 Desktop Nav Updates
@include media-breakpoint-up(lg) {
    .header-nav {
        // make static so dropdown menu can have width based on .header-nav, which is 100% of screen
        .navbar,
        .header .row .col.header-column-1 {
            position: static;
        }

        &.mega-standard {
            .header-column-1 {
                flex-basis: 100%;
                .main-menu {
                    flex-basis: 100%;
                }
            }

            .navbar-nav {
                flex-basis: 100%;
                flex-wrap: nowrap;   
                position: static;

                > .nav-item {
                    > .nav-link {
                        white-space: wrap;
                    }

                    &.megamenu {
                        &.show {
                            > .mega-standard {
                                width: 100%;
                                background: $white;
                                margin-left: auto;
                                margin-right: auto;
                                padding-left: 0;
                                padding-right: 0;
                                right: 0;

                                &.has-asset-1 {
                                    max-width: none;
                                }

                                > .menu-subcategories {
                                    position: static;
                                }
                            }
                        }
                    }

                    // temp testing style
                    &:nth-child(3) {
                        // approx half logo
                        padding-right: 86px;
                    }
                    // move 2 main nav items to right
                    &:nth-child(4) {
                        margin-left: auto;
                        // approx half logo
                        padding-left: 84px;
                    }

                    // only show first 6 nav items
                    &:nth-child(n+6) {
                        display: none;    
                    }
                }
            }
        }
    }
}

