@import "~core/components/headerSearch";

.site-search {
    float: none;
    display: block;
    height: $header-height;
    border-width: $header-search-desktop-border-width;
    border-color: $header-search-desktop-expanded-border-color;
    border-style: solid;

    @include media-breakpoint-up(md) {
        height: $header-height-md;
    }
    @include media-breakpoint-up(lg) {
        height: $header-height-lg;
    }

    form {
        float: none;
        height: inherit;
    }
    .search-field {
        @include navigation-link;
        border: none;
        text-align: left;
        color: $header-link-color;
        padding: 0 40px 0 20px;
        transition: width $transition-speed ease-out;
        width: $header-search-desktop-width;
        background: transparent;
        text-decoration: none;
        &::placeholder {
            color: $header-link-color;
        }
        &:focus {
            box-shadow: none;
        }
        &:hover {
            text-decoration: none;
        }
    }

    .fa-magnifying-glass {
        position: absolute;
        top: $input-padding-top;
        border: none;
        right: 20px;
        padding: 0;
        background-color: transparent;
    }

    .fa-close,
    .fa-search,
    .fa-magnifying-glass {
        @include vertical-center;
    }
    .fa-close::before,
    .fa-search::before,
    .fa-magnifying-glass::before,
    .reset-button::before {
        font-size: $header-search-icon-font-size;
    }
    .search-field:focus {
        width: $header-search-desktop-width-focus;
    }
    input {
        height: 100%;
    }
}
.search-icon-only {
    .site-search {
        border-color: transparent;
        border-style: solid;
        border-width: $header-search-desktop-border-width;
        transition: border $transition-speed ease-out;
        width: auto;
        .search-field {
            border-left: none;
            border-right: none;
            width: 0;
            @include media-breakpoint-down(md) {
                margin-left: 0;
            }
        }
    }
    .logo-center & {
        .site-search {
            border-bottom: 0;
            border-top: 0;

            @include media-breakpoint-up(md) {
                height: 70px;
            }

            @include media-breakpoint-up(lg) {
                height: 80px;
            }
        }
    }
}

// Search suggestions
.suggestions-wrapper .suggestions {
    right: auto;
}

// ensure site-search ui and children inherit basic color styles
.site-search {
    .fa-search,
    .reset-button {
        color: inherit;
        background-color: transparent;
    }

    .form-control {
        color: inherit;
        background-color: inherit;

        &:focus,
        &.focus
        &:focus:hover {
            color: inherit;
            background-color: inherit;
        }
    }
}
