@import "~org_citizen/components/footer";

footer {
    padding-top: 0;
}

footer#footercontent {
    border-top: none;

    .footer-item.col-md-4 {
        padding: 0 130px 0 20px;
        @include media-breakpoint-down(sm) {
            padding: 20px;
        }
    }
    .footer-item {
        .menu-footer {
            padding-top: 0;

            a {
                text-transform: unset;
                color: $white;
                font-size: $default-text-font-size;
                letter-spacing: normal;
                font-weight: $default-text-font-weight;

                &:hover {
                    color: $white !important;
                    text-decoration: none;
                }
            }
        }
    }
    .footer-item.col-md-3 {
        @include media-breakpoint-down(sm) {
            padding: 0 20px;
            margin: 0;
            .title {
                background: none;
                color: $white;
                font-size: 13px;
                line-height: 21.45px;
                letter-spacing: 2.6px;
                font-weight: 700;
                border: none;
                text-decoration: none;
            }
            .title:after {
                font-size: 30px;
                top: 20px;
                color: $brand-white;
            }
        }
    }
    .content {
        font-size: $default-text-font-size;
        font-weight: $default-text-font-weight;
        letter-spacing: $default-text-letter-spacing;
        line-height: 22.5px;
        text-transform: unset;

        li {
            height: auto;

             a:hover {
                 text-decoration: none;
                 color: $brand-white;
             }
        }
    }

    .tagline {
        font-size: $default-text-font-size;
        line-height: 22.5px;
        font-weight: 700;
        color: $white;
        text-transform: unset;
        margin-bottom: 20px;
    }

    .email-description {
        color: $neutral-light;
        font-size: $default-text-font-size;
        line-height: 21px;
    }

    .inline-btn input {
        color: $neutral-light;
        padding: 10px 0;
        height: auto;
        line-height: 20px;
        font-size: $default-text-font-size;
        margin-top: 15px;
    }
    .inline-btn:hover {
        border-bottom-color: $brand-white;
    }
    .inline-btn input::placeholder {
        color: $neutral-light;
    }

    .btn.subscribe-email {
        margin-top: 15px;
    }

    .btn.subscribe-email::after {
        font-size: 24px;
        line-height: 24px;
        content: "→";
        transform: none;
        color: $brand-white;
    }

    .email-signup-form .btn::after {
        color: $brand-white;
    }

    .social {
        border-top: 1px solid $dark-gray;
        border-bottom: 1px solid $dark-gray;

        .social-links {
            display: flex;

            @include media-breakpoint-up(md) {
                padding-left: 10px;
            }
    
            li {
                margin: 5px 10px;

                @include media-breakpoint-down(sm) {
                    &:first-child {
                        margin-left: 0;
                    }
                }

                a {
                    color: $lighter-gray;
                    vertical-align: middle;
                    opacity: .85;
                }
            }
        }
    }

    .title {
        margin-top: 16px;
        margin-bottom: 12px;
        color: $brand-white;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 2.6px;
        line-height: 21.45px;

        @include media-breakpoint-down(sm) {
            margin-top: 0;
        }
    }

    .menu-footer.content {
        background: $footer-background;
    }

    .copyright {
        margin-top: 100px;
        color: $brand-white;

        .copyright-text {
            display: flex;
            font-size: $default-text-font-size;
            line-height: 22.5px;
            color: $white;
            font-weight: $default-text-font-weight;
            letter-spacing: normal;

            @include media-breakpoint-up(md) {
                justify-content: end;
            }
        }

        @include media-breakpoint-down(sm) {
            margin-top: 0;
        }
    }

    .social-back-to-top, .copyright-notice {
        &.mobile-only {
            display: none;
            @include media-breakpoint-down(sm) {
                display: block;
            }
        }
        &:not(.mobile-only)  {
            display: block;
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }

    .footer-top {
        text-align: center;
        margin-top: 3em;
        margin-bottom: 3em;
    }
}
.consent-drawer {
    color: $brand-black;
}