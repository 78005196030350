
.show-more-text {
    .show-more-text-content {
        margin-bottom: 0;
        line-height: 20px;
        @include media-breakpoint-up(md) {
            line-height: 22px;
        }
    }

    .ellipsis-text {
        display: inline-block;
    }

    .more-text {
        display: inline-block;
    }

    .collapse {
        display: block;
        height: 60px;
        overflow: hidden;

        @include media-breakpoint-up(md) {
            height: 44px;
        }
    }

    .collapsing {
        display: block;
        height: 60px;

        @include media-breakpoint-up(md) {
            height: 44px;
        }

        .ellipsis-text {
            display: none;
        }

        .more-text {
            display: inline;
        }
    }

    .collapse.show {
        height: auto;

        .ellipsis-text {
            display: none;
        }

        .more-text {
            display: inline;
        }
    }

    .show-more-text-actions {
        display: block;
        padding: 5px;
        margin: 5px -5px -5px -5px;
        font-size: 14px;

        &.text-link-secondary {
            font-size: 14px;
        }

        .show-more-text-open {
            display: none;
        }

        .show-more-text-close {
            display: initial;
        }


        &.collapsed {
            .show-more-text-open {
                display: initial;
            }

            .show-more-text-close {
                display: none;
            }
        }
    }
}