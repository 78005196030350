@import "~org_citizen/checkout/checkout";

.data-checkout-stage,
.receipt,
.account-page {
    .order-product-summary-card {
        .order-product-summary-header {
            .edit-cart-link {
                color: $dark-gray;
            }
        }
    }
}

.data-checkout-stage {
    .card {
        .card-header-custom, .line-item-name {
            font-family: $primary-font;
        }

        .item-price-qty, .grand-total {
            span {
                font-family: $primary-font;
            }
        }
    }

    .btn-link, a {
        letter-spacing: normal;
    }

    .customer-information-block {
        .btn-light {
            &:hover {
                color: $white;
                border-color: $white;
                background-color: $pure-black;
            }
        }
    }

    .shipping-method-block {
        h3, .display-name {
            font-family: $primary-font;
        }
        
    }

    .form-group {
        label {
            font-family: $primary-font;
        }
    }
}

.checkout-receipt {
    .card {
        &.order-product-summary {
            border-top: 1px solid currentColor;
        }
    }
}

.reason-for-purchase {
    flex-basis: 100%;

    &:first-child {
        margin-right: 5px;
    }
}

.order-confirmation-continue-shopping {
    &:hover {
        opacity: 1;
    }
}

.product-summary {
    .line-item-name {
        font-family: $primary-font;
    }
}

.receipt {
    .confirm-details {
        .shipping-method,
        .shipping-method-price {
            color: $brand-black;
            font-family: $primary-font;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: normal;
            line-height: 22px;
        }

        .shipping-method-arrival-time {
            top: unset;

            @include media-breakpoint-up(lg) {
                font-size: 16px;
            }
        }

        .summary-details {
            color: $brand-black;
        }

        .summary-section-label {
            font-family: $primary-font;
        }
    }
    
    .custom-checkbox {
        .custom-control-label {
            font-family: $primary-font;
            line-height: inherit;
        }
    }

    .form-group {
        label {
            color: $brand-black;
        }

        .validator-container {
            .password-validation-helper {
                li {
                    color: $brand-black;
                }
            }
        }
    }

    .order-product-summary {
        .product-line-item {
            .item-attributes {
                .item-options {
                    color: $brand-black;
                }
            }

            .line-item-total-text {
                color: $brand-black;
            }
            
            .line-item-total-price-amount {
                color: $brand-black;
            }
        }
    }

    .order-total-summary {
        .grand-total {
            .order-receipt-label {
                font-family: $primary-font;
            }
        }

        .grand-total-sum {
            font-family: $primary-font;
        }
    }

    .product-summary {
        .item-attributes {
            color: $brand-black;
        }

        .line-item-total-price-label {
            font-family: $primary-font;
        }

        .qty-card-quantity-label {
            font-family: $primary-font;
        }
    }
}

// Override 2 tone BG for Bulova, instead, all gray.
.bg-domino {
    background-color: $checkout-gradient-gray;
    @include media-breakpoint-up(lg) {
        background: $checkout-gradient-gray;
    }
}

// Alpina checkout ui color is black
.data-checkout-stage {
    input[type=checkbox] {
        accent-color: $black;
    }

    .shipping-method-block {
        .shipping-method-list {
            .custom-radio .custom-control-input:checked ~ .custom-control-label {
                &:before {
                    background-color: $black;
                }   
            }

            .start-lines.active, 
            .start-lines.active + .end-lines {
                background-color: $white;
                border-color: $black;
            }

            .start-lines.active + .end-lines + .start-lines, 
            .start-lines.active + .end-lines + .start-lines + .end-lines {
                border-top-color: $black;
            }
        }
    }
}

.paymentMethod.active-payment-method,
.data-checkout-stage .adyen-component-content #paymentMethodsList .paymentMethod.active-payment-method,
.giftcertificate-information-container .giftcertificate-information.toggle-height-active {
    border-color: $accent-red;
}
