@import "~org_citizen/components/hero";

.hero-text {
    .hero-subtitle {
        &:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
            font-size: 12px;
            font-weight: 400;
            line-height: 20.4px;
        }
    }

    .hero-title {
        margin-top: 2px;

        > * {
            font-size: 28px;
            font-weight: 400;
            line-height: 39.2px;
            letter-spacing: normal;
            text-transform: $h2-text-transform;
            margin-top: 0;
        }
    }

    .hero-subtitle + .hero-title {
        margin-top: 2px;
    }
}
