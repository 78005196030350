@mixin footer-tagline() {
    font-family: $primary-font;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
}

@mixin footer-email-description() {
    font-family: $secondary-font;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
}

@mixin footer-email-input() {
    font-family: $secondary-font;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}

@mixin footer-column-header() {
    font-family: $primary-font;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
}

@mixin footer-column-link() {
    font-family: $secondary-font;
    font-size: 13px;
    font-weight: 600;
    line-height: normal;
}

@mixin footer-copyright() {
    font-family: $secondary-font;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    @include media-breakpoint-up(lg) {
        font-weight: 600;
    }
}

@mixin pdp-lead-1() {
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.08em;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
        font-size: 13px;
        line-height: 16px;
    }
}

@mixin pdp-product-number() {
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.08em;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
        font-size: 13px;
        line-height: 16px;
    }
}

@mixin pdp-promotions() {
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;

    @include media-breakpoint-up(lg) {
        font-size: 14px;
        line-height: 21px;
    }
}

@mixin pdp-availability() {
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;

    @include media-breakpoint-up(lg) {
        font-size: 14px;
        line-height: 21px;
    }
}

@mixin pdp-availability-badge() {
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.08em;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.1em;
    }
}

@mixin pdp-recommendation-thumbnails-title() {
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.08em;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.1em;
    }
}

@mixin pdp-collapsible-title() {
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: normal;

    @include media-breakpoint-up(md) {
        font-size: 28px;
    }
}

@mixin pdp-h4-lead() {
    font-size: 13px;
    font-weight: bold;
    font-family: $secondary-font;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
}

@mixin pdp-attribute-item() {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: normal;
}

@mixin pdp-attribute-item-bold() {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.02em;
    font-weight: bold;
}

@mixin pdp-link-cta() {
    position: relative;
    font-size: 13px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.01em;
    white-space: nowrap;

    &:hover {
        text-decoration: none;
    }

    &::after {
        content: "";
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: $black
    }
}

@mixin pdp-feature-list-item() {
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;

    @include media-breakpoint-up(md) {
        font-size: 24px;
        line-height: 32px;
    }
}

@mixin pdp-sticky-bar-product-name() {
    font-size: 28px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: normal
}

@mixin pdp-sticky-bar-product-price() {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: normal
}


@mixin pdp-additional-functions-list-item() {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: normal;
}