@import "~core/storeLocator/storeLocator";

.map-canvas {
    @include media-breakpoint-up(md) {
        height: 100%;
    }
}

.card.results-card,
.card.search-form {
    border: none;
    width: 100%;
}

.card.search-form {
    margin-bottom: 0;
}

.store-locator-container {
    .row {
        .form-group {
            margin-top: 0;

            .form-control {
                border: 1px solid $light-gray;
                padding-bottom: 20px;
            }
        }
    }

    select.form-control {
        border: 1px solid $light-gray;
    }

    @include media-breakpoint-down(lg) {
        .collapsible-md .title::after,
        .collapsible-lg .title::after {
            position: static;
            vertical-align: middle;
        }
    }

    .collapsible-md,
    .collapsible-lg {
        text-align: center;

        .title {
            text-decoration: underline;
            padding-left: 65px;
        }

        .collapsible-map-li {
            list-style: none;
        }

    }

    @include media-breakpoint-up(lg) {
        .collapsible-lg,
        .menu-footer,
        .collapsible-map-li {
            height: 100%;
        }
    }

    @include media-breakpoint-up(lg) {
        .collapsible-lg {
            .content {
                display: block;
            }
        }
    }
}

// Show 6 stores
@include media-breakpoint-up(md) {
    .store-locator-container {
        .results {
            max-height: 705px;
        }
    }
}