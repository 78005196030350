@import "~core/components/video";

.video-container {
    &.aspect-ratio-unset {
        video {
            &.vjs-tech {
                height: auto;
            }
        }
        
        .video-player {
            height: auto;
        }

        &::before {
            content: "";
            display: block;
            width: 1px;
            margin-left: -1px;
            float: left;
            height: 0;
            padding-top: calc(.30 * 100%);
        }
    }

    .video-thumbnail:not(.btn) {
        transition: opacity 500ms;

        &.faded {
            opacity: 0;
            transition: opacity 500ms;

            &:hover {
                opacity: 0;

                @include media-breakpoint-up(md) {
                    opacity: 1;
                }
            }
        }
    }
}

.experience-commerce_assets-video {
    .video-container {
        // hide video play button till iframe, vimeo and video-js (hosted) is/are loaded
        > div.video-player[data-video="youtube"],
        > video.video-player[data-video="hosted"],
        > div.video-player[data-video="vimeo"]:not([data-vimeo-initialized="true"]) {
            + .video-thumbnail {
                pointer-events: none;
    
                .video-thumbnail-button {
                    display: none;
                }
            }
        }
    }
}
