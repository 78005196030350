@import "~core/account/account";

.order-list-container {
    .card .card-header .btn {
        padding: 12px;
    }
    .card .card-header .btn-primary {
        margin-right: 5px;
    }

    .card .card-header .pull-right,
    .card .card-header .pull-left {
        float: none;
    }

    .card .card-header .pull-left {
        @include media-breakpoint-up(md) {
            float: left;
        }
    }

    .card .card-header .pull-right {
        padding: 0 0 15px 32px;

        @include media-breakpoint-up(md) {
            padding: 38px 32px 0 0;
            float: right;
        }
    }
}

.account-page, 
.login-page, 
.order-return-page, 
.cart-page, 
.wishlist-page {
    margin-top: 0;
    padding-top: 0;
}

// hide edit band size btn on order-details page
.account-page {
    .product-line-item {
        .quickview {
            &.edit {
                pointer-events: none;
            }
        }
        .line-item-name {
            .edit {
                pointer-events: none;
            }
        }
        .line-item-option {
            .edit {
                display: none;
            }
        }
    }
}

.trackorder {
    input {
        &::placeholder {
            color: $off-white;
            font-size: 14px;
        }
    }
}


