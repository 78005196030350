@import "~site_accutron/components/headerSearch";

.header-column-3 {
    .site-search {
        &.bubble-search {
            @include media-breakpoint-up(lg) {
                margin-right: 8px;
            }
        }
    }
}