.breadcrumb {
    @include remove-list-styling();

    display: contents;
    margin: 0;
    font-family: $secondary-font;
    font-size: $breadcrumb-font-size;
    line-height: $breadcrumb-line-height;
    letter-spacing: $breadcrumb-letter-spacing;
    overflow-y: auto;

    .breadcrumb-item {
        display: inline-flex;

        a {
            color: $breadcrumb-item-color;
            color: inherit;
            font-weight: $breadcrumb-font-weight;
            text-decoration: $breadcrumb-text-decoration;
            padding-left: .35em;

            &:hover {
                color: $breadcrumb-item-color-hover;
                text-decoration: $breadcrumb-text-decoration-hover;
            }
        }

        & + .breadcrumb-item {
            display: inline-flex;

            &:before {
                content: $breadcrumb-divider;
                color: inherit;
                font-weight: $breadcrumb-font-weight;
                padding: 0 $breadcrumb-item-padding;
            }

            &:last-of-type {
                color: inherit;
                display: inline-flex;
            }
        }

        h1 {
            font-size: inherit;
            font-family: inherit;
            font-weight: inherit;
            line-height: inherit;
            letter-spacing: inherit;
            margin: 0;
            text-transform: initial;
        }
    }
}
