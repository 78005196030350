@import "~org_citizen/components/headerSearch";

.site-search {
    color: inherit;
    float: none;

    form {
        float: none;
        .search-field {
            color: inherit;
            padding-right: 50px;
        }
    }

    .fa-close::before, 
    .fa-search::before, 
    .reset-button::before {
        color: inherit;
    }
}

.search-icon-only .site-search.expanded { 
    width: 100%;
    overflow: hidden;

    form {
        float: none;
    }
    button {
        color: inherit;
    }
    .search-field {
        width: 100%;
    }
}

.search-icon-only {
    .site-search {
        &.expanded {
            .search-field {
                width: 100%;
            }
        }

        .search-field {
            @include media-breakpoint-down(md) {
                // font-size must be larger than 16px to prevent auto zoom on touch for iPhone
                font-size: 16px;
                &:focus {
                    font-size: 16px;
                }
            }
        }
    }
}

// (No transparent-nav) header styles
#top-header .header-nav:not(.transparent-nav-light) {
    .search-icon-only {
        .bubble-search {
            .search-field {
                &.focus-visible {
                    color: $white;
                    background-color: $pure-black;
                }
            }

            &.expanded { 
                form {
                    background-color: $pure-black;
                    color: $white;
                }

                .search-field {
                    &.focus-visible {
                        + .fa-search::before {
                            color: $white !important;
                        }
                    }
                }   
            }
        }
    }
}

// Default styles (shared by all bubble search states)
#top-header .header-nav {
    .search-icon-only {
        width: 50%;

        @media (min-width: 1300px) {
            width: 35%;
        }

        &.expanded {
            width: 100%;

            form {
                background-color: $white;
            }

            .search-field {
                width: auto;
                border-width: 0;
            }
        }

        // desktop
        .bubble-search {
            background-color: transparent;
            border-color: transparent;
            border: 0;

            .search-field {
                &::placeholder {
                    color: inherit;
                }
            }

            form {
                height: 50px;
                border-radius: 50px;
                overflow: hidden;

                margin-top: 30px;
                @include media-breakpoint-up(md){
                    margin-top: 20px;
                }

                @include media-breakpoint-up(lg){
                    margin-top: 0;
                    height: 40px;
                }
            }

            @include media-breakpoint-up(lg){
                display: flex;
                align-items: center;
                justify-self: center;
            }
        }
        // desktop open
        .bubble-search.expanded { 
            form {
                width: 100%;
                border: 0;
                transition: all $transition-speed ease-in-out;

                @include media-breakpoint-up(lg){
                    transition: width $transition-speed ease-in-out;
                }
            }

            .search-field {
                width: 100%;
            }   
        }

        // mobile
        @include media-breakpoint-down(md){
            .site-search {
                form {
                    transform: translateY(0%);
                    transition: transform $transition-speed ease-in;
                }

                &.expanded.bubble-search-slide-up {
                    form {
                        position: fixed;
                        height: 50px;
                        width: 95%;
                        transform: translateY(0%);
                
                        .search-field {
                            display: block;
                            width: 100%;
                        }
                    }
                }

                // mobile open
                &.expanded {
                    form {
                        position: fixed;
                        left: 2.5%;
                        top: 43px;
                        width: 95%;
                        overflow: hidden;
                        transform: translateY(100%);
                        background-color: $pure-black;
                        color: $white;

                        &:hover {
                            color: $pure-black;
                        }
        
                        .search-field {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

// all transparent
#top-header .transparent-nav-light,
#top-header .transparent-nav-dark,
#top-header {
    @include media-breakpoint-down(md) {
        .search-icon-only .site-search {
            .search-field {
                display: none;            
            }

            &.expanded .search-field {
                display: block;            
            }
        }
    }
}

// transparent-light
#top-header {
    .transparent-nav-light {
        .site-search {
            color: $white;

            &.expanded {
                color: $pure-black;
                .search-field {
                    background-color: $white;    
                }
            }
            .search-field {
                &::placeholder {
                    color: inherit;
                }
            }
        }
    
        .search-icon-only {
            &.expanded {
                background-color: $white;
                border-color: transparent;
            }

            .site-search {
                background-color: transparent;
            }

            .site-search.expanded {
                button {
                    color: inherit;
                    
                    &:hover {
                        color: $pure-black;
                    }
                }

                .search-field {
                    &.focus-visible {
                        + .fa-search::before {
                            color: $pure-black !important;

                            &:hover {
                                color: $pure-black;
                            }
                        }
                    }
                }
            }
    
            @include media-breakpoint-down(md){
                .site-search.expanded {
                    form {
                        background-color: $white;
                        color: $pure-black;

                        &::placeholder {
                            color: $pure-black;
                        }
                    }
        
                    .fa-close::before, 
                    .fa-search::before, 
                    .reset-button::before {
                        color: $pure-black;
                    }
        
                    &:hover {
                        color: $pure-black;
                    }
                }
            }
        }
    }
}

// ensure site-search ui and children inherit basic color styles
.burger-menu {
    .site-search {

        .form-control {
            color: inherit;
            background-color: white;

            &:focus,
            &.focus
            &:focus:hover {
                color: inherit;
                background-color: $white;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .header-column-3 {
        > .search {
            display: flex;
            justify-content: flex-end;

            form {
                display: flex;
                .search-field {
                    margin-left: auto;
                }
            }
        }
    }
}