@import "~org_citizen/components/header";

header {
    &.logo-center {
        .header-column-2 {
            @include media-breakpoint-up(lg) {
                min-width: 160px;
                justify-content: center;
            }
        }
    }
}

.header {
    @include media-breakpoint-up(lg) {
        height: 100px;
    }

    .brand .logo-home {
        width: 140px;

        @include media-breakpoint-up(lg) {
            width: 130px;
            background-size: cover;
        }
    }

    .minicart-link,
    .minicart .minicart-icon:before,
    .nav-link,
    .custom-dropdown.show .nav-link {
        color: $white;
    }

    .minicart-link {
        text-decoration: none;
        font-weight: 400;
    }

    .minicart {
        .minicart-quantity {
            background-color: $black;
            color: $white;
        }

        .minicart-total {
            margin-top: 5px;
        }
    }

    .nav-link {
        @include media-breakpoint-up(lg) {
            padding: 10px;
        }
    }
}

.header-nav {
    transition: all 200ms ease-in-out;
}

.header-column-1 {
    .navbar-nav {
        flex-wrap: nowrap;
    }
}

.header-column-3 {
    .minicart-link-text-copy {
        display: none;
    }

    @include media-breakpoint-up(lg) {
        padding-right: 18px;
    }
}

.main-menu {
    @include media-breakpoint-down(md) {
        color: $black;
    }
    .nav-link,
    .dropdown-link {
        font-weight: 400;
        font-size: 12px;
        color: inherit;
        text-decoration: none;
        line-height: 15px;
        letter-spacing: normal;
    }
}

.header-nav {
    .nav-category,
    .nav-location,
    .nav-user,
    .nav-cart {
        .nav-link-positioning {
            display: inline-block;
            font-size: 12px;
            text-transform: capitalize;
            font-family: $primary-font;
            letter-spacing: $default-text-letter-spacing;
            color: $lighter-gray;
        }
    }

    .nav-cart {
        @include media-breakpoint-up(lg) {
            padding-top: 30px;
        }

        .nav-link-positioning {
            display: none;

            @include media-breakpoint-up(lg) {
                display: inline-block;
                padding-bottom: 3px;
            }
        }
    }

    .nav-category,
    .nav-location,
    .nav-user,
    .nav-cart {
        .fa-watch,
        .fa-location-dot,
        .fa-user,
        .fa-basket-shopping {
            display: block;
            font-size: 21px;
            text-align: center;
        }
    }
    
    .nav-category {
        padding-top: 30px;
        
        .fa-watch {
            padding-bottom: 12px;
        }
    }
    
    .nav-location {
        .fa-location-dot {
            padding-top: 5px;
        }
    }

    .nav-user {
        .fa-user {
            padding-top: 5px;
        }
    }

    .nav-cart {
        .fa-basket-shopping {
            padding-bottom: 6px;
        }
    }

    .minicart {
        .minicart-quantity {
            @include media-breakpoint-up(lg) {
                top: 27px;
            }
        }
    }

    .menu-text {
        display: none;
        
        @include media-breakpoint-up(lg) {
            display: inline;
            position: absolute;
            top: 33px;
            left: 5px;
            font-size: 12px;
            color: $lighter-gray;
        }
    }
}

.mega-desktop-burger {
    .header-column-1 {
        .main-menu {
            @include media-breakpoint-up(lg) {
                padding-left: 60px;
            }
        }
    }
    
    // hide top level items of mega menu desktop (burger version)
    .main-menu {
        .navbar-nav {
            > .nav-item {
                .dropdown-menu {
                    display: none;
                }
            }
        }
    }
}

// FIXED HEADER
.fixed-header {
    &.transparent-header {
        .transparent-nav-dark {
            &.fixed {
                background-color: $black;
            }
        }   

        .transparent-nav-light {
            &.fixed {
                background-color: $black;
            }
        }   
    }

    &:not(.transparent-header) {
        .header-nav {
            background-color: $black;
        }
    }
}

// ENHANCED HEADER STYLE TWEAKS
.fixed-header-enhanced {
    &.transparent-header {
        .transparent-nav-light {
            .minicart-link {
                color: $white;
            }

            .minicart {
                .minicart-quantity {
                    color: $header-minicart-count-text-color-light;
                    background-color: $header-minicart-count-background-color-light;
                }
            }
        }
    }

    .sticky-header & {        
        .primary-images-backdrop {
            will-change: transform;
            transform: translateY(0);
            animation: slide-up $header-transition-speed ease-out forwards;
        }
    }

    .sticky-header.scroll-direction-up & {
        .primary-images-backdrop {
            transform: translateY(125px);
            animation: slide-down $header-transition-speed ease-out forwards;
        }
    }
}

.scroll-direction-up.sticky-header,
.mobile-menu-in .fixed-header-enhanced {
    .transparent-header,
    &.transparent-header {
        .header-nav {
            &.transparent-nav-dark {
                background-color: $black;
                color: $white;

                .brand a {
                    filter: brightness(0);
                }
            }

            &.transparent-nav-light {
                background-color: $black;
                color: $white;

                .brand a {
                    filter: brightness(1);
                }
            }
        }
    }

    &:not(.transparent-header),
    .logo-center:not(.transparent-header),
    .logo-left:not(.transparent-header) {
        .header-nav {
            background-color: $black;
        }
    }
}

header.logo-center,
header.logo-left {
    .header-link, .minicart-link i {
        font-size: 21px;
    }

    // needs .transparent-header for .transparent-nav-dark & transparent-nav-light classes to work
    &.transparent-header {
        .transparent-nav-dark {
            color: $white;
            + .burger-menu-toggler,
            .navbar-toggler {
                color: $white;
                &:before {
                    color: $white;
                }
            }
            .brand a {
                filter: brightness($header-logo-transparent-nav-brightness);
            }
        }   

        .transparent-nav-light {
            color: $white;
            + .burger-menu-toggler,
            .navbar-toggler {
                color: $white;
                &:before {
                    color: inherit;
                }
            }

            .site-search {
                color: $white;
                
                &:hover {
                    color: $white;
                }
            }

            .brand a {
                filter: brightness(1);
            }
        }  
        
        .transparent-nav-dark,
        .transparent-nav-light {
            .main-menu {
                @include media-breakpoint-down(md) {
                    color: $black;
                }
            }
        }
    }

    &:not(.transparent-header) {
        .header .navbar-toggler:hover {
            color: inherit;
        }

        + .burger-menu-toggler,
        .navbar-toggler {
            color: $white;
            &:before {
                color: inherit;
            }
        }
        
        .brand a {
            // this will only work as intended on png images
            filter: brightness($header-logo-transparent-nav-brightness);
        }
    }
}

// transparent navigation tweaks
.mobile-menu-in .transparent-header .header .navbar-toggler:before {
    color: inherit;
}

// pdp (no PD) header updates
.page[data-action^=Product] {
    header.logo-center,
    header.logo-left {
        &:not(.transparent-header) {
            .burger-menu-toggler-remote-button {
                color: $white;
            }
            
            .header-nav {
                background-color: $black;
                color: $white;

                .logo-home {
                    filter: none;
                }

                @include media-breakpoint-up(lg) {
                    padding-bottom: 10px;
                }
            }
        }
    }
}

// Assumes all pages except home page will use black background color.
.page:not([data-action^=Home-Show]) {
    .header-nav {
        background-color: $black;
        color: $white;

        @include media-breakpoint-up(lg) {
            padding-bottom: 10px;
        }
    }
}

//transparent nav
html:not(.sticky-header) {
    .transparent-header {
        .header-nav {
            &.transparent-nav {
                &.transparent-nav-light {
                    &:not(.fixed) {
                        .minicart {
                            .minicart-quantity {
                                color: $header-minicart-count-text-color-light;
                                background-color: $header-minicart-count-background-color-light;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Dark Theme Header - 
.top-header {
    &.dark-theme {
        background-color: $black;
        color: $white;

        &:not(.transparent-header),
        .logo-center:not(.transparent-header),
        .logo-left:not(.transparent-header) {
            .header-nav {
                background-color: $black;
                color: $white;
            }

            .brand a {
                filter: brightness(1);
            }
        }

        .header-nav {
            color: $header-transparent-text-color-light;

            &.fixed {
                .header {
                    background-color: $header-transparent-background;
                }
            }


            &:not(.fixed) {
                .navbar-toggler {
                    @include icon($icon-menu, before, $header-navbar-toggler-icon-size,  $header-transparent-text-color-light);
                }

                .brand a {
                    filter: brightness($header-logo-transparent-nav-brightness) invert($header-logo-transparent-nav-invert);
                }

                .minicart {
                    .minicart-icon::before {
                        @include icon($icon-cart, before, $header-minicart-icon-font-size, color);
                        color: $header-transparent-text-color-light;
                    }

                    .minicart-quantity {
                        color: $pure-black;
                        background-color: $header-transparent-text-color-light;
                    }
                }

                .store-selector .store-locator-header-button {
                    color: $header-transparent-text-color-light;
                }

                .nav-item > .nav-link,
                .nav-item > .nav-link::before,
                .user > .nav-link::before,
                .user > .nav-link::after,
                .support > .nav-link,
                .support > .nav-link::after,
                .country-selector > .dropdown-toggle::after,
                .fa-magnifying-glass::before,
                .site-search input {
                    @include media-breakpoint-up(md) {
                        color: $header-transparent-text-color-light;
                    }
                }

                .site-search {
                    .search-field::placeholder {
                        @include media-breakpoint-up(md) {
                            color: $header-transparent-text-color-light;
                        }
                    }
                }

                .navbar .menu-group .nav.navbar-nav {
                    > .nav-item:not(:hover) {
                        > .nav-link {
                            @include media-breakpoint-up(md) {
                                color: $header-transparent-text-color-light;
                                &::before {
                                    background-color: $header-transparent-text-color-light;
                                }
                            }
                        }
                    }

                    > .nav-item:hover {
                        > .nav-link {
                            @include media-breakpoint-up(md) {
                                color: $header-transparent-text-color-light;
                            }
                        }
                    }
                }
            }
        }
    }
}

// dark theme updates for sticky header
.scroll-direction-up.sticky-header,
.scroll-direction-up.sticky-header:not(.transparent-header) {
    .top-header {
        &.dark-theme {
            .header-nav {
                background-color: $black;
                color: $white;
            }
        }
    }
}

.header-account-container {
    .header-link.nav-link {
        &:before {
            color: inherit;
            display: none;
        }
    }
}

html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .fa-magnifying-glass::before {
    color: inherit;
}

html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .fa-magnifying-glass::before {
    color: $white;
}

html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .site-search.expanded .fa-magnifying-glass::before {
    color: inherit;
}

html:not(.sticky-header) .transparent-header .header-nav.transparent-nav:not(.fixed) .form-control.search-field:focus ~ button {
    color: inherit;
}

//transparent nav
html:not(.sticky-header) {
    .transparent-header {
        .header-nav {
            &.transparent-nav {
                &.transparent-nav-dark,
                &.transparent-nav-light {
                    &:not(.fixed) {
                        .fa-magnifying-glass::before,
                        .site-search input {
                            @include media-breakpoint-up(md) {
                                color: inherit;
                            }
                        }

                        .site-search {
                            .search-field::placeholder {
                                @include media-breakpoint-up(md) {
                                    color: inherit;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.page[data-action^=Product] {
    #top-header {
        .header-nav:not(.transparent-nav-light),
        .header-nav {
            .bubble-search {
                color: $white;

                &.expanded {
                    .search-field {
                        color: $pure-black;
                        background-color: $white;    
                    }
                }
                .search-field {
                    &::placeholder {
                        color: inherit;
                    }
                }
            }
        
            .search-icon-only {
                &.expanded {
                    background-color: $white;
                    border-color: transparent;
                }

                .bubble-search {
                    background-color: transparent;
                }

                .bubble-search.expanded {
                    form {
                        color: $pure-black;
                    }

                    button {
                        color: inherit;
                        
                        &:hover {
                            color: $pure-black;
                        }
                    }

                    .search-field {
                        &.focus-visible {
                            background-color: $white;
                            color: $pure-black;

                            + .fa-magnifying-glass::before {
                                color: inherit !important;

                                &:hover {
                                    color: inherit;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#top-header {
    .transparent-nav-light,
    .header-nav:not(.transparent-nav-light),
    .header-nav {
        .site-search {
            .nav-search-text {
                position: absolute;
                top: 57px;
                right: 10px;
                font-size: 12px;
            }
        }

        .account-logout {
            display: none;
        }
    }
}

.search-results-header {
    .site-search {
        .nav-search-text {
            display: none;
        }
    }
}

html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .brand a {
    filter: brightness(1);
}

header.logo-center:not(.transparent-header) .brand a {
    filter: brightness(1);
}

.page[data-action^=Checkout-Begin] {
    .header-nav {
        .nav-user {
            .fa-user {
                padding-top: 9px;
                padding-bottom: 3px;
            }
        }
    }
}