@import "~core/components/miniCart";

// set --doc-height css var on root -> used in JS to determine minicart height
:root {
    --doc-height: 100%;
}

.minicart {
    display: flex;
    align-self: stretch;
    justify-content: center;
    align-items: center;
    color: inherit;

    .minicart-total {
        padding-left: 10px;
    }

    @include media-breakpoint-up(lg) {
        padding-left: $spacer;
        padding-right: $spacer;

        .minicart-total {
            padding-left: 0;
        }
    }

    // 'modal' backdrop
    &:after {
        content: '';
        visibility: hidden;
        background-color: $minicart-modal-backdrop-color;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: -1;
        opacity: 0;
        transition: opacity $minicart-modal-transition-speed ease-out 0s, visibility 0s ease $minicart-modal-transition-speed;
        pointer-events: none;
    }


    .popover {
        display: block;
        position: fixed;
        overflow-y: auto;
        top: auto;
        right: 0;
        bottom: 0;
        border: 0;
        min-width: auto;
        width: 100%;
        max-width: none;
        height: var(--doc-height);
        max-height: none;
        transform: translate(0, 100%);
        opacity: 0;
        visibility: hidden;
        transition: all $minicart-transition-speed ease-in;

        // may need media queries
        @include media-breakpoint-up(md) {
            top: 0;
            max-width: $minicart-size;
            height:100vh;
            transform: translate(100%, 0);
        }
        
        &.show {
            transform: translate(0, 0);
            opacity: 1;
            visibility: visible;
            transition: all $minicart-transition-speed ease-out;
        }

        > .cart {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    
    .cart {
        background-color: inherit;
        .minicart-label {
            .minicart-cart-link,
            .minicart-link-text {
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .minicart-label {
        font-family: $primary-font;
        font-size: 14px;
        font-weight: normal;
        line-height: 19px;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        padding-top: 32px;
        padding-bottom: 8px;
        margin-bottom: 0;

        @include media-breakpoint-up(md) {
            font-size: 28px;
            line-height: 40px;
            padding-bottom: 14px;
        }

        &.minicart-label-heading {
            @include media-breakpoint-up(md) {
                padding-top: 77px;
            }
        }

        .minicart-icon {
            padding-left: 10px;
            padding-right: 10px;
            display: none;
        }
    }

    .continue-shopping {
        position: absolute;
        left: 14px;
        top: 29px;
        cursor: pointer;
        display: none;

        span {
            pointer-events: none;
            
            &:before {
                pointer-events: none;
            }
        }

        @include media-breakpoint-up(md) {
            display: flex;
        }

        .continue-shopping-text {
            font-family: $secondary-font;
            font-size: 13px;
            line-height: 16px;
            height: 24px;
            font-weight: bold;
            text-decoration: underline;
            display: flex;
            align-items: center;
        }
    }

    .minicart-close {
        font-size: 30px;
        position: absolute;
        right: 14px;
        top: 14px;
        color: $black;
        cursor: pointer;

        @include media-breakpoint-up(md) {
            font-size: 40px;
            right: 0;
            top: 21px;
        }
    }

    .minicart-cross-sells { 
        margin-top: auto;
        
        + .minicart-footer {
            margin-top: 0;
        }
        
        .slider-container {
            &.slider-container-cross-sells {
                &:not(.slider-buttons-bottom) {
                    @include media-breakpoint-up(md) {
                        margin-bottom: 77px;    
                    }
                }
                
                .tns-nav {
                    .tns-nav button {
                        width: 7px;
                        height:  7px;
                    }
                    @include media-breakpoint-up(md) { 
                        bottom: -58px;
                    }
                }
            }
        }

        .minicart-label {
            font-size: 12px;
            padding-top: 22px;
            padding-bottom: 2px;
            border-bottom: 1px solid currentColor;   
            margin-bottom: 24px;
            font-weight: normal;

            .minicart-cart-link {
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                }
            }

            @include media-breakpoint-up(md) { 
                font-size: 20px;
                line-height: 28px;
                margin-bottom: 18px;
            }
        }

        .product-cross-sell {
            margin-bottom: 18px;

            .product-tile-image-link {
                border: 1px solid $off-white;
                margin-bottom: 8px;
                text-align: center;

                @include media-breakpoint-up(md) {
                    margin-bottom: 20px;
                }
                
                .img-fluid {
                    max-width: 100px;
                    max-height: 100px;

                    @include media-breakpoint-up(md) {
                        max-width: 150px;
                        max-height: 150px;
                    }
                }
            }

            .pdp-link {
                font-size: 11px;
                font-family: $primary-font;
                margin-bottom: 3px;

                @include media-breakpoint-up(md) { 
                    font-size: 14px;
                }
            }

            .price,
            .promotion {
                font-size: 10px;
                line-height: 13px;
                margin-bottom: 5px;
                
                @include media-breakpoint-up(md) { 
                    font-size: 12px;
                    margin-bottom: 12px;
                }
            }

            .price {
                font-weight: bold;
            }

            .add-to-cart-container {
                margin-top: auto;

                .cross-sells-add-to-cart,
                .cross-sells-quickview {
                    font-size: 11px;
                    line-height: 13px;
                    margin-top: 4px;
                    padding-top: 6px;
                    padding-bottom: 6px;
                    letter-spacing: normal;
                    border-width: 1px;

                    &.btn-secondary {
                        border-color: currentColor;
                    }

                    &:hover {
                        color: $white;
                        background-color: $dark-gray;
                    }

                    @include media-breakpoint-up(md) {
                        font-size: 12px;
                        line-height: 14px;
                        padding-top: 12px;
                        padding-bottom: 12px;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    button.dw-apple-pay-button.applePaySpecificityOverride {
        width: 100%;
        height: 42px;
        margin: 0;
        background-size: 50px 20px;
        padding-top: 7px;
        padding-bottom: 7px;
        border-width: 2px;
        border-radius: 0%;

        margin-bottom: 40px;

        @include media-breakpoint-up(md) {
            flex: 0 1 auto;
        }
    }

    .product-summary {
        min-height: 115px;
        max-height: none !important;
        padding-top: 0;
        padding-bottom: 0;
        border-top: 1px solid currentColor;
        
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none; 
        
        &::-webkit-scrollbar {
            display: none;
        }

        .minicart-remove-and-price {
            .line-item-price {
                .strike-through {
                    display: none;
                }
            }
        }
    }

    // if product recently added to cart, show success message
    // otherwise, hide success messaGE
    .minicart-success-message {
        display: none;
    }
    // only shows when a product has been added to cart
    &.show-success-message {
        // on mobile, only one line item show
        .popover {
            height: var(--doc-height);
        }
        // hide default label & check out continue
        .minicart-link-text {
            display: none;
        }

        .minicart-success-message {
            display: block;
        }

        .minicart-remove-and-price {
            flex-direction: column;
            align-items: flex-start;

            @include media-breakpoint-up(md) { 
                flex-direction: row;
                align-items: center;
            }

            .line-item-price {
                margin-left: unset;
                @include media-breakpoint-up(md) { 
                    margin-left: auto;
                }
            }
            
            .remove-line-item {
                display: none;
                @include media-breakpoint-up(md) { 
                    display: block;
                }
            }
        }

        .minicart-item {
            // on mobile, only show first minicart item
            display: none;
            &:first-of-type {
                display: block;
            }
            // on desktop, show all items
            @include media-breakpoint-up(md) { 
                display: block;
            }
        }
    }

    .minicart-footer {
        background-color: $brand-secondary;
        font-size: 12px;
        margin-top: auto;
        padding-top: 16px;
        border-top: 1px solid $mega-menu-border-gray;
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px;

        @include media-breakpoint-up(md) { 
            font-size: 13px;
            margin-left: -30px;
            margin-right: -30px;
            padding-left: 30px;
            padding-right: 30px;
            padding-top: 60px;
        }

        .leading-lines {
            margin: unset;
            margin-bottom: 5px;
            
            .start-lines span,
            .end-lines span {
                background-color: transparent;
            }

            .end-lines span:only-child,
            .end-lines span:last-child {
                padding: unset;
                margin: unset;
            }

            p {
                font-size: inherit;
                line-height: inherit;
            }
        }

        .checkout-continue-container {
            margin-top: 16px;
            @include media-breakpoint-up(md) {
                margin-top: 30px;
                padding-bottom: 70px;
            }

            .checkout-btn,
            .btn-secondary.checkout-btn {
                padding-top: 7px;
                padding-bottom: 7px;
                font-size: 16px;
                letter-spacing: normal;

                @include media-breakpoint-up(md) {
                    padding-top: 10px;
                    padding-bottom: 10px;

                    font-size: 16px;
                    letter-spacing: normal;

                    color: white;
                    background-color: $dark-gray;
                    border-color: $dark-gray;

                    &:hover {
                        color: $dark-gray;
                        background-color: $white;
                    }
                }
            }
        }
    }
}

// order-summary in checkout shares some of same styles
// with the minicart
.data-checkout-stage .product-summary-block,
.minicart {   
    a,
    .text-link {
        color: inherit;
    }

    .row {
        height: auto;
    }

    .minicart-item {
        padding-top: 18px;
        padding-bottom: 18px;

        + .minicart-item {
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 22px;
            padding-bottom: 22px;
        }
    }
    
    .remove-line-item {
        margin-top: auto;
    }
    .product-summary {
        .line-item-name {
            font-size: 14px;
            line-height: 18px;
            width: auto;
            overflow: initial;

            @include media-breakpoint-up(md) { 
                font-size: 18px;
                line-height: 24px;
            }
        }

        .item-image {
            width: 75px;
            @include media-breakpoint-up(md) { 
                margin-right: 36px;
                width: 125px;
            }
        }

        .item-attributes {
            display: flex;
            flex-grow: 1;
            flex-direction: column;            
            justify-content: center;
            padding-bottom: 0;
            font-size: 11px;
            line-height: 14px;
            font-family: $secondary-font;

            @include media-breakpoint-up(md) { 
                font-size: 12px;
                line-height: 15px;
            }

            p {
                font-size: inherit;
                line-height: inherit;
            }
        }

        .item-options,
        .line-item-availability,
        .line-item-promo {
            font-size: inherit;
            color: inherit;
        }

        .line-item-header {
            margin-bottom: 4px;

            @include media-breakpoint-up(md) { 
                margin-bottom: 6px;
            }
            .remove-line-item {
                display: none;
            }
        }

        .item-price-qty {
            display: none;
        }

        .line-item-option-container {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        .minicart-remove-and-price {
            font-weight: $minicart-totals-font-weight;
            line-height: 22px;
            flex-direction: row;
            align-items: center;
            padding-top: 10px;

            @include media-breakpoint-up(md) {
                padding-top: 15px;
                margin-bottom: 6px;
            }
            
            .line-item-price {
                margin-left: auto;

                .unit-price del, 
                .unit-price .strike-through {
                    color: inherit;
                }
            }

            .remove-btn {
                border: none;
                border-radius: unset;
                position: static;
                margin: unset;
                background-color: unset;
                padding: unset;
                margin: unset;
                width: auto;
                height: auto;
                color: inherit;
                font-family: $secondary-font;
                text-decoration: underline;
                letter-spacing: normal;
                font-size: $popover-font-size;
                line-height: 22px;
                margin-top: auto;

                &::before {
                    display: none;
                }
            }
        }
    } 

    .line-item-total-price {
        margin-left: auto;
    }
}

// Open / Close mini cart classes
.minicart-open {
    overflow: hidden;
   
    .minicart {
        &:after {
            display: block;
            opacity: 1;
            visibility: visible;
            transition: opacity $minicart-modal-transition-speed ease-out 0s, visibility 0s ease 0s;
        }

        .minicart-quantity {
            z-index: -1;
        }
    }

    // ensure select elements dont override minicart open modal
    [role="main"] {
        select {
            pointer-events: none;
        }
    }
}

// hide product added message (only for success message)
.add-to-cart-messages {
    pointer-events: none;
    display: none;
    .add-to-basket-alert {
        &.alert-success {
            display: none;
        }
    }
}

// ensure zendesk widget is no overlaid on top of minicart
iframe#launcher {
    z-index: 100 !important;
}

// Hide .minicart-remove-and-price 
.minicart-only {
    display: none;
}
// Hide .minicart-remove-and-price - unless it is in the minicart
.minicart {
    .minicart-only {
        display: block;
    }
}

// ensure minicart is positioned at top when header is fixed-header-enhanced
// and the <html> element has '.scroll-direction-down'
.scroll-direction-down {
    &.sticky-header {
        .fixed-header-enhanced {
            .popover {
                &.show {
                    top: var(--header-height);
                }
            }
        }
    }
}