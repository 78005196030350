@import '~org_citizen/components/pricing';

.product-detail,
.pdp-collapsible-content-section,
.quick-view-dialog {
    .product-data {
        .prices {
            .price {
                padding-right: 0;
            }
        }
    }

    .price {
        .strike-through {
            font-size: 14px;
            font-weight: 200;
            color: $gray;
        }
    }
}
