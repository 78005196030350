
@import "~core/components/giftCertificate";

.gift-card-swatches {
    list-style-type: none;
    padding: 0;
    margin: 0 -10px;

    flex-wrap: wrap;
    
    .gift-card-swatch-item {
        padding: 10px;

        .gift-card-swatch {
            width: 17px;
            height: 17px;
            border-radius: 50%;
            border: 2px solid $off-white;

            @include media-breakpoint-up(md) {
                width: 32px;
                height: 32px;
            }
        }   
        
        &.active {
            .gift-card-swatch {
                border-color: $black;
            }
        }
    }
}


.gift-card-slider {
    &:not(.gift-card-slider-initialized) {       
        .gift-card-slider-item {
            display: none;

            &:first-of-type {
                display: block;
                margin: auto;
            }
        }
    }
}

.gift-card-slider-container-wrapper {
    @include media-breakpoint-up(md) {
        position: sticky;
        top: 0;
    }

    .gift-card-slider {
        margin-bottom: 10px;
    
        .gift-card-slider-item {
            img {
                box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.25);
                border-radius: 4%;
            }
        }
    }    
}

.gift-card-thumbs {
    list-style-type: none;
    padding: 0;
    margin: 0 -20px;

    @include media-breakpoint-up(xl) {
        margin: 0 -30px;
    }

    .gift-card-thumb-item {
        flex-basis: 33.333333%;
        width: 33.333333%;
        padding: 20px;

        @include media-breakpoint-up(xl) {
            padding: 30px;
        }
        
        img {
            width: 100%;
            max-width: 152px;
            cursor: pointer;
        }

        &.active {
            img {
                box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.25);
                border-radius: 4%;
            }
        }
    }   
}

.gift-card-headline {
    text-transform: none;
    margin-top: 12px;;
}

.gift-card-subtitle {
    font-family: $secondary-font;

    @include media-breakpoint-up(md) {
        font-size: 13px;
        line-height: 16px;
    }
}

.btn-checkbalancebutton {
    border-width: 1px;

    &:hover {
        background-color: $white;
        color: $dark-gray;
    }
}