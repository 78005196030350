@import "~core/components/pageHeader";

.page-header {
    .plp.page-designer & {
        .hero-subtitle {
            &:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6) {
                text-transform: none;
                font-size: $default-text-font-size;
            }
        }
        .hero-title {
            &:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6) {
                font-size: $h1-font-size;
                line-height: $h1-line-height;
                margin-top: 5px;
            }
        }
        .hero-body {
            font-size: $default-text-font-size;
            line-height: $default-text-line-height;
        }
    }

    &.search-results-header {
        .page-header-title {
            color: $brand-primary;
            margin-bottom: 0;
            margin-top: 0;
            padding-top: 40px;
            padding-bottom: 35px;
            
            @include media-breakpoint-up(md) {
                font-size: $h1-font-size;
                line-height: $h1-line-height;
                font-weight: $h1-font-weight;
                letter-spacing: $h1-letter-spacing;
            }
        }
    }
}
