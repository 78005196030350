// DARK THEME - PLP STYLES - PRODUCT TILES STYLES - REFINEMENT BAR STYLES
.dark-theme {
    .experience-commerce_layouts-searchResults > & {
        background-color: $pure-black;
        color: $lightest-grey;
    }

    .experience-commerce_layouts-searchResults & {
        .experience-component > & {
            background-color: $pure-black;
        }

        .custom-select,
        select.form-control,
        select {
            color: $pure-black;
            border-color: $pure-black;
            filter: invert(1);
        }

        .refinement-bar {
            background-color: $pure-black;
        }

        .vertical-slide-out-filter-btn {
            button {
                color: $lightest-grey;
            }
        }
    }

    .breadcrumb {
        .breadcrumb-item {
            a {
                color: inherit;
            }
        }
    }
}

.light-theme {
    .experience-commerce_layouts-searchResults & {
        background-color: $white;
        // base styles
        > .container {
            background-color: $white;
        }
    }   
}