@import '~org_citizen/product/comparison';

.product-tile {
    .compare {
        border: 1px solid $lighter-gray;
        background-color: $white;

        .custom-checkbox, .custom-radio {
            .custom-control-label {
                color: $lighter-gray;
            }
        }
    }
}
