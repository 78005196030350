@import '~core/styleguide/styleguide';

.styleguide {
    .main {
        &__section {
            &>.row {
                &.dark-theme {
                    background-color: $charcoal;
                }
            }
        }
    }
}

