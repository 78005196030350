.burger-menu {
    background-color: $burger-menu-background-color;
    color: $black;
    
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;

    height: 100vh;
    width: 100%;
    max-width: 530px;
    padding: 0 16px;

    transform: translateX(-10%);
    transition: all 200ms ease-out, top 0ms linear;
    opacity: 0;
    visibility: hidden;

    overflow-y: scroll;
    // hide scrollbar (but keep scroll functionality)
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;

    &::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }

    .site-search {
        height: 50px;
        margin-top: 15px;
        margin-bottom: 10px;
    }

    .search {
        width: calc(100% - 72px);
        align-self: flex-end;

            .site-search {
                width: 100%;
                border-color: #d8d8d8;
                overflow: hidden;
                border-radius: $search-field-border-radius;
    
                form {
                    float: none;
                }
    
                .search-field {
                    font-size: 16px;
                    width: 100%;
                    text-align: left;
                    font-weight: normal;
                    background-color: inherit;
    
                    @include media-breakpoint-up(lg) {
                        color: $brand-black;
                        font-size: 12px;
                    }

                    &:focus,
                    &:hover {
                        background-color: inherit;
                    }
                }
            }
    }

    .burger-menu-innner-wrap {
        opacity: 0;
        transition: opacity 400ms ease-in 200ms;
    }

    .copyright-notice {
        padding-bottom: 40px;
    }

    &.in {
        transform: translateX(0);
        opacity: 1;
        visibility: visible;

        .burger-menu-innner-wrap {
            opacity: 1;
        }

        .navbar-nav {
            > .nav-item {
                transform: translate3d(0,0,0);
                opacity: 1;
            }
        }
    }
    
    @include delayedListAnimation;
    
    .brand-logo-black {
        background: url(../images/logo-black.png) no-repeat center;
        background-size: contain;
        padding-bottom: 40px;
        max-width: 140px;
        margin: 15px auto;
        position: absolute;
        left: 0;
        right: 0;

        @include media-breakpoint-up(lg) {
            max-width: 160px;
        }
    }

    .minicart-icon,
    .dropdown-toggle:after,
    .close-menu {
        display: none;
    }

    .menu-group,
    .navbar-nav {
        width: 100%;
    }  

    .minicart-link {
        color: inherit;
        display: flex;
        @include burger-link;

        .minicart-quantity {
            margin-left: 5px;
        }
    }

    .navbar {
        flex-direction: column;
        justify-content: flex-start;
        font-size: $burger-menu-font-size;
        line-height: $burger-menu-line-height;
        text-transform: uppercase;
        padding-top: 0;

        .navbar-nav {
            text-align: left;
        }

        .nav-link {
            @include burger-link;

            .plus-icon,
            .fa-chevron-right {
                display: none;
            } 
        }

        .nav-item {
            border-bottom: 1px solid #DDDDDD;

            &:last-child {
                border-bottom: none;
            }

            &:hover {
                color: $black;
            }

            .dropdown-menu {
                display: flex;
                flex-direction: column;
                border: none;
                background-color: inherit;
                font-size: inherit;
                padding-left: 15px;

                .dropdown-item {
                    font-size: inherit;
                    border: none;
                    border-top: 1px solid #DDDDDD;

                    &:hover {
                        background-color: inherit;
                        color: inherit;
                    }

                    .dropdown-link {
                        @include burger-link;
                    }
                }
            }
        }   
    }

    .burger-menu-logo {
        margin-top: 10px;

        img {
            width: 35%;
        }
    }

    .fa-basket-shopping {
        margin-top: 3px;
        margin-right: 3px;
    }
}

.main-menu {
    .navbar-nav {
        .country-selector {
            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
    }
}

.burger-menu {
    .menu-text,
    .minicart-link-text-copy,
    .fa-watch,
    .fa-basket-shopping {
        display: none;
    }

    .custom-dropdown {
        .nav-category {
            margin-top: 20px;
        }
    }
}