@import "~org_citizen/product/grid";

.vertical-slide-out-refinements {
    .refinement-header {
        .refinement-header-title,
        .refinement-header-close {
            color: inherit;
            text-shadow: none;
        }
    }

    > div,
    .refinement-footer {
        background-color: inherit;
        color: inherit;
    }
}

.vertical-slide-out-filter-btn {
    button {
        padding-left: 0;
        border: 0;

        &:hover {
            color: inherit;
            background-color: inherit;
            text-decoration: underline;
        }
    }
}

.vertical-slide-out-sortBy {
    select[name="sort-order"] {
        background-color: transparent;
        font-weight: 400;
        line-height: 20px;
    }
}

.search-results {
    .tab-content {
        border-bottom: 1px solid #BE6574;

        @include media-breakpoint-up(md) {
            margin-left: -17px;
            margin-right: -17px;
        }

        > .tab-pane {
            display: block;
        }
    }

    .grid-header {
        .result-count:only-child {
            margin-left: 0;
            text-align: left;
        }
    }

    .wishlist.product-list-enhancements-toggle-product {
        display: none;
    }
}

.header-bar,
.grid-header,
.filter-bar {
    .result-count {
        font-size: 18px;
        text-transform: uppercase;
        margin-bottom: 0;
        line-height: 56px;
    }
}

.grid-header {
    .result-count {
        text-align: center;
        padding-bottom: 0;
    }
}

.grid-header, 
.content-grid-header {
    margin-bottom: 0;
}

.refinement-bar:not([class*="refinement-bar-horizontal"]) {
    + div {
        margin-top: 20px;
    }
}

.refinement-bar,
.vertical-slide-out-refinements {
    background-color: $lighter-gray;

    .refinement-header-title {
        font-size: 18px;
        text-transform: uppercase;
    }

    .refinement-header {
        .refinement-header-close {
            @include icon($icon-close, after, 18px);
        }
    }

    .refinement-body {
        .refinements {

            .card-title {
                span,
                &::after {
                    color: $brand-black;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 20.4px;
                    letter-spacing: normal;
                    text-transform: uppercase;
                }
            }

            .card-title {
                &::after {
                    font-size: 24px;
                }
            }
        }

        .collapsible-xl {
            .title:after {
                font-family: $icomoon-font-family;
                content: $icon-expand;
            }
        }
    
        .collapsible-xl.active {
            .card-header {
                .title:after {
                    font-family: $icomoon-font-family;
                    content: $icon-collapse;
                }
            } 
        }

        .card {
            border-top: none;
            margin-bottom: 0;
        }
    }

    .refinement-footer {
        button.close {
            font-family: $button-solid-black-font;
            text-transform: $button-solid-black-text-transform;
            color: $button-solid-black-color;
            border-width: $button-solid-black-border-width;
            border-radius: $button-solid-black-border-radius;
            background-color: $button-solid-black-background;
            border-color: $button-solid-black-border-color;
            text-decoration: $button-solid-black-text-decoration;
            text-align: $button-solid-black-text-align;
            font-weight: $button-solid-black-font-weight;
            opacity: 1;
            text-shadow: none;

            &:focus {
                opacity: 1;
            }

            &:hover {
                color: $brand-black;
                background-color: $brand-white;
                border-color: $brand-black;
                opacity: 1;
            }
        }
    }
}

.vertical-slide-out-refinements {
    .refinement-body {
        .refinement-colorfilter,
        .refinement-bandcolor,
        .refinement-dialcolor {
            .values.content {
                padding: 0 6px;

                li {
                    padding-bottom: 12px;
                }
            }
        }
    }

    .filter-bar {
        li.filter-value {
            button {
                background-color: $brand-white;
            }
        }
    }
}

// hide sort/filter ui on search results page (non plp)
.search-no-decorator {
    .vertical-slide-out-filter-btn,
    .vertical-slide-out-sortBy {
        display: none;
    }

    .result-count {
        text-align: left;
    }
    .search-results-search-component {
        .site-search {
            margin-bottom: 24px;
            margin-left: 112px;
            width: calc(100% - 112px);

            @include media-breakpoint-up(lg) {
                width: calc(65.4% - 112px);
            }

            form {
                &:before {
                    content: attr(role);
                    position: absolute;
                    top: 50%;
                    left: -112px;
                    display: block;
                    transform: translate(0, -50%);
                    font-size: 18px;
                    text-transform: uppercase;
                    margin-top: 1px;
                }
            }

            .search-field {
                font-size: 18px;
                width: 100%;
                text-align: left;
                font-weight: normal;
                font-family: $primary-font;

                &:focus,
                &:hover {
                    background-color: inherit;
                }
            }
        }
    }
}

#product-search-results {
    .result-count,
    .vertical-slide-out-sortBy,
    .vertical-slide-out-filter-btn {
        margin-top: 15px;
    }
}

.product-grid.row:not(.no-gutters) > [class*="col-"] {
    @include media-breakpoint-up(md) {
        margin-bottom: 10px;
    }
}