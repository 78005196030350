@import url('https://use.typekit.net/ews7gqn.css');

// Locally stored fonts (must be placed in static/fonts folder)
// Font variables format: (font-family name, local file name, font weight, font style, font extensions)
$swiss-721-light: (Swiss 721, swiss-721-light, 100, normal, eot ttf);
$swiss-721-light-italic: (Swiss 721, swiss-721-light-italic, 100, italic, eot ttf);
$swiss-721-thin: (Swiss 721, swiss-721-thin, 200, normal, eot ttf);
$swiss-721-thin-italic: (Swiss 721, swiss-721-thin-italic, 200, italic, eot ttf);
$swiss-721-medium: (Swiss 721, swiss-721-medium, 400, normal, eot ttf);
$swiss-721-medium-italic: (Swiss 721, swiss-721-medium-italic, 400, italic, eot ttf);
$swiss-721-bold: (Swiss 721, swiss-721-bold, 700, normal, ttf);
$swiss-721-bold-italic: (Swiss 721, swiss-721-bold-italic, 700, italic, eot ttf);
$swiss-721-heavy: (Swiss 721, swiss-721-heavy, 900, normal, eot ttf);
$swiss-721-heavy-italic: (Swiss 721, swiss-721-heavy-italic, 900, italic, eot ttf);

//Comma separated list of font settings variables from above
$local-fonts: (
    $swiss-721-light,
    $swiss-721-light-italic,
    $swiss-721-thin,
    $swiss-721-thin-italic,
    $swiss-721-medium,
    $swiss-721-medium-italic,
    $swiss-721-bold,
    $swiss-721-bold-italic,
    $swiss-721-heavy,
    $swiss-721-heavy-italic,
);

//This works, but should have a map-has-key and check for file extensions (in font variables) to create non null urls
@mixin font-face($font) {

    $formats: (
        eot: "embedded-opentype",
        woff: "woff",
        woff2: "woff2",
        ttf: "truetype",
        svg: "svg"
    );

    $src: null;
    @each $filetype in nth($font, 5) {
        @if $filetype == eot {
            $src: append($src, url("../fonts/#{nth($font, 2)}.#{$filetype}"), comma);
            $src: append($src, url("../fonts/#{nth($font, 2)}.#{$filetype}?#iefix") format(map-get($formats, $filetype)), comma);
        } @else if $filetype == svg {
            $src: append($src, url("../fonts/#{nth($font, 2)}.#{$filetype}##{nth($font, 2)}") format(map-get($formats, $filetype)), comma);
        } @else {
            $src: append($src, url("../fonts/#{nth($font, 2)}.#{$filetype}") format(map-get($formats, $filetype)), comma);
        }
    }

    @font-face {
        font-family: nth($font, 1);
        font-style: nth($font, 4);
        font-weight: nth($font, 3);
        font-display: auto;
        src: $src;
    }
}

@each $font in $local-fonts {
	@include font-face($font);
}