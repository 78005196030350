@import "~core/components/header";

.header {
    @include update-container-padding;
    .navbar-toggler {
        padding-left: 0;
        padding-right: $spacer / 2;
        margin-left: 0;
        &:before {
            width: 28px;
        }
    }
    .header-column-1 {
        @include media-breakpoint-up(lg) {
            overflow: initial;
        }
    }
    .header-column-3 {
        justify-content: flex-end;
        & > .pull-right {
            margin-right: 0;
        }
    }
    .minicart {
        .minicart-total {
            margin-right: 0;
        }
    }
}

// Header nav menu
.main-menu {
    background-color: transparent;
    .navbar {
        .close-menu button,
        .close-button button {
            background-color: transparent;
            border: 0;
            -webkit-appearance: none;
            display: flex;
        }
        .close-button button {
            @include icon($icon-close, after, 12px);
            span {
                display: none;
            }
        }
        @include media-breakpoint-up(lg) {
            .nav-item.custom-dropdown {
                &>.dropdown-toggle {
                    @include icon($header-navbar-dropdown-icon, after, $header-navbar-dropdown-icon-size, inherit);
                }
            }
        }
    }
    .dropdown-link,
    .nav-link {
        @include navigation-link;
    }
}

// Promo banner
.header-banner {
    padding: 0;
    overflow: visible;
    background-color: $header-promo-bar-background-mobile;
    font-family: $body-font;
    color: $header-promo-bar-color;
    text-align: center;

    @include media-breakpoint-up(lg) {
        background-color: $header-promo-bar-background-desktop;
    }
    
    .container {
        @include update-container-padding;
    }
    .banner-promotion {
        font-family: $secondary-font;
        font-size: 11px;
        line-height: 20px;
        font-weight: normal;
    }

    .header-locale-container {
        display: flex;
        position: relative;
    }
    .accessibility-link, .header-locale-container {
        @include media-breakpoint-up(lg) {
            width: 140px;
        }
    }
    .accessibility-link {
        font-weight: 400;
    }
    .header-banner-column-1 {
        .pull-left:not(:empty) {
            margin-left: 0;
        }
    }
    .header-banner-column-2 {
        flex-basis: 100%;
    }
}

.slide-up {
    max-height: 120px;

    .container {
        @include media-breakpoint-up(lg) {
            max-height: 40px;
        }
    }
}

.header-icon-location {
    padding-bottom: 3px;
    padding-right: 3px;
    &:before {
        margin-bottom: 2px;
        font-size: $header-location-icon-font-size;
    }
}

.header-store-locator-icon {
    @include media-breakpoint-up(md) {
        padding-right: 13px;
    }
}

.flex-container {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    @include media-breakpoint-up(lg) {
        flex-direction: row;
    }
}

// ensure fixed-header is fixed when opended
html:not(.sticky-header) .transparent-header.fixed-header .header-nav.fixed-mobile-menu-open,
html:not(.sticky-header) .transparent-header.fixed-header .header-nav.transparent-nav.fixed-mobile-menu-open {
    position: fixed;
}

html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .brand a {
    &.logo-override {
        filter: none;
    }
}

.fixed-header {
    .header-nav {
        &.fixed-mobile-menu-open {
            position: fixed;
            width: 100%;
            box-shadow: $header-fixed-shadow;
            transition: all $header-transition-speed cubic-bezier(0.77,0,.175,1);
            z-index: $z-index-header-fixed;
        }
    }
}

.scroll-direction-down {
    &.sticky-header {
        .header-nav {
            .burger-menu-toggler-fixed {
                transform: translateY(var(--header-height));
                transition: 200ms transform linear 400ms;
            }
        }
    }
}

.mobile-menu-in {
    .fixed-header-enhanced {
        &:not(.transparent-header) {
            padding-bottom: var(--header-height);
        }

        .header-nav:not(.mega-desktop-burger) {
            @include media-breakpoint-up(lg) {
                position: fixed;
                top: 0;
                width: 100%;
            }
        }
        
        .mega-desktop-burger,
        .header-nav {
            position: fixed;
            width: 100%;
        }
    }
}

// transparent nav
html:not(.sticky-header) {
    .transparent-header {
        .header-nav {
            @include media-breakpoint-up(md) {
                &.nav-center .main-menu {
                    transition: background-color $transition-speed linear, transform $transition-speed ease-in-out;
                }
            }
            &.transparent-nav {
                &:not(.fixed) {
                    .header-icon-location {
                        color: inherit;
                        &:before {
                            color: inherit;
                        }
                    }
                    .fa-search {
                        &:before {
                            color: inherit;
                        }
                    }

                    .form-control {
                        &.search-field {
                            &:focus {
                                ~ button {
                                    color: $black;
                                }
                            }
                        }
                    }

                    .main-menu {
                        @include media-breakpoint-up(md) {
                            transition: background-color $transition-speed linear, transform $transition-speed ease-in-out;
                        }
                    }
                }

                &.transparent-nav-dark {
                    .minicart {
                        color: $light-gray;
                    }
                }

                &.transparent-nav-light {
                    .minicart {
                        color: $light-gray;
                    }
                }
            }
        }
    }

    // Update for when megamenu is not sticky
    .fixed-header,
    .fixed-header-enhanced,
    .static-header {
        @include media-breakpoint-up(lg) {
            .navbar-nav .megamenu > .dropdown-menu {
                // make up for scrollbar size when header is not fixed
                width: calc(100vw - 15px);
                &:before {
                    width: calc(100vw - 15px);
                }
            }            
        }
    }
}

.cookie-warning-messaging {
    z-index: 10;
}

.valid-cookie-warning {
    background-color: unset;
    color: inherit;
    white-space: normal;
}

.mega-desktop-burger {
    .main-menu .navbar-nav > .nav-item .dropdown-menu {
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}

// fix css layout issue when custom prefs for nav layout is 'center'
@include media-breakpoint-up(lg) {
    .nav-center {
        .navbar {
            position: static;

            .navbar-nav .megamenu > .dropdown-menu {
                margin-left: 0;
            }
        }
    }
}

html:not(.sticky-header) {
    .transparent-header {
        .header-nav.transparent-nav.transparent-nav-light {
            + .burger-menu-toggler {
                color: $white;
            }
        }
    }
}

// SFCC-1458 New Mobile menu updates
.mobile-nav-horizontal-flyout {
    .header {
        .header-column-1 {
            > .navbar-toggler {
                .mobile-menu-in & {
                    @include icon($icon-menu, before, $header-navbar-toggler-icon-size, $header-link-color);
                }
            }
        }
    }
}
