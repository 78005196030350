@import "~core/components/tabs";

.nav-tabs-container {
    &.has-overflow {
        &:after {
            height: calc(var(--nav-tabs-height));
            background-color: $brand-secondary;

            @media (min-width: 1020px) {
                left: -50%;
                right: auto;
                transform: translate(510px, 0);
            }
        }
    }
}