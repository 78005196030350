@import "~core/components/collapsibleItem";

@each $size in map-keys($grid-breakpoints) {

    @include media-breakpoint-down($size) {
        .collapsible-#{$size} {
            .card-header {
                border-right: none;
                border-left: none;
                border-bottom: none;
                &:last-child {
                    border-bottom: 1px solid $collapsible-border-color;
                }
            }
            .title {
                padding-top: $collapsible-padding-top;
                padding-bottom: $collapsible-padding-bottom;
                padding-left: $collapsible-padding-left;
                font-size: $collapsible-font-size;
                cursor: pointer;
                &::after {
                    top: $collapsible-padding-top;
                }
            }
            &:not(.active):not(.aside):not(.no-borders) {
                .card-header {
                    border-bottom: none;
                }
                &:last-child {
                    .card-header {
                        border-bottom: 1px solid $collapsible-border-color;
                    }
                }
            }
            &:not(:last-child) {
                .content,
                .card-body {
                    border-bottom: none;
                }
            }

            &.active {
                .content,
                .card-body {
                    border-right: none;
                    border-left: none;
                    padding-left: 0;
                    border-bottom: none;
                }
                .title {
                    @include icon($collapsible-header-icon-close, after, $collapsible-header-icon-size, $collapsible-header-icon-color);
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .collapsible-xl {
        .card-header {
            border-right: none;
            border-left: none;
            border-bottom: none;
            &:last-child {
                border-bottom: 1px solid $collapsible-border-color;
            }
        }
        .title {
            padding-top: $collapsible-padding-top;
            padding-bottom: $collapsible-padding-bottom;
            padding-left: $collapsible-padding-left;
            font-size: $collapsible-font-size;
            cursor: pointer;
            
            &:after {
                content: $icon-plus;
                font-family: $icomoon-font-family;
                display: inline-block;
                font-size: 28px;
                color: inherit;
                font-weight: normal;
                top: $collapsible-padding-top;
                position: absolute;
                right: 0;
            }
        }
        &:not(.active):not(.aside):not(.no-borders) {
            .card-header {
                border-bottom: none;
            }
            &:last-child {
                .card-header {
                    border-bottom: 1px solid $collapsible-border-color;
                }
            }
        }
        &:not(:last-child) {
            .content,
            .card-body {
                border-bottom: none;
            }
        }

        > .content, 
        > .content-asset > .content, 
        > .card-body {
            display: none;
        }

        &.active {
            > .content, 
            > .content-asset > .content, 
            > .card-body {
                display: block;
            }

            .content,
            .card-body {
                border-right: none;
                border-left: none;
                padding-left: 0;
                border-bottom: none;
            }
            .title {
                @include icon($collapsible-header-icon-close, after, $collapsible-header-icon-size, $collapsible-header-icon-color);
            }
        }
    }
}