@import '~core/mixins';

@mixin button($style: "", $customBackground: "", $customText: "", $customBorder: "", $size: "") {
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
    border-width: $button-border-width;
    border-style: solid;
    font-family: $button-font;
    text-transform: $button-text-transform;
    transition: all $transition-speed ease-out;
    text-decoration: none;
    border-radius: $button-border-radius;
    text-align: $button-text-align;
    font-weight: $button-font-weight;
    @if $style == small {
        font-size: $button-sm-font-size;
        line-height: $button-sm-line-height;
        padding: $button-sm-padding-top $button-sm-padding-right $button-sm-padding-bottom $button-sm-padding-left;
        letter-spacing: $button-sm-letter-spacing;
    } @else {
        font-size: $button-font-size;
        letter-spacing: $button-letter-spacing;
        line-height: $button-line-height;
        padding: $button-padding-top $button-padding-right $button-padding-bottom $button-padding-left;
    }
    @if $style == primary {
        font-family: $button-primary-font;
        text-transform: $button-primary-text-transform;
        color: $button-primary-color;
        background-color: $button-primary-background;
        border-width: $button-primary-border-width;
        border-radius: $button-primary-border-radius;
        border-color: $button-primary-border-color;
        text-decoration: $button-primary-text-decoration;
        text-align: $button-primary-text-align;
        font-weight: $button-primary-font-weight;
        @if $style == small {
            padding: $button-primary-sm-padding-top $button-primary-sm-padding-right $button-primary-sm-padding-bottom $button-primary-sm-padding-left;
        } @else {
            padding: $button-primary-padding-top $button-primary-padding-right $button-primary-padding-bottom $button-primary-padding-left;
        }
        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            color: $button-primary-color-hover;
            background-color: $button-primary-background-hover;
            border-color: $button-primary-border-color-hover;
            text-decoration: $button-primary-text-decoration-hover;
        }
        
        &:disabled,
        &.disabled {
            color: $button-primary-color-disabled;
            background-color: $button-primary-background-color-disabled;
            border-color: $button-primary-border-color-disabled;
            opacity: 1;
        }
    }
    @if $style == secondary {
        font-family: $button-secondary-font;
        text-transform: $button-secondary-text-transform;
        color: $button-secondary-color;
        border-width: $button-secondary-border-width;
        border-radius: $button-secondary-border-radius;
        background-color: $button-secondary-background;
        border-color: $button-secondary-border-color;
        text-decoration: $button-secondary-text-decoration;
        text-align: $button-secondary-text-align;
        font-weight: $button-secondary-font-weight;
        @if $style == small {
            padding: $button-secondary-sm-padding-top $button-secondary-sm-padding-right $button-secondary-sm-padding-bottom $button-secondary-sm-padding-left;
        } @else {
            padding: $button-secondary-padding-top $button-secondary-padding-right $button-secondary-padding-bottom $button-secondary-padding-left;
        }
        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            color: $button-secondary-color-hover;
            background-color: $button-secondary-background-hover;
            border-color: $button-secondary-border-color-hover;
            text-decoration: $button-secondary-text-decoration-hover;
        }

        &:disabled,
        &.disabled {
            color: $button-secondary-color-disabled;
            background-color: $button-secondary-background-color-disabled;
            border-color: $button-secondary-border-color-disabled;
            opacity: 1;
        }
    }
    @if $style == tertiary {
        font-family: $button-tertiary-font;
        text-transform: $button-tertiary-text-transform;
        color: $button-tertiary-color;
        background-color: $button-tertiary-background;
        border-radius: $button-tertiary-border-radius;
        border-width: $button-tertiary-border-width;
        border-color: $button-tertiary-border-color;
        text-decoration: $button-tertiary-text-decoration;
        text-align: $button-tertiary-text-align;
        font-weight: $button-tertiary-font-weight;
        font-size: $button-tertiary-font-size;
        @if $style == small {
            padding: $button-tertiary-sm-padding-top $button-tertiary-sm-padding-right $button-tertiary-sm-padding-bottom $button-tertiary-sm-padding-left;
        } @else {
            padding: $button-tertiary-padding-top $button-tertiary-padding-right $button-tertiary-padding-bottom $button-tertiary-padding-left;
        }
        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            color: $button-tertiary-color-hover;
            background-color: $button-tertiary-background-hover;
            border-color: $button-tertiary-border-color-hover;
            text-decoration: $button-tertiary-text-decoration-hover;
        }

        &:disabled,
        &.disabled {
            color: $button-tertiary-color-disabled;
            background-color: $button-tertiary-background-color-disabled;
            border-color: $button-tertiary-border-color-disabled;
            opacity: 1;
        }
    }
    @if $style == quaternary {
        font-family: $button-quaternary-font;
        text-transform: $button-quaternary-text-transform;
        color: $button-quaternary-color;
        background-color: $button-quaternary-background;
        border-radius: $button-quaternary-border-radius;
        border-width: $button-quaternary-border-width;
        border-color: $button-quaternary-border-color;
        text-decoration: $button-quaternary-text-decoration;
        text-align: $button-quaternary-text-align;
        font-weight: $button-quaternary-font-weight;
        @if $style == small {
            padding: $button-quaternary-sm-padding-top $button-quaternary-sm-padding-right $button-quaternary-sm-padding-bottom $button-quaternary-sm-padding-left;
        } @else {
            padding: $button-quaternary-padding-top $button-quaternary-padding-right $button-quaternary-padding-bottom $button-quaternary-padding-left;
        }
        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            color: $button-quaternary-color-hover;
            background-color: $button-quaternary-background-hover;
            border-color: $button-quaternary-border-color-hover;
            text-decoration: $button-quaternary-text-decoration-hover;
        }

        &:disabled,
        &.disabled {
            color: $button-quaternary-color-disabled;
            background-color: $button-quaternary-background-color-disabled;
            border-color: $button-quaternary-border-color-disabled;
            opacity: 1;
        }
    }
    @if $style == custom {
        background: $customBackground;
        color: $customText;
        border-color: $customBorder;
        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            background: darken($customBackground, 5%);
            border-color: darken($customBorder, 5%);
        }
    }
}

@mixin checkbox-icon-background {
    content: "";
    width: $radiocheck-button-size;
    height: $radiocheck-button-size;
    border: 2px solid $radiocheck-border-color;
    background-color: $radiocheck-background-color;
    border-radius: $border-radius;
}

@mixin checkbox-icon-checked-background {
    content: "";
    width: $radiocheck-button-size;
    height: $radiocheck-button-size;
    background-color: $radiocheck-background-color-selected;
    border: 2px solid $radiocheck-border-color-selected;
    border-radius: $border-radius;
}


@mixin radio-icon-background {
    content: "";
    width: $radiocheck-button-size;
    height: $radiocheck-button-size;
    border: 2px solid $radiocheck-border-color;
    border-radius: 50%;
    background-color: $radiocheck-background-color;
}

@mixin radio-icon-checked-background {
    width: $radiocheck-button-size;
    height: $radiocheck-button-size;
    border-radius: 50%;
    background-color: $radiocheck-icon-color;
    border: 2px solid $radiocheck-border-color-selected;
}

@mixin disabled {
    opacity: 1;
    pointer-events: none;
}

@mixin text-link {
    color: $link-color;
    font-weight: $link-font-weight;
    text-decoration: $link-text-decoration;
    cursor: pointer;
    &:hover {
        color: $link-color-hover;
        text-decoration: $link-text-decoration-hover;
    }
}

@mixin text-link-secondary {
    color: $link-color;
    font-family: $secondary-font;
    font-weight: $link-secondary-font-weight;
    text-decoration: $link-secondary-decoration;
    font-size: $link-secondary-font-size;
    line-height: $link-secondary-line-height;
    cursor: pointer;
    &:hover {
        color: $link-color-hover;
        text-decoration: $link-text-decoration-hover;
    }
}

// Navigation links
@mixin navigation-link-mega-menu {
    font-family: $megamenu-left-nav-font-family;
    text-decoration: $nav-link-text-decoration;
    text-transform: $nav-link-text-transform;
    font-size: $nav-link-font-size;
    font-weight: $nav-link-font-weight;
    line-height: $nav-link-line-height;
    letter-spacing: $nav-link-letter-spacing;
    color: $megamenu-left-nav-font-color;

    &:hover {
        text-decoration: $nav-link-text-decoration-hover;
        color: $megamenu-left-nav-font-hover-color;
        @include icon($icon-arrow-right, after, 30px);
    }
}

@mixin visually-hidden {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    height: 1px;
    width: 1px;
}

@mixin burger-link {
    font-family: $nav-link-font-family;
    font-size: inherit;    
    text-decoration: none;
    line-height: 15px;
    letter-spacing: 2px;
    padding: 8px 10px;
    text-transform: uppercase;
}

@mixin delayedListAnimation() {
    .navbar-nav {
        > .nav-item {
            transform: translate3d(-10%,0,0);
            transition: opacity .5s ease-in-out, transform .5s ease-in-out;
            opacity: 0;

            &:nth-child(1) { transition-delay: .08s;}
            &:nth-child(2) { transition-delay: .16s;}
            &:nth-child(3) { transition-delay: .24s;}
            &:nth-child(4) { transition-delay: .32s;}
            &:nth-child(5) { transition-delay: .40s;}
            &:nth-child(6) { transition-delay: .48s;}
            &:nth-child(7) { transition-delay: .56s;}
            &:nth-child(8) { transition-delay: .64s;}
            &:nth-child(9) { transition-delay: .72s;}
            &:nth-child(10) { transition-delay: .8s;}
            &:nth-child(11) { transition-delay: .88s;}
            &:nth-child(12) { transition-delay: .96s;}
            &:nth-child(13) { transition-delay: 1.04s;}
            &:nth-child(14) { transition-delay: 1.12s;}
            &:nth-child(15) { transition-delay: 1.20s;}
        }
    }
}

@mixin delete-card-button($absolute: true) {
    @include icon($product-card-remove-icon, before, $product-card-remove-icon-size);
    font-family: $body-font;
    border: none;
    border-radius: 50%;
    font-size: 0;
    @if $absolute {
        position: absolute;
        top: 0;
        right: 0;
        margin: $card-body-padding-top $card-body-padding-right $card-body-padding-bottom $card-body-padding-left;
    }
    cursor: pointer;
    z-index: 1;
    background-color: $product-card-remove-icon-background;
    padding: 4px 6px;
    height: 22px;
    width: 22px;

    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin header-link {
    font-family: $primary-font;
    text-decoration: none;
    text-transform: $header-link-text-transform;
    font-size: $header-link-font-size;
    font-weight: $header-link-font-weight;
    line-height: $header-link-line-height;
    letter-spacing: $header-link-letter-spacing;
    &:hover {
        text-decoration: none;
    }
}

@mixin label-styles {
    font-family: $primary-font;
    font-weight: $form-label-font-weight;
    font-size: $form-label-font-size;
    line-height: $form-label-line-height;
    margin-bottom: $form-label-margin-bottom;
    text-transform: $form-label-text-transform;
    letter-spacing: $form-label-letter-spacing;
}

@mixin radio-check-label {
    font-family: $primary-font;
    font-weight: $radiocheck-label-font-weight;
    font-size: $radiocheck-label-font-size;
    text-transform: $radiocheck-label-text-transform;
    letter-spacing: $radiocheck-label-letter-spacing;
    vertical-align: middle;
    transition: box-shadow $transition-speed ease-out;
    &:hover:before {
        box-shadow: 0 0 0 1px $radiocheck-border-color;
    }
}