@import"~org_citizen/components/formFields";


select.form-control {
    line-height: $select-line-height;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: $input-border-width solid $select-border-color;
    color: $select-color;
}

.float-label {
    label {
        font-family: $primary-font;
    }
    // input styles 
    .form-control,
    select.form-control {
        &.focus-visible {
            border-color: $brand-primary;
        }
    }
}