@import "~core/components/layout";

[class*='experience-commerce_layouts'] {
    .has-background-image {
        &>.container {
            .column-background {
                @include media-breakpoint-up(sm) {
                    width: auto;
                }
                
                @include media-breakpoint-up(xl) {
                    width: auto;
                }
            }
        }
    }
}