.show-down-down-arrow,
.back-to-top,
footer .back-to-top {
    position: fixed;
    bottom: 40px;
    right: 40px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    transition: all 200ms ease-in-out;
    opacity: 0;

    .fa-stack {
        .fa-circle,
        .fa-arrow-up {
            transition: all 200ms ease-in-out;

            &:before {
                color: inherit;
                transition: all 200ms ease-in-out;
            }
        }
    }

    .fa-circle {
        &:before {
            content: '';
        }
    }

    &:hover {
        .fa-stack {
            .fa-circle {
                border: 1px solid $black;
                opacity: 1;
            }
            .fa-arrow-up {
                color: $white;
            }
        }
    }
    
    &.active {
        opacity: 1;
    }
}

// shares similar styles to back to top
.show-down-down-arrow {
    opacity: 1;
    position: absolute;
    right: calc(50% - 20px);

    .fa-stack {
        width: $footer-icon-size;
        height: $footer-icon-size;
        line-height: $footer-icon-size;
    }

    .fa-arrow-down {
        @include icon($icon-arrow-down, before, 40px, 'inherit');
        width: 100%;
        height: 100%;
        color: inherit;
    }

    .fa-circle {
        background-color: inherit;
        box-shadow: 0 2px 10px rgba($black, 0.2);
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.5);
    }

    &.dark-theme {
        color: $white;       

        .fa-circle {
            border: 1px solid rgba(255, 255, 255, 0.5);
        }

        &:hover {
            .fa-circle {
                border: 1px solid rgba(255, 255, 255, 1);
            }   
        }
    }

    &.hide-mobile {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &.hide-desktop {
        @include media-breakpoint-up(md) {
            display: none;
        }
    }
}
