@import "~org_citizen/components/productTiles";

.product-tile {
    font-family: $primary-font;
    background-color: $pure-black;
    color: $lightest-rain-cloud;
    text-align: center;
    font-weight: 500;

    .badge-product-container {

        &.badge-image-over {
            top: unset;
            left: unset;
        }

        .badge-product {
            margin-left: auto;
            margin-right: auto;
            padding-right: 0;
            padding-left: 0;

            display: none;

            &:first-of-type {
                display: block;
            }
        }

        @include media-breakpoint-up(md) {
            padding-top: 3.7px;

            &.badge-image-over {
                top: unset;
                left: unset;
            }
        }
    }

    &:not(.product-tile-horizontal) {
        background-color: $brand-secondary;

        .product-id {
            transform: none;
            position: static;
            width: auto;
            font-size: 13px;
            line-height: 21.45px;
            font-weight: 700;
            color: inherit;
            text-transform: uppercase;
        }

        .tile-body {
            padding-top: 0;
            justify-content: flex-end;

            .price {
                font-family: inherit;
                color: $light-gray;
                font-size: 13px;
                margin-top: auto;
                margin-bottom: 1px;
                font-weight: 200;
                letter-spacing: normal;
                text-transform: uppercase;
                line-height: 21.45px;
            }
        }

        .pdp-link {
            padding-bottom: 8px;
            margin-top: 3px;
            margin-bottom: 0;

            .link, .subtitle {
                font-family: inherit;
                font-weight: 400;
                color: $brand-primary;
                text-transform: unset;
                font-size: 16px;
                line-height: 21.45px;
                letter-spacing: normal;
                margin-bottom: 0;
            }

            .subtitle {
                text-align: center;
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: 8px;
            }
        }

        .quickview {
            &.btn {
                position: absolute;
                width: auto;
                left: -16px;
                right: -16px;
                bottom: -16px;
                border: none;
                font-weight: 500;
            }

            &:hover {
                opacity: 1;
            }
        }
    }

    .image-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;

        .product-tile-image-link {
            order: -1;
        }
    }

    &:hover {
        .product-tile-image-container {
            &:after {
                content: none;
            }
        }
    }
}

.product-tile-horizontal {
    .tile-headline {
        .pdp-link {
            color: $accent-red;
            text-transform: uppercase;
            text-decoration: none;
        }
    }

    .tile-description {
        @include media-breakpoint-up(md) {
            font-size: 12px;
            line-height: 20px;
            margin-top: 24px;
        }
    }

    .btn {
        letter-spacing: $button-letter-spacing;
    }
}

.dark-theme {
    .product-tile-horizontal {
        background-color: $pure-black;
        color: $lightest-grey;
    }   
}

.light-theme {
    .product-tile {
        background-color: $brand-secondary;
        color: $brand-primary;

        &:not(.product-tile-horizontal) {
            background-color: $brand-secondary;
            color: $brand-primary;

            &:before {
                content: '';
                box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.4);
                opacity: 0;
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                transition: all 300ms ease-in;            
            }

            &:hover {
                &:before {
                    opacity: 1;
                }
            }

            .pdp-link {
                .link {
                    color: $brand-primary;
                }
            }
        }
    }
}

.product-tile-alternate {
    .subtitle,
    .link {
        color: $brand-black;
        font-size: 16px;
        line-height: 26.4px;
        letter-spacing: normal;
    }

    .subtitle {
        font-weight: 700;
        text-transform: uppercase;
        padding-top: 2px;
        padding-bottom: 3px;
    }

    .link {
        font-weight: 400;
        text-decoration: none;
    }

    .price {
        .sales {
            .value {
                font-size: 12px;
                font-weight: 400;
                line-height: 20.4px;
                letter-spacing: normal;
                color: $brand-black;
                opacity: .65;
            }
        }
    }
}

.product-tile:not(.product-tile-horizontal) {
    .product-tile-image-container,
    .image-container {
        padding: 15px 0;
    }

    .badge-container-overflow {
        min-height: 28px;
        max-height: 28px;
        overflow: hidden;
    }

    .badge-container-placeholder {
        min-height: 28px;
    }
}