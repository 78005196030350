@include media-breakpoint-down(sm) {
    .text-below-image {
        .hero-container {
            flex-direction: column;
            padding:0px;
        }
        .hero-media, .hero-text {
            position: relative;
        }
        .hero-media {
            padding-bottom: 50%;
            top: 0;
            transform: none;
            right: 0;
        }
    }

    .text-above-image {
        .hero-container {
            flex-direction: column-reverse;
            padding:0px;
        }
        .hero-media, .hero-text {
            position: relative;
        }
        .hero-media {
            padding-bottom: 50%;
            top: 0;
            transform: none;
            right: 0;
        }
    }
}

[class*='experience-commerce_layouts'] {
    //enables setting class on layout's custom class 
    .no-gutters  {
        .row {
            padding-right: 0;
            padding-left: 0;
            @include media-breakpoint-up(xl) {
                padding-right: 0;
                padding-left: 0;
            }
            & > [class*=" col-"] {
                padding-right: 0;
                padding-left: 0;
            }
        }
    }
}

// ensure photoTile,
// photoHotspots
// heroBanner all have image tiles that are width: 100%
div:not(.product-grid) {
    .experience-component {
        &.experience-commerce_assets-photoTile,
        &.experience-commerce_assets-photoHotspots,
        &.experience-commerce_assets-heroBanner {
            img.image-fluid.photo-tile-image {
                width: 100%;
            }
        }
    }
}

// ensure photoTile, 
// photoHotspots, 
// and heroBanner stretch 100% of product tile height in regard to sibling tiles
body .product-grid {
    .experience-region {
        height: 100%;
    }

    .experience-component {
        &.experience-commerce_assets-imageAndText,
        &.experience-commerce_assets-photoTile,
        &.experience-commerce_assets-photoHotspots,
        &.experience-commerce_assets-heroBanner {
            height: 100%;

            .photo-hotspots,
            .photo-tile-container,
            .photo-tile-container > .row {
                height: 100%;
            }
            
                figure.photo-tile-figure {
                    height: 100%;
                    width: 100%;
                    position: relative;
                    overflow: hidden;
                }
                    
                    // remove for photo hotspots
                    img.image-fluid.photo-tile-image {
                        // height: 100%;
                        min-height: 100%;
                        width: auto;
                        min-width: 100%;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }

            .image-text-block {
                height: 100%;

                .image-cropper {
                    height: 100%;
                    max-height: none;

                    .image-wrapper {
                        height: 100%;
                        position: absolute;

                        .background-image {
                            height: 100%;
                            min-height: 100%;
                            width: auto;
                            min-width: 100%;
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                }
            }
        }
    }    
}

//  experience-commerce_layouts
// fixing alignment issues when full width and photo-tile-container is used
.experience-commerce_layouts-1column,
.experience-commerce_layouts-2column,
.experience-commerce_layouts-3column,
.experience-commerce_layouts-4column,
.experience-commerce_layouts-6column {
    .container-fluid > .row {
        margin-left: 0;
        margin-right: 0;
    }

    .full-bleed-mobile-only > .container {
        @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
// REMOVE MARGIN / PADDING ON PHOTO TILE CONTAINER ELEMENT PARENT MARKUP
.photo-tile-container {
    > .row {
        margin-left: 0;
        margin-right: 0;

        > .col-12 {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.experience-component {
    .hero-text,
    h1, h2, h3, h4, h5, h6 {
        word-break: break-word;
    }
}