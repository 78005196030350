@import "~core/product/grid";

.vertical-slide-out-filter-btn {
    border: 0;
    background-image: none;
    background-color: transparent;
    white-space:nowrap;
    height: auto;
    width: auto;

    .icon-filter {
        font-size:28px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 100%;
    }
    button {
        padding-right: 5px;
        text-decoration: none;
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
        width: auto !important;
        @include media-breakpoint-up(lg) {
            padding-right: 10px;
        }
    }
}

.vertical-slide-out-sortBy {
    &:before {
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
    }
    select[name="sort-order"] {
        border: 0px;
        position:relative;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        padding-right: 20px;
        background-position-x: 100%;
    }

    &:only-child {
        margin-left: auto;
    }
}

.vertical-slide-out-refinements {
    position: fixed;
    z-index: 1000;
    height: 100%;
    top: 0px !important;
    bottom: 0;
    left: 0;
    background-color: $white;
    display: flex !important;
    flex-direction: column;
    transition: left .25s ease;
    left: -500px;
    margin:0px !important;
    padding:0px !important;
    width: 100%;
    max-width: 312px;

    &.in {
        left: 0;
    }

    > div {
        padding: 6.5%;
        z-index: 1;
        background-color: white;
    }

    .refinement-header {
        flex: 0 0 auto;
        font-size: 16px;
        line-height: 32px;
        font-weight: 500;
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid $off-white;
        letter-spacing: .1px;
        padding-bottom: 4.3%;
        .refinement-header-title {
            flex:1 auto;
            letter-spacing: .1px;
            color: $black;
        }
        .refinement-header-close {
            flex:0 auto;
            padding: 10px;
            margin-top: -6px;
            margin-right: -10px;
            font-weight: 600;
            font-size: 13px;
            line-height: 24px;
            color: $dark-gray;
            opacity: 1;
            letter-spacing: .1px;
            text-decoration: none;
            position: relative;
            span {
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    bottom: -5px;
                    border-bottom: 2px solid currentColor;
                    width: 100%;
                }
            }
        }
    }
    .refinement-body {
        flex: 1 1 auto;
        list-style-type: none;
        overflow-y: auto;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 0px;
        .card {
            margin-bottom: 20px;
        }
        .card-header,
        .card-body {
            padding-left: 6.55%;
            padding-right: 6.55%;
        }
        .card-header {
            .card-title {
                font-size: 18px;
                line-height: 32px;
                font-weight: normal;
                padding-bottom: 15px;
                border: 0;

                &::after {
                    right: 6px;
                    font-size: 35px;
                }
            }
        }
        .card-body {
            font-size: 13px;
            line-height: 24px;
        }
        .values.content {
            padding: 0 6px;
            margin-left:10px;
            li {
                padding-bottom: 12px;
            }
            .show-more-refinements-wrapper,
            .show-more-refinements {
                flex-basis: 100%;
            }
            .values li {
                padding-top: 0px;
            }
            button {
                display: flex;
                align-items: center;

                i {
                    min-width: 24px;
                    min-height: 24px;
                }
            }
        }
        .refinement-colorfilter,
        .refinement-bandcolor,
        .refinement-dialcolor {
            .values.content {
                padding: 0 10px;
                li {
                    padding-bottom: 20px;
                }
            }
        }
        #refinement-group-category {
            li  {
                padding-bottom: 0px;
                button { padding-bottom: 12px; }
            }
        }
    }
    .refinement-footer {
        flex: 0 0 auto;
        background: $brand-secondary;
        display: flex;
        justify-content: space-between;
        button {
            &.close {
                @extend .btn;
            }

            &.btn-primary {
                opacity: 1;
                text-shadow: none;

                // reset focus/hover styles back to mixin default
                &:focus {
                    opacity: 1;
                    color: $button-primary-color;
                    background-color: $button-primary-background;
                }

                &:hover {
                    opacity: 1;
                    color: $button-primary-color-hover;
                    background-color: $button-primary-background-hover;
                }
            }
        }

        > button {
            flex: 1 auto;
            max-width: 132px;
        }
    }

    .swatch-circle {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        position: relative;
        background-color: $light-gray;
        &:before{
            content: '';
            border-radius: 50%;
            background-color: transparent;
            position: absolute;
            top: -4px;
            bottom: -4px;
            left: -4px;
            right: -4px;
        }
        &.selected:before {
            border: 2px solid $black;
        }
    }

    .filter-bar {
        flex-basis: 100%;
        margin-top: 5px;
        ul {
            margin-top: -10px;
        }
        li {
            margin-left: 0;
            margin-right: 10px;
            margin-bottom: 0;
            margin-top: 10px;
            &.filter-value {
                button {
                    border:0px;
                    padding-top: 9px;
                    padding-bottom: 9px;
                    background-color: $brand-secondary;
                    padding-right: 32px;
                    &::after {
                        right: 5px;
                        font-size: 16px;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .card:first-child {
        border-top: none;
    }

}

.search-results-row {
    min-width: 100%;
}

.search-results {
    .grid-header {
        .result-count:only-child {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .product-grid {
        .hero {
            &:not(.no-min-height) {
                min-height: 100%;
                margin-bottom: 0px;
            }
        }

        .photo-tile-container {
            [class*="col-"] {
                margin-bottom: 0;
            }
        }
    }
}

 .product-grid .chromeless {
    height: auto;
}

.product-grid {
    &.row:not(.no-gutters) {
        @include media-breakpoint-down(sm) {
            margin-right: -18px;
            margin-left: -18px;
        }

        > .col,
        > [class*="col-"] {
            margin-bottom: 4px;

            @include media-breakpoint-down(sm) {
                padding-left: 2px;
                padding-right: 2px;
            }

            @include media-breakpoint-up(md) {
                margin-bottom: 50px;
            }
        }
    }
    
    .product {
        height: 100%;
    }
}

.scroll-direction-down {
    .fixed-header-enhanced {
        ~ #maincontent {
            .refinement-bar {
                @include media-breakpoint-down(md) {
                    height: 100vh;
                }
            }
        }
    }
}

.grid-header,
.content-grid-header {
    margin-bottom: 0;

    .filter-results {
        margin-top: 9px;
    }
}

.refinement-bar,
.vertical-slide-out-refinements {
    background-color: $refinement-filter-bg-color;

    .card-header {
        background-color: $refinement-filter-bg-color;
    }

    .refinement-body {
        .refinements {
            .values.content button i {
                min-width: 15px;
                min-height: 15px;
    
                &.fa-square-o,
                &.fa-check-square {
                    width: 15px;
                    height: 15px;
                }

                &.fa-circle-o,
                &.fa-check-circle {
                    width: 16px;
                    height: 16px;

                    &::after {
                        width: 16px;
                        height: 16px;
                    }
                }

                &.fa-square-o {
                    border-width: 1px;
                }
            }
        }

        // ensure filter cards are always visile (disables card toggle)
        div[class*='collapsible-'] .content, 
        div[class*='collapsible-'] .card-body {
            display: flex;
            background-color: $refinement-filter-bg-color;
        }

        .card {
            margin-bottom: 0;
        
            &.aside {
                border-top: 0;
            }

            .card-header .card-title {
                &:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
                    font-size: 18px;
                    font-weight: 500;
                    background-color: $refinement-filter-bg-color;
                    
                    span {
                        font-size: inherit;
                    }
                }
    
                &::after {
                    display: none;
                }
            }
        }
    }
}

.vertical-slide-out-refinements > div {
    background-color: $refinement-filter-bg-color;
}

.dark-theme {
    .refinement-bar,
    .vertical-slide-out-refinements {
        background-color: $refinement-filter-dark-theme-bg-color;
    
        .card-header {
            background-color: $refinement-filter-dark-theme-bg-color;
        }
    
        .refinement-body {
            // ensure filter cards are always visile (disables card toggle)
            div[class*='collapsible-'] .content, 
            div[class*='collapsible-'] .card-body {
                background-color: $refinement-filter-dark-theme-bg-color;
            }
    
            .card {
                .card-header .card-title {
                    &:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
                        background-color: $refinement-filter-dark-theme-bg-color;
                    }
                }
            }
        }
    }
    
    .vertical-slide-out-refinements > div {
        background-color: $refinement-filter-dark-theme-bg-color;
    }
    
    // ensure that product tiles inside an element with bulova-bg-dark/bulova-bg-light render medium-gray bg
    .product-tile:not(.product-tile-horizontal) .product-tile-image-container:not(.transparent-tile) {
        background-color: $medium-gray;
    }
}


.refinement-bar:not([class*="refinement-bar-horizontal"]) + div {
    margin-top: 10px;
}