@import "~core/components/badges";

.badge-product-container {
    position: static;
    margin: 0 0 4px;

    .badge-product,
    .badge-product:not([class*='text--']),
    .badge-product:not([class*='font-size--']) {
        color: $white;
        font-size: 12px;
        line-height: 12px;
        padding: 4px;
        background-color: $light-navy;
        border: 1px solid $light-navy;
        text-transform: none;
        margin-bottom: 6px;

        &:not(:last-child) {
            margin-right: 6px;
        }

        @include media-breakpoint-up(md) {
            font-size: 12px;
            line-height: 12px;
            background-color: $light-navy;
            text-transform: none;
            padding: 4px 12px;
        }
    }
}

.badge-product {
    display: none;
    
    &:first-of-type {
        display: block;
    }

    &:not([class*='text--']) {
        background-color: transparent;
        font-family: $secondary-font;;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
    }

    &:not([class*='font-size--']) {
        font-size: $product-tile-badge-font-size;

        @include media-breakpoint-up(md) {
            font-size: $product-tile-badge-font-size-lg
        }
    }

    @include media-breakpoint-up(md) {
        display: block;
    }
}

.product-tile,
.product-detail .product-tile {
    .badge-product-container,
    .badge-product-container.badge-image-over {
        position: absolute;
    }
}

.product-tile-alternate {
    .badge-product-container {
        @include media-breakpoint-up(md) {
            .badge-product {
                padding-left: 0;
            }

            &.badge-image-over {
                position: relative;
            }
        }
    }
}
