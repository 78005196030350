@import "~org_citizen/components/flexibleGrid";

.flex-grid-wrapper {
    .grid-title:not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
        font-size: 26px;
        text-transform: capitalize;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 24.75px;
    }

    .flex-grid {
        .flex-grid-item {
            position: relative;
            display: flex;
            flex-flow: row wrap;

            //Give child element full width to allow components like video component to display
            > * {
                width: 100%;
            }
        }
    }
}
