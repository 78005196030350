@import "~core/checkout/checkout";

#authenticationModal iframe {
    height: 410px;
    border: none;
}

.shipping-method-option {
    cursor: pointer;
    margin-right: 12px;
}

.data-checkout-stage,
.receipt,
.account-page {
// confirmation page
    .confirm-details {
        .shipping-method-arrival-time {
            @include paragraph-font-size;
        }
    }

    .card .order-product-summary {
        border: 0;
        margin: 0;
    }

    .order-product-summary-card {
        .order-product-summary-header {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            flex-wrap: wrap;

            .card-header-custom {
                padding-right: 20px;
            }

            .edit-cart-link {
                padding: 0 $checkout-form-padding 0 0;
                font-size: 12px;
                text-decoration: underline;
            }
        }
    }

    &[data-checkout-stage=placeOrder] {
        .checkout-promo-code-card {
            display: none;
        }

        .checkout-promo-code-card ~ .card {
            border-top: 1px solid $card-body-border-color;
        }
    }
}

.customer-information-block {
    strong {
        font-size: 13px;
        font-weight: normal;
    }
    .btn-link {
        text-decoration: underline;
    }
}

.coupon-price-adjustment {
    margin-top: $spacer;
    background-color: $card-body-background;
    border: 1px solid $card-body-border-color;
    border-radius: $card-border-radius;
    padding: $input-padding-top $input-padding-right $input-padding-bottom $input-padding-left;
    font-size: $input-font-size-md;
    line-height: $input-font-line-height;
    letter-spacing: $input-font-letter-spacing;
    position: relative;
    .coupon-applied,
    .coupon-not-applied {
        @include paragraph-font-size(small);
    }
    .coupon-applied {
        color: $success;
    }
    .coupon-not-applied {
        color: $danger;
    }
    .remove-coupon {
        @include delete-card-button;
        padding: $input-padding-top $input-padding-right $input-padding-bottom $input-padding-left;
    }
    .coupon-promotion-relationship {
        @include paragraph-font-size(small);
        margin-bottom: 0;
    }
}

.coupon-missing-error {
    display: none;
}

.promotion-information {
    @include clearfix;
    margin-bottom: $spacer/2;
    margin-top: $spacer/2;
}
.applied-promotion-discount {
    color: $checkout-promo-message-color;
    float: right;
}

// Checkout modals
.delete-coupon-confirmation-body {
    .coupon-to-remove {
        margin: $spacer 0 0;
        font-weight: bold;
    }
}
.delete-confirmation-body {
    .product-to-remove {
        margin: $spacer 0 0;
        font-weight: bold;
    }
}

body {
    .unit-price {
        del,
        .strike-through {
            text-decoration: none;
            color: $brand-primary;
        }

        del + span.sales {
            display: none;
        }
    }
}

// hide sales tax line item for mexico locale
.locale-es_MX {
    .sales-tax-item {
        display: none;
    }
}

.klaviyo-inputs {
    input,
    label {
        cursor: pointer;
    }

    &.form-group {
        display: flex;
        align-items: center;

        label {
            margin-bottom: 0px;
            margin-left: 5px;
        }
    }
}

.reason-for-purchase {
    margin-bottom: 15px;    
}

.checkout-receipt {
    .card {
        &.order-product-summary {
            border-top: 1px solid $off-white;
        }
    }
}

// checkout-only helper class
.checkout-only,
.checkout-only.row {
    display: none;
}

.data-checkout-stage {
    @media (min-width: 1400px) {
        padding-left: 0;
        padding-right: 0;
    }
    
    // checkout-only helper class
    .checkout-only {
        display: initial;

        &.row {
            display: flex;
        }
    }

    // elements to hide in the checkout order summary
    .product-summary-block {
        .summary-details.gift-summary,
        .product-line-item .item-price-qty,
        .line-item-availability,
        .line-item-promo {
            display: none;
        }

          // minicart styles
        .product-summary .product-image {
            width: 80px;
            margin-right: 18px;
        }
    }  

    .minicart-remove-and-price {
        flex-direction: row;
        align-items: center;

        .line-item-price {
            margin-left: unset;
            @include media-breakpoint-up(md) { 
                margin-left: auto;
            }
        }
        
        .remove-line-item {
            display: block;
        }
    }

    .product-summary {
        .item-image {
            @include media-breakpoint-up(md) {
                margin-right: 18px;
                width: 28%;
                max-width: 125px;
            }

            @include media-breakpoint-up(lg) {
                margin-right: 36px;
            }
        }
    }

    .order-product-summary-card {
        .js-shipping-line-items {
            display: none;
        }
    }

    .card.order-total-summary-card {
        border-top: 0;
    }

    .order-total-summary {
        padding-left: $checkout-form-padding;
        padding-right: $checkout-form-padding;
        .checkout-continue-container {
            display: none;
        }  
        .grand-total {
            margin-bottom: 0;
        } 

        // Check Out Right Side
        .grand-total .order-receipt-label,
        .grand-total-sum {
            font-size: 24px;
            font-weight: 600;
        }
    }

    .billing-address-block .summary-details {
        padding-bottom: $checkout-spacer;
    }

    .shipping-summary {
        .summary-details {
            + .gift-summary {
                margin-top: 20px;
                padding-bottom: 0;
            }
        }
    }

    .checkout-continue-container {
        display: none;
    }

    // hide remove button on payment, placeOrder, and Submitted
    &[data-checkout-stage=payment],
    &[data-checkout-stage=placeOrder],
    &[data-checkout-stage=submitted] {
        .product-summary-block {
            .minicart-remove-and-price {
                .remove-line-item {
                    display: none;
                }
            }
        }
    }
}

/** Shopify-esque style updates **/
.data-checkout-stage {
    .row {
        [class*='col-'] {
            .card {
                ~ .card.customer-section, 
                ~ .card.customer-summary {
                    border: 0;
                }
            }
        }
    }

    label {
        cursor: pointer;
    }

    .card {
        border: 0;
        
        .card-header {
            background-color: transparent;
            
            h2 {
                font-family: $checkout-font-family;
                font-weight: 600;
                letter-spacing: normal;
                font-size: 26px;
                padding-left: $checkout-form-padding;
                padding-right: $checkout-form-padding;
            }

            .sign-in-link,
            .edit-button {
                padding-bottom: 15px;
                padding-right: $checkout-form-padding;
            }

            .js-login-customer {
                cursor: pointer;
                padding: 0;
                vertical-align: baseline;
                border: none;
                text-decoration: underline;
                padding-bottom: 15px;
                padding-right: $checkout-form-padding;
            }

            ~ .card-body {
                padding: 0 $checkout-form-padding 15px;
            }
        }

        .card-body {
            background-color: transparent;
        }
    }

    .js-login-customer {
        letter-spacing: inherit;
    }

    .btn {
        border-radius: $checkout-border-radius-size;
    }

    input[type=checkbox] {
        accent-color: $blue-enabled;
    }

    .billing-address-block {
        margin-bottom: 18px;
    }

    .summary-section-label,
    .shipping-method-block h3,
    .billing-address-block .address-selector-block label {
        font-family: $checkout-font-family;
        font-size: 20px;
        margin-bottom: 12px;
        color: $black;
    }

    .billing-address {
        margin-bottom: 20px;
    }

    .address-selector-block {
        .addressSelector {
            border-radius: 5px;
            height: 63px;
        }

        .btn-show-details,
        .btn-add-new {
            font-weight: 600;
            letter-spacing: normal;
            margin-bottom: 24px;
            text-decoration: underline;
        }
    }

    .leading-lines .end-lines span:only-child, 
    .leading-lines .end-lines span:last-child {
        padding-left: 0;
        padding-right: 0;
        margin-right: 0;
    }

    .leading-lines {
        .end-lines,
        .start-lines {
            span,
            label {
                background-color: transparent;
            }
        }

        &.grand-total {
            padding-top: 10px;
            padding-bottom: 30px;
        }
    }

    .leading-lines .start-lines span.tooltip-icon,
    .tooltip-icon {
        top: auto;
        display: flex;
        align-items: center;

        &:before {
            content: "\3F";
            display: inline-block;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            font: var(--fa-font-light);
            font-size: 11px;
            line-height: 17px;
            text-align: center;

            width: 19px;
            height: 19px;
            border-radius: 50%;
            border: 1px solid currentColor;
        }

        &:hover {
            &:before {
                color: inherit;
            }
        }
    }

    .info-icon:hover:before, 
    .info-icon[aria-describedby*="tooltip"]:before, 
    .tooltip-icon:hover:before, 
    .tooltip-icon[aria-describedby*="tooltip"]:before {
        color: inherit;
    }

    // info icon 
    .info-icon {
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);

        &:before {
            color: $gray;
        } 

        &:hover {
            color: inherit;
            &:before {
                color: inherit;
            }
        }
    }

    .is-invalid + .tooltip-icon {
        transform: translateY( calc(-50% - 10px) );
    }

    .contact-info-block {
        // margin-bottom: 40px;
        
        .info-icon {
            font-size: 22px;
        }

        .float-label,
        .float-label.float-label-has-input {
            .form-control {
                padding-right: 36px;
            }
        }
    }

    .error-message {
        margin-top: 24px;
        margin-bottom: 24px;
        border-radius: 5px;;
    }

    .leading-lines .start-lines span:only-child, 
    .leading-lines .start-lines span:last-child {
        &[data-toggle=tooltip] {
            padding-right: 0;
        }
    }

    // toggle hide/show on shipping method depending on if address form is complete or not
    .shipping-method-block {
        .leading-lines {
            margin-bottom: 0;
        }

        .shipping-method-list {
            .start-lines,
            .end-lines {
                margin-bottom: 0;
                min-height: $checkout-float-label-height + 6px;
                display: flex;
                align-items: center;
                border-top: 1px solid $light-gray;
            }

            .start-lines {
                padding-left: 20px;
                border-left: 1px solid $light-gray;

                &:first-of-type {
                    border-top-left-radius: $checkout-border-radius-size;
                }

                &:nth-last-of-type(-n+2) {
                    border-bottom: 1px solid $light-gray;
                    border-bottom-left-radius: $checkout-border-radius-size;
                }

                &.active,
                &.active + .end-lines {
                    background-color: $blue-pressed;
                    border-color: $blue-enabled;
                }
                // must also change border color of next 2 elements due to html arrangement
                &.active + .end-lines + .start-lines,
                &.active + .end-lines + .start-lines + .end-lines {
                    border-top-color: $blue-enabled;
                }
            }

            .end-lines {
                padding-right: 20px;
                border-right: 1px solid $light-gray;
                justify-content: flex-end;
                font-weight: 600;

                &:nth-of-type(2) {
                    border-top-right-radius: $checkout-border-radius-size;
                }

                &:last-of-type {
                    border-bottom: 1px solid $light-gray;
                    border-bottom-right-radius: $checkout-border-radius-size;
                }
            }
            
            .start-lines,
            .end-lines {
                align-self: stretch;
                justify-self: stretch;
            }

            .custom-radio {
                display: flex;
                align-items: center;
                line-height: 24px;
                
                .custom-control-label {
                    border-width: 1px;

                    &:before {
                        top: 0;
                        bottom: 0;
                        margin: auto;
                    }
                }

                .custom-control-input {
                    &:checked ~ .custom-control-label {
                        &:after,
                        &:before {
                            top: 0;
                            bottom: 0;
                            margin: auto;
                        }

                        &:before {
                            border: 0;
                            background-color: $blue-enabled;
                        }

                        &:after {
                            width: 8px;
                            height: 8px;
                            left: calc(-29px + 6px / 2);
                            background-color: $white;
                        }
                    }
                }
            }
        }

        &.hide-shipping {
            .shipping-method-list {
                display: none;
            }
        }

        &:not(.hide-shipping) {
            .shipping-method-message {
                display: none;
            }   
        }
    }

    // gift card
    .js-balancemsg {
        &:not(:empty) {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    .js-giftcertificatepaymentinstruments {
        *:last-child {
            margin-bottom: 30px;
        }
    }

    // start button overwrites
    .submit-shipping,
    .submit-payment {
        height: $checkout-float-label-height;
    }

    .oauth-google,
    .promo-code-btn,
    .submit-shipping,
    .submit-payment,
    .submit-customer-login,
    .js-cancel-login,
    .js-applybalancebutton,
    .place-order,
    .create-account-btn,
    .continue-as-guest {
        font-weight: 600;
        letter-spacing: normal;
        font-family: inherit;
        font-size: 18px;
    }

    .promo-code-btn {
        height: $checkout-float-label-height;
    }

    .login-oauth .oauth-google[class*="btn-"]:not(.title) { 
        padding-left: 0;
        padding-right: 0;

        &:before {
            position: static;
            transform: none;
            padding-right: 5px;
        }
    }
    // end button overwrites

    // Payment Stuff
    .card.payment-form {
        > .card-body {
            padding-bottom: 0;
        }
        // hide summary if edit button clicked
        &.toggle-height-active {
            .summary-details.billing {
                display: none;
            }
        }
    }

    // Adyen Style Overrides
    .adyen-component-content {
        #paymentMethodsList {
            padding-left: 0;
            margin-bottom: 0;
            
            .paymentMethod {
                background-color: $brand-secondary;
                border: 1px solid $light-gray;
                border-radius: $checkout-border-radius-size;
                padding: 16px 22px;
                margin-bottom: 18px;

                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                > input[type=radio] {
                    position: absolute;
                    z-index: -1;
                    opacity: 0;
                }

                > label {
                    cursor: pointer;
                }
            }
        }

        .adyen-checkout__card__exp-cvc {
            .adyen-checkout__label {
                margin-bottom: 0;
            }
        }

        .adyen-checkout__helper-text, 
        .adyen-checkout__label__tex {
            font-size: 13px;
            line-height: 1.4;
        }

        .additionalFields {
            margin: 10px 0 0;
        }
    }

    .adyen-checkout__input {
        height: $checkout-float-label-height;
    }

    // mobile footer - ensure its flush
    .next-step-button {
        margin-top: 0;

        .btn {
            margin-top: 24px;
        }

        // remove sticky positioning on mobile
        @include media-breakpoint-down(sm) {
            position: relative;
            left: auto;
            right: auto;
            top: auto;
            bottom: auto;
            box-shadow: none;
            background-color: transparent;
            z-index: auto;
        }
    }

    .product-summary {
        .product-line-item {
            .line-item-name {
                font-family: $checkout-font-family;
                font-weight: 600;
                color: $black;
            }
        }
    }

    // stickty order summary styles
    // still needs js tweaking in combo w/ fixed-enhanced-header
    .sticky-order-summary,
    .sticky-order-summary-container {
        will-change: transform;
        transform: translateY(0);
        transition: transform $header-transition-speed ease-out;
    }
    
    .sticky-order-summary {
        @include media-breakpoint-up(md) {
            position: sticky;
            top: 20px;
        }
    }

    .checkout-col-2 {
        @include media-breakpoint-down(md) {
            border-top: 1px solid $light-gray;
            border-bottom: 1px solid $light-gray;
        }
    }

    .order-summary-wrapper {
        @include media-breakpoint-down(md) {
            &:before {
                content: '';
                border-top: 1px solid $light-gray;
                position: absolute;
                top: 59px;
                left: 0;
                display: block;
                z-index: 1;
                width: 100%;
            }
        }
    }

    .checkout-col-1,
    .order-summary-wrapper,
    .order-summary-toggle {
        max-width: $checkout-mobile-max-width;
        margin-left: auto;
        margin-right: auto;
        @include media-breakpoint-up(lg) {
            max-width: none;
            margin-left: 0;
            margin-right: 0;
        }
    }

    .order-summary-wrapper,
    .order-summary-toggle {
        padding-left: 20px;
        padding-right: 20px;
        @include media-breakpoint-up(lg) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    @include media-breakpoint-up(lg) {
        .checkout-col-1 > .card,
        .order-summary-wrapper > .card {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    &[data-checkout-stage=shipping] {
        .shipment-selector-block {
            padding-top: 0;
        }
        .shipping-address-block:not(.d-none) + .shipping-method-block {
            margin-top: 12px;
        }
    }

    // Payment -------------------------------
    &[data-checkout-stage=payment] { 
        .payment-error-messages-shipping {
            display: none;
        }

        [data-address-mode=details] {
            .summary-details.billing {
                display: none;
            }
        }

        [data-address-mode=edit] {
            .btn-add-new,
            .btn-show-details,
            .addressSelector {
                display: none;
            }
        }
    }
}

// checkout tooltip styles - has to be top level
[data-action^=Checkout-Begin] {
    ~ .tooltip {
        .tooltip-inner {
            color: $white;
            background-color: $tooltip-gray;
            border-radius: $checkout-border-radius-size;
            font-size: 14px;
            line-height: 23px;
            max-width: 190px;
        }

        &.bs-tooltip-top, 
        &.bs-tooltip-auto[x-placement^="top"] {
            .arrow {
                &:before {
                    border-top-color: $tooltip-gray;
                }
            }
        }
    }
}

// 2 tone BG
.bg-domino {
    @include media-breakpoint-up(lg) {
        border-bottom: 1px solid $light-gray;
        background: linear-gradient(90deg, white 0%, white 58.25%, $checkout-gradient-gray 58.25%, $checkout-gradient-gray 100%);
    }
}

.checkout-col-2 {
    background-color: $checkout-gradient-gray;
}

// order summary hide/show
.order-summary-block {
    @include media-breakpoint-up(md) {
        padding-left: 20px;
        padding-right: 20px;
    }

    @include media-breakpoint-up(lg) {
        padding-left: 0;
        padding-right: 0;
    }

    .hide-order-summary {
        display: none;
    }

    @include media-breakpoint-down(lg) {
        .order-summary-wrapper {
            display: none;
        }

        &.toggle-height-active {
            .hide-order-summary,
            .order-summary-wrapper {
                display: block;
            }
            .show-order-summary {
                display: none;
            }   
        }
    }

    .order-summary-wrapper {
        overflow: hidden;
        transition: height 280ms ease-in-out;
    }

    &.hide-summary {
        .hide-order-summary {
            display: none;
        }
        .show-order-summary {
            display: inline;
        }

        .order-summary-wrapper {
            height: 0;
        }
    }
}

// Checkout button spinner
.btn-checkout {
    .spinner {
        width: $checkout-spinner-size;
        height: $checkout-spinner-size;
        margin-top: ($checkout-spinner-size / 2) * -1;
        margin-left: ($checkout-spinner-size / 2) * -1;
    }
}

// Hide Adyen UI buttons - We override and show related component automatically
.form-nav.billing-nav.payment-information {
    display: none;
}

.giftcertificate-information-container {
    .giftcertificate-information {
        background-color: $brand-secondary;
        border-radius: 5px;
        border: 1px solid $light-gray;
        padding: 16px 2px;
        margin-bottom: 18px;

        .summary-section-label {
            font-size: 16px;
            letter-spacing: normal;
            line-height: 22px;
            font-weight: normal;
            margin-bottom: 0;
        }

        .payment-option-gift-card {
            width: 77px;
            margin: 0 0.25em 0 0.25em;

            img {
                margin: 0;
            }
        }

        .shipping-method-label {
            margin-left: 5px;
        }
        
        .giftcertificate-information-body {
            display: none;
        }

        &.toggle-height-active {
            border-width: 2px;
            border-color: $blue-enabled;
            
            .giftcertificate-information-body {
                display: block;
            }
        }
    }   
}

.edit.quickview:not(.line-item-name) {
    font-weight: 700;
    text-decoration: underline;
}

.data-checkout-stage .order-product-summary .product-line-item .item-attributes .item-options,
.receipt .order-product-summary .product-line-item .item-attributes .item-options {
    white-space: normal;
}

// hide +/- on zip code number input
input[type="number"].shippingZipCode::-webkit-outer-spin-button,
input[type="number"].shippingZipCode::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* For Firefox */
input[type="number"].shippingZipCode {
    -moz-appearance: textfield;
}

.paymentMethod,
.data-checkout-stage .adyen-component-content #paymentMethodsList .paymentMethod {
    // cursor: pointer;

    &.active-payment-method {
        border-width: 2px;
        border-color: $blue-enabled;
    }

    .paymentMethod_img {
        position: absolute;
    }

    > label {
        width: 100%;
        padding-top: 14px;
        padding-bottom: 14px;
        padding-left: 90px;
    }
}

.klarna-text {
    display: none;
}

.klarna-active {
    .submit-payment {
        border-color: $klarna-pink;
        background-color: $klarna-pink;

        .klarna-text {
            display: inline;
        }
    }
}