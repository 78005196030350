@import "~org_citizen/components/slider";

.slider-container {
    // slider bullets
    .tns-nav {
        padding-left: 50px;
        padding-right: 50px;
        width: 100%;
        max-width: 1300px;
        text-align: left;
        -webkit-tap-highlight-color: transparent;

        button {
            outline: 0;
            display: inline-block;
            zoom: 1;
            border: none;
            margin: 10px 6px;
            background-color: $pure-black;
            cursor: pointer;
            border-radius: 0;
            opacity: .5;

            &.tns-nav-active {
                background: $pure-black;
                opacity: 1;
                transform: none;
            }
        }
    }

     // slider nav buttons
     .tns-controls {
         button {
             &:before {
                height: 36px;
                width: 36px;
                background-color: $white;
                color: $black;
                outline-color: $black;
                line-height: 36px;
                border-radius: 50%;
            }
            &:hover:before {
                color: $black;
            }
         }
        [data-controls='prev'] {
            justify-content: flex-end;

            &:before {
                content: $icon-arrow-left;
            }
        }
        [data-controls='next'] {
            justify-content: flex-start;

            &:before {
                content: $icon-arrow-right;
            }
        }
    }

    &.light {
        .tns-controls {
            button {
                &:before {
                    color: $light-gray;
                    background-color: $white;
                    border-color: $light-gray;
                }

                &:after {
                    color: $light-gray;
                }
                
                &:hover {
                    &:before {
                        color: $light-gray;
                        background-color: $white;
                        border-color: $light-gray;
                    }
                }
            }
        }   
    }

    // dark slider (and default slider)
    .tns-controls,
    .tns-controls.dark {
        button {
            &:before {
                color: $slider-dark-button-color;
                background-color: $brand-black;
                border-color: $slider-dark-button-color;
            }
            &:hover {
                &:before {
                    color: $slider-dark-button-color;
                    background-color: $brand-black;
                    border-color: $slider-dark-button-color;
                }
            }
        }   
    }
}

// Thumbnails
.slider-thumbnails {
    .slide {
        &::after {
            content: '';
            pointer-events: none;
            @include absolute-cover;
            width: 6px;
            height: 6px;
            border-radius: 100%;
            background-color: transparent;
            transition: background-color $transition-speed ease-out;
        }
        &.tns-nav-active::after {
            background-color: transparent;
        }
    }
}

// product tile slider class - ensures that all product tiles have same height
.product-tile-slider {
    display: flex;
    align-items: stretch;

    .product,
    .category-tile,
    .image-text-block,
    .photo-tile-figure,
    .photo-hotspots {
        height: 100%;
    }
    .photo-hotspots,
    .photo-tile-figure {
        overflow: hidden;
    }

    .photo-tile-container {
        height: 100%;

        > .row {
            height: 100%;

            > .col-12 {
                height: 100%;

                .photo-tile-image {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .photo-hotspots {
        .photo-tile-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .category-tile,
    .image-text-block {
        &:not(.text-placement-below) {
            .image-cropper {
                height: 100%;
                > .image-wrapper {
                    height: 100%;
                }
            }
        }

        &.text-placement-below {
            display: flex;
            flex-direction: column;

            .image-cropper {
                max-height: none;
                margin: 0;
                flex-grow: 1;

                .image-wrapper {
                    height: 100%;
                }
            }
        }
    }
}

[data-slider-items-lg="4"] {
    @include media-breakpoint-up(xl) {
        .product-tile:not(.product-tile-horizontal) {
            padding: 16px;
            
            .badge-product-container {
                &.badge-image-over {
                    top: unset;
                    left: unset;
                }
            }

            .compare-badge-location:not(.move) {
                top: unset;
                right: unset;
            }

            .pdp-link {
                padding-bottom: 8px;
                margin-top: 3px;
                margin-bottom: 0;
    
                .link, .subtitle {
                    font-family: inherit;
                    font-weight: 400;
                    color: $brand-primary;
                    text-transform: unset;
                    font-size: 16px;
                    line-height: 21.45px;
                    letter-spacing: normal;
                    margin-bottom: 0;
                }
    
                .subtitle {
                    text-align: center;
                    font-weight: 700;
                    text-transform: uppercase;
                    margin-bottom: 8px;
                }
            }
        }
    }
}

.slider-title:not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
    text-transform: capitalize;
    font-size: 26px;
    font-weight: 400;
    letter-spacing: normal;
    padding-bottom: 45px;

    @include media-breakpoint-up(sm) {
        font-size: 22px;
    }

    @include media-breakpoint-up(md) {
        font-size: 26px;
    }
}