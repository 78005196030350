@import "~core/components/alert";

.alert {
    background-repeat: no-repeat;
    font-family: $alert-font-family;
    padding-top: $alert-padding-top;
    padding-bottom: $alert-padding-bottom;
    padding-right: $alert-padding-right;
    padding-left: $alert-padding-left;
    font-weight: $alert-font-weight;
    &-success {
        background-color: $alert-success-background;
    }
    &-warning {
        background-color: $alert-warning-background;
    }
    &-danger {
        background-color: $alert-danger-background;
    }
    &-info {
        background-color: $alert-info-background;
    }
}