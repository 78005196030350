@import "~org_citizen/components/menu";

.main-menu {
    .menu-group {
        .navbar-nav {
            > .nav-item {
                > .nav-link {
                    @include media-breakpoint-up(lg) {
                        padding:0 10px;
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                margin-left: 0;
            }
        }
    }
}
