// New Text Block component based on an abridged heroBanner component
.text-block-component {
    position: relative;
    overflow: hidden;
    display: flex;
}

.text-block-component-text {
    overflow: visible;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    // text alignment
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        &.text-center {
            align-items: center; // fix for IE11 not horizontally centering
        }
        &.text-right {
            align-items: flex-end; // fix for IE11 not right aligning
        }
    }

    // text elements
    .text-block-component-subtitle,
    .text-block-component-title,
    .text-block-component-body {
        // override styles from page designer injected tags
        &>* {
            font-family: inherit;
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
            text-transform: inherit;
            letter-spacing: inherit;
            color: inherit;
            margin-bottom: 0;
        }
    }
    .text-block-component-subtitle {
        &:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
            font-family: $hero-subtitle-font;
            font-size: $paragraph-font-size-small;
            text-transform: $hero-subtitle-text-transform;
        }
    }
    .text-block-component-title {
        &:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
            @include header($hero-title-size);
        }
    }
    .text-block-component-body {
        ul {
            list-style: disc;
            list-style-position: outside;
            margin-left: $spacer;
        }
        @include media-breakpoint-up(sm) {
            ul {
                columns: 2;
            }
        }
    }
    .text-block-component-subtitle + .text-block-component-title,
    .text-block-component-title + .text-block-component-body,
    .text-block-component-body + .text-block-component-buttons .text-block-component-button {
        margin-top: $hero-textblock-items-vertical-spacing;
    }

    // buttons
    .text-block-component-buttons {
        font-size: 0; // prevent extra space for inline-block elements
    }
    .text-block-component-button {
        margin-top: $spacer;
        display: inline-block;
        position: relative;
    }
    &.text-center .text-block-component-button:not(:only-child) {
        margin-left: $spacer/2;
        margin-right: $spacer/2;
    }
    &.text-left .text-block-component-button:not(:only-child) {
        margin-right: $spacer;
    }
    &.text-right .text-block-component-button:not(:only-child) {
        margin-left: $spacer;
    }
}
