@import '~org_citizen/mixins';

@mixin text-link {
    color: $link-color;
    font-family: $primary-font;
    font-weight: $link-font-weight;
    text-decoration: $link-text-decoration;
    letter-spacing: $link-letter-spacing;
    cursor: pointer;
    &:hover {
        color: $link-color;
        text-decoration: $link-text-decoration-hover;
        opacity: $link-text-opacity;
    }
    .dark-theme & {
        color: $link-dark-theme-color;
        &:hover {
            color: $link-dark-theme-color-hover;
        }
    }
}

@mixin text-link-secondary {
    color: $link-color;
    font-family: $secondary-font;
    font-weight: $link-secondary-font-weight;
    text-decoration: $link-secondary-decoration;
    font-size: $link-secondary-font-size;
    line-height: $link-secondary-line-height;
    letter-spacing: $link-letter-spacing;
    cursor: pointer;
    &:hover {
        color: $link-color-hover;
        text-decoration: $link-text-decoration-hover;
    }
    .dark-theme & {
        color: $link-dark-theme-color;
        &:hover {
            color: $link-dark-theme-color-hover;
        }
    }
}

@mixin button($style: "", $customBackground: "", $customText: "", $customBorder: "", $size: "") {
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
    border-width: $button-border-width;
    border-style: solid;
    font-family: $button-font;
    text-transform: $button-text-transform;
    transition: all $transition-speed ease-out;
    text-decoration: none;
    border-radius: $button-border-radius;
    text-align: $button-text-align;
    font-weight: $button-font-weight;
    @if $size == small {
        font-size: $button-sm-font-size;
        line-height: $button-sm-line-height;
        padding: $button-sm-padding-top $button-sm-padding-right $button-sm-padding-bottom $button-sm-padding-left;
        letter-spacing: $button-sm-letter-spacing;
    } @else {
        font-size: $button-font-size;
        letter-spacing: $button-letter-spacing;
        line-height: $button-line-height;
        padding: $button-padding-top $button-padding-right $button-padding-bottom $button-padding-left;
    }
    @if $style == primary {
        font-family: $button-primary-font;
        text-transform: $button-primary-text-transform;
        color: $button-primary-color;
        background-color: $button-primary-background;
        border-width: $button-primary-border-width;
        border-radius: $button-primary-border-radius;
        border-color: $button-primary-border-color;
        text-decoration: $button-primary-text-decoration;
        text-align: $button-primary-text-align;
        font-weight: $button-primary-font-weight;
        @if $size == small {
            padding: $button-sm-primary-padding-top $button-sm-primary-padding-right $button-sm-primary-padding-bottom $button-sm-primary-padding-left;
        } @else {
            padding: $button-primary-padding-top $button-primary-padding-right $button-primary-padding-bottom $button-primary-padding-left;
        }
        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            color: $button-primary-color-hover;
            background-color: $button-primary-background-hover;
            border-color: $button-primary-border-color-hover;
            text-decoration: $button-primary-text-decoration-hover;
        }
        .dark-theme & {
            color: $button-primary-dark-theme-color;
            &:hover {
               color:  $button-primary-dark-theme-color-hover !important;
               background-color: $button-primary-dark-theme-background-hover;
               opacity: $button-opacity;
            }
        }
    }
    @if $style == secondary {
        font-family: $button-secondary-font;
        text-transform: $button-secondary-text-transform;
        color: $button-secondary-color;
        border-width: $button-secondary-border-width;
        border-radius: $button-secondary-border-radius;
        background-color: $button-secondary-background;
        border-color: $button-secondary-border-color;
        text-decoration: $button-secondary-text-decoration;
        text-align: $button-secondary-text-align;
        font-weight: $button-secondary-font-weight;
        @if $size == small {
            padding: $button-sm-secondary-padding-top $button-sm-secondary-padding-right $button-sm-secondary-padding-bottom $button-sm-secondary-padding-left;
        } @else {
            padding: $button-secondary-padding-top $button-secondary-padding-right $button-secondary-padding-bottom $button-secondary-padding-left;
        }
        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            color: $button-secondary-color-hover;
            background-color: $button-secondary-background-hover;
            border-color: $button-secondary-border-color-hover;
            text-decoration: $button-secondary-text-decoration-hover;
            opacity: $button-opacity;
        }
        .dark-theme & {
            color: $button-secondary-dark-theme-color;
            &:hover {
                color: $button-secondary-dark-theme-color-hover;
                background-color: $button-secondary-dark-theme-background-hover;
            }
        }
    }
    @if $style == accent-red {
        font-family: $button-accent-red-font;
        text-transform: $button-accent-red-text-transform;
        color: $button-accent-red-color;
        border-width: $button-accent-red-border-width;
        border-radius: $button-accent-red-border-radius;
        background-color: $button-accent-red-background;
        border-color: $button-accent-red-border-color;
        text-decoration: $button-accent-red-text-decoration;
        text-align: $button-accent-red-text-align;
        font-weight: $button-accent-red-font-weight;
        @if $size == small {
            padding: $button-sm-accent-red-padding-top $button-sm-accent-red-padding-right $button-sm-accent-red-padding-bottom $button-sm-accent-red-padding-left;
        } @else {
            padding: $button-accent-red-padding-top $button-accent-red-padding-right $button-accent-red-padding-bottom $button-accent-red-padding-left;
        }
        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            color: $button-accent-red-color-hover;
            background-color: $button-accent-red-background-hover;
            border-color: $button-accent-red-border-color-hover;
            text-decoration: $button-accent-red-text-decoration-hover;
        }
        .dark-theme & {
            color: $button-accent-red-dark-theme-color;
            &:hover {
                color: $button-accent-red-dark-theme-color-hover;
            }
        }
    }
    @if $style == solid-black {
        font-family: $button-solid-black-font;
        text-transform: $button-solid-black-text-transform;
        color: $button-solid-black-color;
        border-width: $button-solid-black-border-width;
        border-radius: $button-solid-black-border-radius;
        background-color: $button-solid-black-background;
        border-color: $button-solid-black-border-color;
        text-decoration: $button-solid-black-text-decoration;
        text-align: $button-solid-black-text-align;
        font-weight: $button-solid-black-font-weight;
        @if $size == small {
            padding: $button-sm-solid-black-padding-top $button-sm-solid-black-padding-right $button-sm-solid-black-padding-bottom $button-sm-solid-black-padding-left;
        } @else {
            padding: $button-solid-black-padding-top $button-solid-black-padding-right $button-solid-black-padding-bottom $button-solid-black-padding-left;
        }
        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            color: $button-solid-black-color-hover;
            background-color: $button-solid-black-background-hover;
            border-color: $button-solid-black-border-color-hover;
            text-decoration: $button-solid-black-text-decoration-hover;
        }
        .dark-theme & {
            color: $button-solid-black-dark-theme-color;
            &:hover {
                color: $button-solid-black-dark-theme-color-hover;
            }
        }
    }
    @if $style == link {
        font-family: $button-link-font;
        text-transform: $button-link-text-transform;
        color: $button-link-color;
        border-width: $button-link-border-width;
        border-radius: $button-link-border-radius;
        border-style: none;
        background-color: $button-link-background;
        border-color: $button-link-border-color;
        text-decoration: $button-link-text-decoration;
        text-align: $button-link-text-align;
        font-weight: $button-link-font-weight;
        @if $size == small {
            padding: $button-sm-link-padding-top $button-sm-link-padding-right $button-sm-link-padding-bottom $button-sm-link-padding-left;
        } @else {
            padding: $button-link-padding-top $button-link-padding-right $button-link-padding-bottom $button-link-padding-left;
        }
        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            color: $button-link-color-hover;
            background-color: $button-link-background-hover;
            border-color: $button-link-border-color-hover;
            text-decoration: $button-link-text-decoration-hover;
        }
        .dark-theme & {
            color: $button-link-dark-theme-color;
            &:hover {
                color: $button-link-dark-theme-color-hover;
            }
        }
    }
}

@mixin burger-link {
    font-family: $nav-link-font-family;
    font-size: 12px;
    font-weight: 400;
    color: $brand-black;
    text-decoration: none;
    line-height: 20.4px;
    letter-spacing: normal;
    padding: 8px 10px;
    text-transform: uppercase;
}

// Use to zoom an image when you hover its container. Pass in the selector of the
// element that should be zoomed, e.g. '.background-image' and the amount to zoom it, e.g. 1.2
@mixin zoom-image($imageSelector, $zoomAmount) {
    @include media-breakpoint-up(md) {
        #{$imageSelector} {
            will-change: transform;
            transition: transform 8000ms ease-out;
        }
        &:hover {
            #{$imageSelector} {
                transform: scale($zoomAmount);
            }
        }
    }
}
