@import "~org_citizen/components/blog";

.blog-landing-top {
    letter-spacing: .25em;
    line-height: 1.3;
    font-weight: 300;
    padding-top: 190px;
    padding-bottom: 70px;

    @include media-breakpoint-up(lg) {
        .content-tile {
            max-height: 500px;

            .content-tile-image {
                &:before {
                    padding-top: 500px;
                }
            }

            &:hover {
                .content-tile-image {
                    transform: initial;
                }
            }
        }
    }
}

.blog-landing-title-author {
    align-items: flex-end;
    margin-bottom: 15px;

    @include media-breakpoint-up(sm) {
        margin-bottom: 30px;
    }
}

.blog-landing-heading {
    font-size: 18px;
    margin-bottom: 10px;
    line-height: 1.8;
    font-weight: inherit;
}

.blog-landing-title {
    font-size: 34px;
    margin-bottom: 20px;
    font-weight: inherit;

    a {
        font-weight: inherit;
    }
}

.blog-landing-author {
    font-size: 20px;
    margin-bottom: 0;
    font-weight: inherit;
}

.blog-landing-date {
    font-size: 20px;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: inherit;
    text-transform: none;
}

.blog-entry {
    padding-top: 35px;
    margin-bottom: 70px;
}

.fixed-header-enhanced {
    ~ #maincontent {
        .blog-landing-top {
            padding-top: 90px;
        }
    }
}