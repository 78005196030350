/** must use id as Page Designer renders this div and the id is only unique att avaiable **/
#global-ticker {
    height: 32px;
    
    @include media-breakpoint-up(lg) {
        height: 40px;
    }
}

.ticker-banner {
    .header-locale-container-container {
        @include media-breakpoint-up(lg) {
            width: 140px;
            z-index: 1000;
            position: absolute;
            left: 20px;
        }

        @media screen and (min-width: 1356px) {
            left: -1150px;
            right: 0;
            margin: auto;
        }
    }
    
    .accessibility {
        margin: auto;

        @include media-breakpoint-up(lg) {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
        }

        .accessibility-link {
            a {
                position: relative;
                z-index: 100;
            }
        }

        @include media-breakpoint-up(lg) {
            width: 140px;
            top: auto;
            left: auto;
            right: 24px;
        }

        @media screen and (min-width: 1356px) {
            left: 0;
            right: -1150px;
            margin: auto;
        }

        .accessibility-link {
            @include media-breakpoint-up(lg) {
                text-align: right;
            }
        }
    }

    .slider-container {
        font-size: 13px;
        width: 100%;
        flex-basis: 100%;
        padding: 0;

        .tns-controls {
            [data-controls="prev"] {
                left: 12px;
            }

            [data-controls="next"] {
                right: 12px;
            }

            @include media-breakpoint-up(lg) {
                [data-controls="prev"] {
                    left: 160px;
                }

                [data-controls="next"] {
                    right: 160px;
                }
            }

            @media screen and (min-width: 1356px) {
                [data-controls="prev"] {
                    left: 0;
                    right: 940px;
                    margin: auto;
                }

                [data-controls="next"] {
                    right: 0;
                    left: 940px;
                    margin: auto;
                }
            }
        }
    }

    .ticker {
        font-size: inherit;
        padding-top: 2px;
        padding-bottom: 2px;

        .ticker-text-mobile {
            height: 28px;
        }
        
        @include media-breakpoint-up(lg) {
            height: 40px;
            line-height: 40px;
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .ticker-inner-wrap {
        max-width: 900px;
        margin-right: auto;
        margin-left: auto;
        white-space: nowrap;
    }

    a,
    .text-link-secondary,
    button, 
    .btn {
        background-color: inherit;
        color: inherit;
    }

    button, 
    .btn {
        padding-top: unset;
        padding-bottom: unset;
    }
}

.ticker-banner {
    .slide,
    .ticker-item {
        height: 32px;
        @include media-breakpoint-up(lg) {
            height: 40px;
        }
    }
}