@import "~org_citizen/cart/cart";

.cart-page {
    .line-item-name {
        font-family: $primary-font;
    }

    .product-edit {
        a {
            letter-spacing: normal;
        }
    }

    .checkout-btn {
        opacity: 1;
    }

    .card.totals {
        border-top: solid 1px $pure-black;
    }

    .estimated-total {
        p {
            font-size: 12px !important;
        }

        @include media-breakpoint-up(lg) {
            p {
                font-size: 16px !important;
            }
        }
    }

    // needed or breaks costs alignment
    .leading-lines .end-lines span:only-child, .leading-lines .end-lines span:last-child {
        margin-right: -24px;

        @include media-breakpoint-up(lg) {
            margin-right: -20px;
        }
    }
}
