@import "~org_citizen/components/badges";

.badge-product-container {
    position: static;
    margin: 0;

    .badge-product,
    .badge-product:not([class*='text--']),
    .badge-product:not([class*='font-size--']) {
        background-color: transparent;
        color: $accent-red;
        border-color: transparent;
        font-size: 11px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 2px;
        line-height: 11.44px;
        text-transform: uppercase;

        &:not(:last-child) {
            margin-right: 6px;
        }

        @include media-breakpoint-up(md) {
            background-color: transparent;
            color: $accent-red;
            border-color: transparent;
        }
    }
}

.product-tile {
    .badge-product-container,
    .badge-product-container.badge-image-over {
        position: relative;
    }

    .badge-product-container {
        .badge-product,
        .badge-product:not([class*='text--']),
        .badge-product:not([class*='font-size--']) {
            &:not(:last-child) {
                margin-right: auto;
            }
        }
    }
}