.compare-bar-wrapper {
    border-top: 1px solid $off-white;
    bottom: 0;
    display: none;
    position: fixed;
    width: 100%;
    z-index: 10;
    background-color: $white;

    .compare-bar {
        padding-top: 10px;
    }

    .product-slots {
        height: 6.25em;

        .selected-product {
            border-right: 1px solid $off-white;

            &:last-of-type {
                border-right: none;
            }
        }   
    }

    .slot {
        background-color: $white;
        height: 5em;
        margin: 0 auto;
        position: relative;
        width: 6.788em;
        border-radius: $border-radius;

        img {
            float: left;
            height: 100%;
            position: static;
            border-radius: $border-radius 0 0 $border-radius;
        }

        i {
            color: $black;
            float: left;
            font-size: 1rem;
            position: relative;
        }

        .close {
            background-color: $white;
            height: 100%;
            width: 25%;
            border-radius: 0 $border-radius $border-radius 0;
            opacity: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }

        .action {
            text-align: center;
        }
    }

    .empty {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $off-white;
        font-size: 16px; 
    }

    .clear-all {
        color: $black;
    }

    .min-products-msg {
        color: $black;
        font-size: 12px;
        line-height: 14px;
        padding: 10px;
    }
}

.product-comparison {
    .back-to-results {
        margin: 1em 0;
    }

    .product-header > .col {
        padding: 0;
    }
}

.product-tile {
    .compare {
        font-size: 0.875em;
        border: 1px solid $light-navy;
        background-color: $white;

        .custom-checkbox {
            .custom-control-input {
                margin: 10px;
                margin-right: 50px;
                left: 0;
                pointer-events: auto;
            }
        }

        .custom-checkbox, .custom-radio {
            .custom-control-label {
                color: $light-navy;
                font-size: 12px;
                text-transform: uppercase;

                &:before,
                &:after {
                    display: none;
                }
            }
        }

        .custom-control {
            padding: 0px 10px;
        }
    }

    .compare-badge-location {
        display: none;
    
        &:not(.move) {
            position: absolute;
            top: 16px;
            right: 16px;
            line-height: 19px;
        }

        .custom-control-label {
            cursor: pointer;
        }
    
        @include media-breakpoint-up(md) {
            display: block;
        }
    }
}

.table-striped-column td:nth-of-type(odd) {
    background-color: $light-neutral;
}
