@import "~org_citizen/components/miniCart";

.minicart {
    padding-left: 0;

    @include media-breakpoint-up(lg) {
        padding-left: $spacer;
    }

    .popover {
        color: $black;
        top: auto;
        left: auto;
    }

    .product-summary {
        .line-item-name {
            font-family: $primary-font;
            letter-spacing: normal;
        }

        .item-attributes {
            p {
                letter-spacing: normal;
            }
        }
    } 

    .minicart-footer {
        .checkout-continue-container {
            .checkout-btn,
            .btn-secondary.checkout-btn {
                color: $white;
                border-color: $white;
                background-color: $pure-black;

                &:hover, &:focus {
                    color: $pure-black;
                    border-color: $pure-black;
                    background-color: $white;
                }

                @include media-breakpoint-up(md) {
                    color: $white;
                    border-color: $white;
                    background-color: $pure-black;

                    &:hover, &:focus {
                        color: $pure-black;
                        border-color: $pure-black;
                        background-color: $white;
                    }
                }
            }
        }
    }

    .minicart-quantity {
        padding-left: 3px;
    }

    .minicart-label {
        .minicart-link-text, .minicart-success-message {
            letter-spacing: normal;
        }
    }
}

#removeProductModal {
    .btn-outline-primary {
        &:hover, &:focus {
            color: $white;
            border-color: $white;
            background-color: $pure-black;
        }
    }
}
