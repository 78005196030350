#watchCalibersForm {
    width: 250px;
    margin: 0 auto;

    .form-control-label {
        font-size: 14px;
    }
}

#watchCaliberInfoModal {
    .watch-caliber-pdf-logo {
        width: 75px;
        height: 75px;
        background-image: url('../../images/watchCaliberIcons/watch-caliber-pdf.svg')
    }

    .watch-caliber-video-logo {
        width: 75px;
        height: 75px;
        background-image: url('../../images/watchCaliberIcons/watch-caliber-video.svg')
    }

    .watch-caliber-item {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid $border-color;

        &:last-of-type {
            margin-bottom: 0;
            border-width: 0;
        }
    }
}