@import '~core/product/detail';

.product-detail, .pdp-collapsible-content-section {
    margin-bottom: 0;

    .primary-images {
        margin-bottom: $spacer;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(lg) {
            .primary-images-main {
                &.product-has-thumbnails {
                    padding-left: 40px;
                }
            }
        }

        .slider-container {
            // show only first slide before slider is loaded
            &:not(.slider-loaded) {
                .slide {
                    &:not(:first-of-type) {
                        display: none;
                    }
                }
            }
        }
    }

    .primary-images-main {
        .slider-container {
            @include media-breakpoint-up(lg) {
                padding: 0;
            }
        }

        .slide {
            img {
                margin: 0 auto;
                height: fluid-value(250, 360);

                @include media-breakpoint-up(md) {
                    height: auto;
                }

                @include media-breakpoint-down(lg) {
                    max-height: 590px;
                }
            }

            // Start Real Time Watch Styles
            .watch-face {
                width: 360px;
                height: auto;
            }

            .clock {                
                .hand {
                    position: absolute;
                    filter: drop-shadow(3px 4px 1px rgba(0, 0, 0, 0.5));
                    width: 30px;
                    height: 360px;
                    top: 90px;
                    left: 47.25%;
                }
                  
                #secondHand {
                    z-index: 3;
                    width: 23px;
                    left: 47.75%; // always 3 pxs or .5% more than .hand's left property value
                }
                  
                #minuteHand {
                    z-index: 2;
                }
                  
                #hourHand {
                    z-index: 1;
                }
            }

            .date-number-container {
                position: absolute;
                top: 260px;
                left: 66.25%;
                // handle date shifting
                @include media-breakpoint-up(lg) { left: 67%;} 
                @media (min-width: 1040px) { left: 66.5%; }
                @media (min-width: 1070px) { left: 66%; }
                @media (min-width: 1090px) { left: 65.5%; }
                @media (min-width: 1120px) { left: 65%; }
                @media (min-width: 1150px) { left: 64.5%; }
                @media (min-width: 1180px) { left: 64%; }
                @media (min-width: 1210px) { left: 63.5%; }
                @media (min-width: 1240px) { left: 63%; }
                @media (min-width: 1280px) { left: 62.5%; }
                @media (min-width: 1310px) { left: 62.25%; }

                #dateNumber {
                    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
                    font-weight: 300;
                    font-size: 21px;
                }
            }
            // End Real Time Watch Styles
        }
    }

    .primary-images-desktop {
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    .primary-images-mobile {
        display: block;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    .primary-images-thumbnails {
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
            margin-top: 10px;
        }
        
        .slider-thumbnails {
            .slider {
                padding-bottom: 50px
            }

            &.slider-thumbnails-vertical-lg {
                .slider-thumbnail-controls {
                    .prev,
                    .next {
                        background-color: $off-white;

                        &:before {
                            color: $white;
                        }
                        &:after {
                            content: '';
                            display: block;
                            height: 5px;
                            width: 100%;
                            background: $brand-secondary;
                            position: absolute;
                        }
                    }

                    .prev {
                        &:after {
                            bottom: -5px;
                        }
                    }

                    .next {
                        &:after {
                            top: -5px;
                        }
                    }
                }
            }
        }

        .slide {
            @include media-breakpoint-up(lg) {
                margin-bottom: 9px;
            }
        }

        .primary-images-thumbnail {
            &.video-container {
                img {
                    width: 100%;
                }
            }
        }
    }

    .primary-images-backdrop {
        padding: 30px 30px 40px 30px;
        background-color:$brand-secondary;
        
        @include media-breakpoint-up(md) {
            padding: 35px 40px;
            max-height: 745px;
            position: sticky;
            top: 100px;
        }
    }

    .product-name-container {
        .wishlist {
            &:not(.move) {
                position: relative;
            }
        }
    }

    .pdp-setting-instructions-video-link {
        @include button(secondary);
    }

    .wishlist {
        &:not(.move) {
            position: absolute;
            top: initial;
            right: initial;
            margin-right: 0;
            margin-left: auto;

            @include media-breakpoint-up(md) {
                top: initial;
                right: initial;
            }

            @include media-breakpoint-up(lg) {
                top: initial;
                right: initial;
            }
        }

        .wishlist-icon {
            background-color: transparent;

            &:before {
                font-size: 30px;
                line-height: 30px;
                color: currentColor;
            }
        }
    }

    .product-name-container {
        margin-bottom: 8px;
    }

    .product-name,
    h1.product-name,
    .h1.product-name {
        text-transform: none;
        margin-bottom: 0;
    }

    .product-subtitle {
        @include pdp-lead-1;

        margin-bottom: 12px;
    }

    .product-number-rating {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 0;
        clear: none;
    }

    .ratings {
        margin-left: 0;
    }

    .product-number {
        @include pdp-product-number;
    }

    .addtocart-sticky-bar {
        .prices {
            margin: 0;

            .price {
                padding: 0;
                margin: 0;
                font-size: 14px;
            }
        }
    }

    .prices {
        display: flex;
        flex-wrap: wrap;
        margin-top: 0;
        margin-bottom: 10px;

        @include media-breakpoint-up(sm) {
            margin-bottom: 10px;
        }

        @include media-breakpoint-up(md) {
            margin-top: 0;
        }
        .vta-message {
            @include pdp-h4-lead();
            margin-top: 24px;
            margin-right: auto;
        }

        .price {
            padding-right: 10px;
            margin-right: 0;
            margin-top: $spacer;
            font-size: $pdp-price-font-size-sm;
            font-family: $pdp-price-font;
            font-weight: bold;

            @include media-breakpoint-up(md) {
                font-size: $pdp-price-font-size;
            }
        }
    }

    .price-disabled-text {
        padding-right: 10px;
        margin-right: auto;
        margin-top: $spacer;
    }

    .availability-badge {
        @include pdp-availability-badge;

        margin-top: $spacer;
        color: map-get($colors, danger);
        display: none;
    }

    .promotions {
        margin-top: 0;

        .promotion {
            @include pdp-promotions;
            font-weight: normal;
            margin-top: 5px;
            color: $pdp-promo-color;

            &:first-of-type {
                margin-top: 0;
            }

            .info-icon:before, 
            .tooltip-icon:before {
                color: $light-navy;
            }
        }

        .tooltip {
            width: 98%;
        }
    }

    .setting-instructions-link {
        letter-spacing: normal;
        text-decoration: none;
        width: 100%;

        @include media-breakpoint-up(md) {
            max-width: 365px;
        }

        .icon {
            line-height: inherit;
        }

        &:hover {
            text-decoration: none;

            > span {
                text-decoration: underline;
            }
        }
        
        .setting-instructions-link-text {
            text-decoration: underline;
        }
    }

    .setting-instructions-row {
        .setting-instructions-link {
            .setting-instructions-link-text {
                text-decoration: none;
            }

            &:hover {
                .setting-instructions-link-text {
                    text-decoration: underline;
                }   
            }
        }
    }
    
    .pdp-rule {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .product-description-label {
        margin-bottom: 2px;
    }

    .availability-container {
        display: flex;
        align-items: center;
        margin-top: 0;
        margin-bottom: 0;

        p {
            @include pdp-availability;
            margin: 0;
        }
    }

    .product-option {
        margin-top: 0;

        @include media-breakpoint-up(md) {
            margin-top: 0;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 0;
        }
    }

    .product-options {
        flex: 1 1 auto;

        .options-select {
            height: 100%;
            text-align: center;
            line-height: 20px;
            padding: 12px 25px 12px;

            @include media-breakpoint-up(md) {
                padding-left: $select-padding-left;
            }
        }
    }

    .apple-pay-actions {
        margin-top: 12px;
    }

    button.dw-apple-pay-button.applePaySpecificityOverride {
        width: 100%;
        height: 48px;
        margin: 0;
        background-image: -webkit-named-image(apple-pay-logo-black);
        background-color: white;
        background-size: 50px 20px;
        border-width: 2px;
        border-radius: 0%;

        @include media-breakpoint-up(md) {
            flex: 0 1 auto;
        }
    }

    .product-options ~ button.dw-apple-pay-button.applePaySpecificityOverride {
        width: 50%;
        margin-left: 5px;

        @include media-breakpoint-up(md) {
            margin-left: 15px;
            flex: 0 1 155px;
        }
    }

    .qty-cart-container {
        margin-top: 20px;

        @include media-breakpoint-up(md) {
            .quantity {
                margin-top: $spacer;
            }
        }
    }

    .size-guide-asset-container {
        line-height: 32px;
        margin-left: 10px;
    }

    .prices-add-to-cart-actions {
        display: flex;

        .cart-and-ipay {
            flex-grow: 1;
        }
        
        .product-list-enhancements-toggle-product:not(.move) {
            position: relative;
            background-color: $black;
            color: $white;
            width: 48px;
            margin-left: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        button.wishlist.product-list-enhancements-toggle-product {
            transition: all 200ms ease-out;
            border-width: 2px;
            &:hover {
                background-color: $white;
                color: $black;
                border: solid 2px;
                border-color: $black;
            }

            .wishlist-icon:before {
                font-size: 20px;
                line-height: 1;
            }
        }
    }

    .recommendation-thumbnails-title {
        @include pdp-recommendation-thumbnails-title;

        margin-bottom: 6px;
        color: $dark-gray;

        @include media-breakpoint-up(md) {
            margin-bottom: 11px;
        }
    }

    .recommendation-thumbnails {
        margin: 0 -5px 20px;

        .recommendation-thumbnail {
            padding: 5px;
            flex-grow: 0;

            a {
                display: inline-block;
            }

            img {
                width: 80px;
                max-width: 80px;
                height: auto;
            }
        }
    }

    .pdp-collapsible-content {
        &.collapsible-xl {
            .title {
                &::after {
                    top: 10px;

                    @include media-breakpoint-up(md) {
                        top: 35px;
                    }
                }
            }
        }

        .card-header {
            .card-title {
                padding: 10px 0;

                @include media-breakpoint-up(md) {
                    padding: 35px 0;
                }
            }

            h3 {
                @include pdp-collapsible-title();

                padding: 0;
            }
        }

        .card-body {
            padding-bottom: 30px;

            @include media-breakpoint-up(md) {
                padding-bottom: 53px;
            }
        }
    }

    .pdp-component {
        margin-bottom: 15px;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }

    .video-thumbnail-bg {
        background-size: contain
    }

    .pdp-detail-attribute-col {
        &:nth-child(even) {
            margin-bottom: 20px;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(md) {
            &:nth-child(even) {
                margin-bottom: 46px;
            }
        }
    }

    .pdp-detail-attribute-header {
        padding-right: 0;
        margin-bottom: 14px;
        letter-spacing: normal;

        h4 {
            @include pdp-h4-lead();
            font-weight: bold;
            margin: 0;
        }
    }

    .pdp-detail-attribute-header-align {
        @include media-breakpoint-up(md) {
            margin-right: 3%;
        }
        
        @include media-breakpoint-up(lg) {
            margin-right: 3.1%;
        }
    }

    .pdp-detail-attribute-list {
        padding: 0;
        margin: 0;
        list-style: none;

        .pdp-detail-attribute-list-item {
            @include pdp-attribute-item();

            margin-bottom: 0;

            &:last-of-type {
                margin-bottom: 0;
                padding-bottom: 17px;
            }

            &.pdp-detail-attribute-list-item-bold {
                @include pdp-attribute-item-bold();
            }
        }
    }

    .setting-instructions-container {
        display: flex;
        flex-wrap: wrap;
        margin-top: 25px;
        align-items: center;

        @include media-breakpoint-up(md) {
            margin-top: 20px;
        }

        h4 {
            @include pdp-h4-lead();

            width: 100%;
            flex-basis: 100%;
            margin-bottom: 9px;

            @include media-breakpoint-up(md) {
                width: auto;
                flex-basis: auto;
                margin-bottom: 0;
                margin-right: 30px;
            }
        }

        .setting-instructions-pdf-download-link {
            margin-right: 15px;
        }
    }

    .features-col-1 {
        max-width: 732px;

        &.features-col-1-single {
            max-width: none;

            img {
                margin: auto;
            }
        }
    }

    .features-col-2 {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $light-gold;
    }

    .feature-text-container {
        padding: 30px 25px;
        text-align: center;

        h2 {
            margin-bottom: 20px;
            text-transform: none;
            letter-spacing: normal;

            @include media-breakpoint-up(md) {
                margin-bottom: 52px;
            }
        }
    }

    .feature-text-list {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    .feature-text-list-item {
        @include pdp-feature-list-item();

        margin-bottom: 15px;

        &:last-of-type {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 12px;
        }
    }

    .additional-functions-header {
        @include pdp-h4-lead();
        font-weight: bold;
        margin-bottom: 15px;
    }

    .pdp-additional-functions-list-item {
        @include pdp-additional-functions-list-item();

        margin-bottom: 3px;

        @include media-breakpoint-up(md) {
            margin-bottom: 10px;
        }
    }

    .collapsible-content-container-fluid,
    .collapsible-content-container {
        .card {
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .collapsible-content-container {
        padding: 0;
    }

    .product-option-modal {
        .product-options {
            .options-select {
                width: 100%;
            }
        }
    }
}

.addtocart-sticky-bar {
    height: auto;
    padding-top: 15px;
    padding-bottom: 0;
    top: auto;
    bottom: 0;
    transform: translateY(100%);
    box-shadow: $pdp-sticky-addtocart-shadow-mobile;

    @include media-breakpoint-up(md) {
        height: 164px;
        padding: 25px 0;
        margin: 0;
        box-shadow: $pdp-sticky-addtocart-shadow;
        top: 0;
        transform: translateY(-100%);
    }

    .addtocart-sticky-bar-container {
        @include media-breakpoint-up(md) {
            @include update-container-padding;

            max-width: map-get($container-max-widths, xl);
        }
    }

    .product-name-container {
        margin-bottom: 8px;
    }

    .product-name,
    h1.product-name,
    .h1.product-name {
        @include pdp-sticky-bar-product-name();
        text-transform: none;
        margin-bottom: 8px;
    }

    .product-number {
        @include pdp-h4-lead();

        margin-bottom: 4px;
    }

    .price {
        font-size: 14px;
    }

    .name,
    .links {
        margin: 0;
    }

    .sticky-header.scroll-direction-up .showstickybar & {
        transform: translateY(0);
        transition: transform $header-transition-speed ease-out 50ms;

        @include media-breakpoint-up(md) {
            transform: translateY(var(--header-height));
        }
    }

    .product-options-row-container {
        width: 100%;

        @include media-breakpoint-up(md) {
            width: auto;
            // padding-left: 5px;
        }
    }

    .product-options-row {
        padding: 0 15px;

        @include media-breakpoint-up(md) {
            padding: 0;
        }

    }

    .product-options ~ button.dw-apple-pay-button.applePaySpecificityOverride {
        width: auto;
        flex: 1 1 auto;

        @include media-breakpoint-up(md) {
            width: auto;
            margin: 0 0 0 16px;
            flex: 0 1 120px;
        }
    }

    .product-options {
        flex: 1 0 auto;

        @include media-breakpoint-up(md) {
            flex: 0 1 184px;
        }
    }

    button.dw-apple-pay-button.applePaySpecificityOverride {
        width: 100%;
        flex: 1 1 100%;

        @include media-breakpoint-up(md) {
            width: auto;
            margin: 0 0 0 16px;
            flex: 0 1 120px;
        }
    }

    .cart-and-ipay {
        height: 48px;
        margin-top: 15px;
        flex: 1 0 auto;
        

        @include media-breakpoint-up(md) {
            height: auto;
            margin-top: 0;
        }

        .btn {
            flex-basis: 100%;
        }

        .add-to-cart.btn.btn-primary {
            width: 100%;
            height: 100%;
            border-width: 0px;

            @include media-breakpoint-up(md) {
                width: 172px;
                margin-left: 16px;
                border-width: $button-primary-border-width

            }
        }
    }
}

#stockNotificationModal {
    .modal-content {
        background-color: $white;
        padding: 30px;

        @include media-breakpoint-up(md) {
            width: 620px;
            padding: 40px;
        }
    }

    .form-control-label {
        margin-bottom: 15px;

        font-family: $primary-font;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: normal;

        @include media-breakpoint-up(md) {
            margin-bottom: 25px;

            font-size: 28px;
            font-weight: 400;
            line-height: 32px;
        }
    }

    .modal-footer {
        display: flex;
        padding-top: 30px;

        @include media-breakpoint-up(md) {
            padding-top: 60px;
        }

        button {
            flex-grow: 1;
        }
    }
}

.pdp-assets {
    padding-right: 0px;
    padding-left: 0px;
}

.cart-and-ipay {
    flex-wrap: wrap;
    .add-to-cart {
        &.btn {
            flex-basis: 100%;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: normal;
            font-weight: normal;
        }
    }
}

.options-and-add-to-cart {
    .options-actions {
        margin-bottom: 15px;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            
            + .prices-add-to-cart-actions {
                .cart-and-ipay {
                    padding-left: 12px;
                }
            }
        }
    }

    .product-options {
        @include media-breakpoint-up(md) {
            margin-right: 12px;
        }
    }
}

.product-detail {
    .badge-product-container {
        &.badge-image-over {
            top: initial;
            left: initial;
            position: static;
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
        }
    }
}

// style also used by tabs PD component
.internal-links {
    position: relative;

    &:before {
        content: '';
        display: block;
        height: 1px;
        width: 100%;
        background: $light-gray;
        top: 40px;
        position: absolute;
    }

    .tab-content > .tab-pane {
        display: initial;
    }

    .internal-links-nav {
        list-style-type: none;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
        overflow-x: auto;
        max-width: 1020px;
        margin-bottom: 1px;

        &::-webkit-scrollbar {
            display: none;
        }

        .internal-links-nav-item,
        .nav-item {
            text-align: center;
            display: flex;
            flex-grow: 1;
            justify-content: center;
            padding-left: 20px;
            padding-right: 20px;

            &.active {
                &:after {
                    opacity: 1;
                }
            }  

            .internal-links-nav-link,
            .nav-link {
                display: inline-block;
                text-transform: uppercase;
                font-weight: 700;
                white-space: nowrap;
                position: relative;
                letter-spacing: normal;
                padding-left: 0;
                padding-right: 0;
                line-height: 20px;

                &:after {
                    content: '';
                    width: 100%;
                    height: 4px;
                    background-color: $gray;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    opacity: 0;
                    transition: opacity 200ms ease-in-out;
                }

                &:hover {
                    text-decoration: none;
                    &:after {
                        content: '';
                        opacity: 1;
                    }
                }
                &.active {
                    &:after {
                        opacity: 1;
                    }
                }    
            }
        }
    }
}

.internal-links-section {
    &:not(:first-child) {
        .collapsible-content-group {
            > .card-header {
                border-top: 1px solid $light-gray;
            }
        }
    }
    
    .pdp-collapsible-content {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
    }

    .collapsible-content-container {
        padding: 0;
    }

    .card {
        .card-title {
            h3 {
                text-transform: capitalize;
            }
        }
        .card-header,
        .card-body {
            padding-left: 20px;
            padding-right: 20px;
            @include media-breakpoint-up(lg) {
                padding-left: 24px;
                padding-right: 24px;
            }
        }
    }
}

// PDP STYLE slider
.pdp-product-slider {
    padding-left: 0;
    padding-right: 0;
    
    @include media-breakpoint-up(lg) {
        padding-left: 0;
        padding-right: 0;
    }
    
    // hide slider dots
    .tns-nav {
        display: none;
    }

    // hide wishlist, product id
    .product-tile {
        .wishlist,
        .product-id,
        .quickview {
            display: none;
        }
    }

    .product-tile {
        // only show first badge
        .badge-product-container {
            .badge-product {
                display: none;
    
                &:first-of-type {
                    display: unset;
                }
            }
        }
    }
}

.pdp-shipping-message-container {
    .pdp-shipping-icon {
        align-self: flex-start;
        margin-top: -4px;
    }
}

.pdp-shipping-message {
    font-size: 14px;

    .pdp-shipping-message-order-by {
        color: $dark-mauve;
    }

    span,
    .icon,
    .icon.icon-info {
        line-height: inherit;
    }

    .pdp-shipping-modal-trigger {
        cursor: pointer;
        text-decoration: none;
    }
}

// hide video play button if video is playing
.vjs-playing + {
    .video-thumbnail {
        .video-thumbnail-button {
            opacity: 0;
        }
    }
}

.pdp-collapsible-content {
    .pdp-detail-video-image {
        margin-left: -20px;
        margin-right: -20px;

        @include media-breakpoint-up(xl) {
            margin-left: -24px;
            margin-right: -24px;
        }
    }
    // if video && img are bother rendered, add margin top to image
    .video-container {
        + {
            img {
                margin-top: 40px;
            }
        }
    }
}

.klaviyo-inputs input {
    accent-color: gray;
}

.review-user-block {
    background-color: $brand-secondary;
}

// hide recaptcha badge
.grecaptcha-badge {
    display: none !important;
}

/** adjust for double modal / fixed page no scrolll bar */
.review-guidelines-modal {
    margin-left: -6px;
    h2 {
        font-size: 24px;
        font-family: $secondary-font;
        font-weight: 700;
        text-transform: capitalize;
    }    

    &.modal {
        .modal-header {
            .close {
                &:before {
                    font-size: 24px;
                }
            }
        }
    }
}

.notify-modal-image {
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}

// klarna message requires id in selector
// https://docs.klarna.com/on-site-messaging/additional-resources/placements/styling-on-site-messaging-with-css/
#klarna-strip {
    // develop env needs no ampersands in selector
    ::part(osm-message) {
        text-align: left;
    }
    ::part(osm-cta) {
        font-size: 12px;
    }
    ::part(osm-container) {
        border: 0;
        padding-left: 0;
    }
    // for some reason staging needs ampersands in SCSS selector
    &::part(osm-message) {
        text-align: left;
    }
    &::part(osm-cta) {
        font-size: 12px;
    }
    &::part(osm-container) {
        border: 0;
        padding-left: 0;
    }
}

.stock-notification-form {
    .email {
        margin-bottom: 5px;
    }  

    .stock-notification-privacy-label {
        display: inline;
    }

    .stock-notification-privacy-checkbox {
        transform: translateY(2px);
    }
}

// Flagship modal updates
.flagship-name {
    font-size: 16px;
    line-height: 1.4;
}

.flagship-headline,
.flagship-sub-headline {
    font-weight: 500;
    line-height: 1.4;
}

.flagship-headline {
    font-size: 20px;
}

.flagship-sub-headline {
    font-size: 12px;
}

.flagship-privacy-label {
    display: inline;
}

.flagship-form-group-wrap {
    @include media-breakpoint-up(sm) {
        max-width: 420px;
    }
}

.flagship-form {
    position: relative;

    @include media-breakpoint-up(md) {
        padding: 20px;
    }

    .form-control-label {
        display: none;
    }

    .form-group {
        &:not(:first-of-type):not(:last-of-type) {
            margin-top: 0;
        }
    }

    .klaviyo-checkbox {
        vertical-align: middle;
    }

    .flagship-contact-method-headline {
        text-transform: none;
        font-family: "Inter", sans-serif;
        font-weight: 500;
        font-size: 12px;
        line-height: 1.4;
        margin-bottom: 6px;
        letter-spacing: normal;
    }

    .flagship-contact-method {
        .custom-radio {
            .custom-control-label {
                line-height: 24px;
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }
}

.flagship-success {
    opacity: 0;
    transition: opacity 200ms ease-in-out;

    @include media-breakpoint-up(lg) {
        padding-top: 40px;
    }

    &.show-flagship-success {
        opacity: 1;
    }

    h3 {
        text-transform: none;
        font-weight: 900;
    }

    .flagship-text-address,
    .flagship-text-hours {
        p {
            line-height: inherit;
            margin-bottom: 0;
        }
    }

    .flagship-text-address {
        margin-top: 20px;
        line-height: 1.6;
    }
    .flagship-text-hours {
        line-height: 2;
    }
}

.stock-notification-modal,
.dark-theme .stock-notification-modal {
    .modal-content {
        color: $black;

        .btn-primary {
            @include button(primary);
        }

        .btn-secondary {
            @include button(secondary);
        }
    }
}

.pdp.dark-theme {
    .pdp-rule {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
    } 

    .product-detail, 
    .pdp-collapsible-content-section {
        .primary-images-thumbnails {
            .slider-thumbnails {
                &.slider-thumbnails-vertical-lg {
                    .slider-thumbnail-controls {
                        .prev,
                        .next {
                            &:before {
                                color: $medium-gray;
                            }
                            &:after {
                                background-color: $medium-gray;
                            }
                        }
                    }
                }
            }
        }

        .prices-add-to-cart-actions {
            button.wishlist.product-list-enhancements-toggle-product {
                background-color: $white;
                color: $black;
                border-color: $white;
                
                &:hover {
                    background-color: $black;
                    color: $white;
                    border-color: $white;
                }    
            }
        }
    }
}

// Fix bugs where sticky add-to-cart Y transform breaks layout
// html el
.sticky-header.scroll-direction-up {
    // body el
    .showstickybar {
        // header el that holds nav
        .top-header {
            &.static-header,
            &.fixed-header {
                ~ #maincontent {
                    .addtocart-sticky-bar {
                        transform: translateY(0);
                    }
                }
            }
        }
    }
} 

// product marketing video updates (pdp carousel slide & corresponding modal)
.pdp-carousel-video-container {
    .video-player {
        background: $brand-secondary;
    }
}

// style product marketing video with 9/16 aspect ratio
.product-marketing-video-modal {
    .modal-dialog {
        max-width: none;
        .modal-content {
            width: fit-content;
            margin: auto;
            // adjust for native brower chrome (~115px)
            height: calc(100vh - 115px);

            @include media-breakpoint-up(md) {
                height: calc(100vh - 80px);
            }
                   
            .modal-body {
                height: 100%;
                padding: 0;

                .product-marketing-video {
                    height: 100%;
                    display: block;
                }
            }
        }
    }

    // hide header, but show close button
    .modal-header {
        padding: 0;
        background: transparent;
        display: flex;
        justify-content: flex-end;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;

        .close {
            &:before {
                font-size: 24px;
                color: $white;
                padding: 6px;
                text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
            }
        }
    }

    /* Styles for screens where height is greater than width 
        177vw is representative of a browser window with a
        9/16 width to height ratio. This media query applies 
        to screens with a least a 9/16 width to height ratio */
    @media (min-height: 177vw) {
        width: 100%;

        @include media-breakpoint-up(sm) {
            width: 100%;
        }

        .modal-dialog {
            .modal-content {
                height: auto;

                @include media-breakpoint-up(sm) {
                    height: auto;
                }
                       
                .modal-body {
                    height: auto;
    
                    .product-marketing-video {
                        height: auto;
                        width: 100%;
                    }
                }
            }
        }
    }
}   
