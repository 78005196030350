@import "~core/components/card";

.card { 
    &.pdp-collapsible-content {
        margin-bottom: 0;
    }

    // Using :not() here to allow for overriding card titles from Page Designer
    .card-title:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom),
    .card-header h2,
    .card-header h3,
    .card-header h4,
    .card-header .checkout-card-header,
    h2.card-header {
        font-family: $primary-font;
    }
}