@import"~core/components/formFields";

.form-control{
    border-bottom: $input-border-width solid $input-border-color;
    border-top: none;
    border-left: none;
    border-right: none;

    &:focus,
    &.focus
    &:focus:hover {
        background-color: $input-background-color;
    }

    &:disabled,
    &.disabled {
        background-color: $input-disabled-background-color;
        color: $input-disabled-color;
        opacity: 1;
    }

    &:disabled[type="text"],
    &.disabled[type="text"] {
        border-bottom: none;
    }
}

textarea.form-control {
    &:disabled,
    &.disabled {
        border-bottom: none;
    }
    line-height: 1.4;
    border-top: 1px solid $light-gray;
    border-right: 1px solid $light-gray;
    border-left: 1px solid $light-gray;
}

select.form-control {
    border: $input-border-width solid $select-border-color;
    color: $select-color;
    background-color: $select-background;
    padding: $select-padding-top $select-padding-right-sm $select-padding-bottom $select-padding-left;

    @include media-breakpoint-up(md) {
        padding: $select-padding-top $select-padding-right $select-padding-bottom $select-padding-left;
        font-size: $input-font-size-md;
        line-height: $input-font-line-height-md;
    }

    &:focus,
    &.focus
    &:focus:hover {
        background-color: $select-background;
    }
}

.address-summary .city {
    display: inline-block;
}

// 'float-placeholders' should be added to '<form>' element for styles to take effect;
.float-placeholders {
    .form-group ~ .form-group {
        margin-top: 10px;
    }

    .form-control-label {
        display: none;
    }

    .form-control,
    .custom-select {
        border: 1px solid $light-gray;
        height: auto;
    }

    .custom-select {
        font-size: 16px;
        line-height: 30px;
        color: $dark-gray;
    }

    .form-control {
        line-height: 26px;
        padding-top: 10px;
        padding-bottom: 10px;

        &::placeholder {
            color: $gift-card-form-gray;
            opacity: 1;

            &:before {
                content: '*';
            }
        }

        @include media-breakpoint-up(lg) {
            font-size: 12px;
        }
    }   
}

/** Shopify-esque style updates **/
// styles for UI animations on float-label input / label
.float-label {
    position: relative;
    margin-top: 18px;

    // input / button styles
    .form-control,
    select.form-control,
    .btn {
        border-radius: $checkout-border-radius-size;
    }

    // input styles 
    .form-control,
    select.form-control {
        transition: all 200ms ease-in-out;
        border: 1px solid $light-gray;
        padding: 20px;
        height: $checkout-float-label-height;
        font-size: 17px;
        line-height: 20px;

        &.focus-visible {
            border-color: $blue-enabled;
        }

        &.is-invalid {
            border-color: $red;
        }
    }

    .form-control-label {
        font-size: 13px;
        transition: all 200ms ease-in-out;
        opacity: 0;   
        position: absolute;
        top: 9px;
        left: 21px;
        // taken from live alpina shopify site (fall 2024)
        transform: translateY(.21428571428571427em);
    }

    // hide pointer events on float-label select element labels (state & country)
    &[class*=stateCode],
    &[class*=country] {
        .form-control-label {
            pointer-events: none;
        }

        .custom-select {
            cursor: pointer;
        }
    }

    &.required {
        .form-control-label {
            &:before {
                position: absolute;
                transform: translateX(-.75em);
            }
        }

        &:before {
            content: '*';
            color: $red;
            opacity: 1;
            position: absolute;
            top: 21px;
            left: 10px;
            margin: auto;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
        }
    }

    // triggered when label has input
    &.float-label-has-input {
        .form-control {
            padding: 28px 20px 12px 20px;
        }

        .form-control-label {
            opacity: 1;
            transform: translateY(0);
        }

        &:before {
            opacity: 0;
        }
    }
}