@import "~org_citizen/components/tickerBanner";

.ticker-banner {
    .accessibility {
        .accessibility-link {
            a {
                letter-spacing: normal;
            }
        }
    }
}
