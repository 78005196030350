@import "~core/helperClasses";

// Create paragraph classes, e.g. p, .p
p,
.p {
    font-family: $secondary-font;
}

.text-link-secondary {
    @include text-link-secondary;
}

// breakpoint specific styles
@each $size in map-keys($grid-breakpoints) {
    @include media-breakpoint-down($size) {
        .background-#{$size} {
            @include color-classes(background-color);
        }

        .text-#{$size} {
            @include color-classes(color);
        }
        .background-#{$size} {
            @include color-classes(background-color);
        }
        .border-#{$size} {
            @include color-classes(border-color);
        }

         // Generate utility font classes, e.g. font-size--20
        @for $i from 0 through 60 {
            .font-size-#{$size}--#{$i} {
                font-size: #{$i}px !important;
            }
        }

        // Generate utility font classes, e.g. line-height--20
        @for $i from 0 through 60 {
            .line-height-#{$size}--#{$i} {
                line-height: #{$i}px !important;
            }
        }

        // Generate font classes, e.g. font--roboto
        @each $name, $font-stack in $fonts {
            .font-#{$size}--#{$name} {
                font-family: $font-stack;
            }
        }
    }
}

// p-xs and d-xs is not rendering from bootstrap so doing it manually here
@include media-breakpoint-up(xs) {
    @each $size, $length in $spacers {
        .p-xs-#{$size} { padding: $length; }

        .pt-xs-#{$size},
        .py-xs-#{$size} {
            padding-top: $length;
        }

        .pr-xs-#{$size},
        .px-xs-#{$size} {
            padding-right: $length;
        }

        .pb-xs-#{$size},
        .py-xs-#{$size} {
            padding-bottom: $length;
        }

        .pl-xs-#{$size},
        .px-xs-#{$size} {
            padding-left: $length;
        }

    }

    @each $value in $displays {
        .d-xs-#{$value} { display: $value; }
    }
}

.navigation-link-mega-menu {
    @include navigation-link-mega-menu();
}

// page-designer: component at full-height
.page-designer {
    .experience-component {
        .element-height-100,
        .element-height-full {
            min-height: 100vh;
        }
    }
}

// page designer: horizontal slide-in element
.element-horizontal-slide-track {
    min-height: 100vh;

    .experience-component {
        position: absolute;
        min-width: 100vw;
    }
}

// page designer: horizontal scroll and slider element
.element-horizontal-scroll,
.element-horizontal-scroll-with-slider {
    position: relative;

    .element-horizontal-scroll-track {
        position: absolute;
        white-space: nowrap;
        overflow-x: auto;
        width: max-content;
    }
}

// page designer: horizontal scroll element - content style
.watch-display {
    min-width: 100vw;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: inline-block;
            white-space: initial;

            max-width: 70vw;
            @include media-breakpoint-up(md) {
                max-width: 50vw;
            }
        }
    }

    &-item {
        text-align: center;
        margin: 0 5%;

        @include media-breakpoint-up(md) {
            display: grid;
            grid-template-columns: 1fr 2fr;
            grid-template-rows: 1fr repeat(4, auto) 1fr;

            margin: 0 15%;
            text-align: left;
        }

        h3 {
            margin-bottom: 6px;

            @include media-breakpoint-up(md) {
                grid-column: 2;
                grid-row: 2;
            }
        }

        h4 {
            font-family: $secondary-font;
            font-size: 10px;
            line-height: 12px;

            @include media-breakpoint-up(md) {
                grid-column: 2;
                grid-row: 3;
            }
        }

        &-image {
            margin: 20px 0;

            @include media-breakpoint-up(md) {
                grid-column: 1;
                grid-row: 1/7;

                text-align: center;
            }

            img {
                width: 100%;
                max-width: 180px;
                @include media-breakpoint-up(md) {
                    max-width: 224px;
                }
            }
        }

        &-desc {
            @include media-breakpoint-up(md) {
                grid-column: 2;
                grid-row: 4;
            }

            p {
                margin-bottom: 5px;
            }
        }

        &-cta {
            @include media-breakpoint-up(md) {
                grid-column: 2;
                grid-row: 5;
            }
        }
    }

    &-cta {
        position: absolute;
        bottom: 3%;
        left: 50%;
        transform: translate(-50%, -3%);
        @include media-breakpoint-up(md) {
            bottom: 10%;
            left: 50%;
            transform: translate(-50%, -10%);
        }

        text-align: center;

        .btn {
            margin: 10px 10px 0;
            white-space: nowrap;
        }
    }
}

.desktop-dark-mobile-light {
    color: $white !important;
    @include media-breakpoint-up(md) {
        color: $black !important;
    }
}

.desktop-light-mobile-dark {
    color: $black !important;
    @include media-breakpoint-up(md) {
        color: $white !important;
    }   
}

.light-mauve-desktop-light-neutral-mobile {
    color: $light-neutral !important;
    @include media-breakpoint-up(md) {
        color: $light-mauve !important;
    }
}

.breadcrumb-light-text {
    .breadcrumb-wrapper .breadcrumb .breadcrumb-item a {
        color: $off-white;
    }
    .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
        color: $off-white;
    }
}

.text-off-white {
    color: $off-white !important;
}

.product-tile-small-pading-title .category-tile.text-placement-below .category-tile-text {
    margin-top: 8px;
}

.text-case-normal {
    text-transform: none !important;
}

.text-decorate-underline {
    text-decoration: underline;
}

.dark-theme {
    .text-shadow {
        text-shadow: black 0 1px 7px;
    }
}

// ensure light-theme styles cascade if nested inside a .dark-theme ancestor
.light-theme,
.dark-theme .light-theme {
    .text-shadow {
        text-shadow: unset;
    }

    div:not(.hero) {
        .btn-primary {
            @include button(primary);
        }
    } 
}

// Transparent helper classes for PD components
.transparent-bg--25,
.transparent-bg--50,
.transparent-bg--75 {
    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.transparent-bg--25 {
    &:before {
        background: rgba(0,0,0, 0.25);
    }
}

.transparent-bg--50 {
    &:before {
        background: rgba(0,0,0, 0.50);
    }
}

.transparent-bg--75 {
    &:before {
        background: rgba(0,0,0, 0.75);
    }
}

/* 
* new helper utiltities based of Bootstrap convention
* Desktop >= 1024px 
*/
.w-lg-25 {
    @include media-breakpoint-up(lg) {
        width: 25% !important;
    }
}

.w-lg-50 {
    @include media-breakpoint-up(lg) {
        width: 50% !important;
    }
}

.w-lg-75 {
    @include media-breakpoint-up(lg) {
        width: 75% !important;
    }
}

/* 
* Tablet > 544px && < 1024px 
*/
.w-md-25 {
    // @include media-breakpoint-up(sm) {
    @media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, lg)) {
        width: 25% !important;
    }
}

.w-md-50 {
    @media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, lg)) {
        width: 50% !important;
    }
}

.w-md-75 {
    @media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, lg)) {
        width: 75% !important;
    }
}

/* 
* Mobile <= 544px 
*/
.w-sm-25 {
    @include media-breakpoint-down(xs) {
        width: 25% !important;
    }
}

.w-sm-50 {
    @include media-breakpoint-down(xs) {
        width: 50% !important;
    }
}

.w-sm-75 {
    @include media-breakpoint-down(xs) {
        width: 75% !important;
    }
}

.btn,
.btn-primary,
.btn-secondary,
.btn-tertiary {
    &[class*='icon'] {
        &:before,
        &:after {
            font-family: $icomoon-font-family;
        }
    }
}

// make same colors as primary
.btn-notify {
    @include button(primary);
}

.btn-flagship,
.btn-notify {
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
    letter-spacing: normal;
}

.btn-checkout {
    color: white;
    background-color: $blue-enabled;
    border-color: $blue-enabled;
    
    &:not(:disabled) {
        &:active {
            background-color: $blue-enabled;
            border-color: $blue-enabled
        }

        &:hover {
            color: $white;
            background-color: $blue-hover;
            border-color: $blue-hover;
        }

        &:focus {
            background-color: $blue-pressed;
            border-color: $blue-pressed;
        }
    }
}

.btn-checkout-inverse {
    color: $blue-enabled;
    background-color: $white;
    border-color: $blue-enabled;
    
    &:not(:disabled) {
        &:active {
            color: $blue-enabled;
            border-color: $blue-enabled;
        }

        &:hover {
            color: $blue-hover;
            border-color: $blue-hover;
        }

        &:focus {
            color: $blue-pressed;
            border-color: $blue-pressed;
        }
    }
}

.btn-solid-black {
    background-color: $pure-black;
    border-color: $pure-black;
    color: $white;

    &:hover {
        background-color: $white;
        border-color: $white;
        color: $pure-black;
    }
}

.btn-solid-gold {
    background-color: $gold;
    border-color: $gold;
    color: $white;

    &:hover {
        background-color: $white;
        border-color: $white;
        color: $gold;
    }
}

.btn-link {
    border-color: transparent;
    background-color: transparent;

    &:hover {
        border-color: transparent;
        text-decoration: underline;
    }
}

/** 
* Add font-weight 500 helper class following same  
* conventions as existing font-wieight classes 
**/
.font-weight-semibold {
    font-weight: 500 !important;
}

.letter-spacing-normal {
    letter-spacing: normal;
}

.cursor-pointer {
    cursor: pointer;
}

.space-between .flex-grid {
    justify-content: space-between;
}

.span-full-height div:not(.image-text-block-text) {
    height:100%;
}

.three-seven-height {
    >div {
        height: 100%;
    }

    .experience-component:first-child {
        height: 20%;

        +.experience-component {
            height: 80%;
            
            div:not(.image-text-block-text) {
                height: 100%;
            }
        }
    }
}

.half-half-height {
    > div {
        height: 100%;

        .experience-component {
            height: 50%;

            div:not(.image-text-block-text) {
                height: 100%;
            }
        }
    }
}

/** Gradient background helpers **/
.background-gradient--red-orange {
    background : linear-gradient(270deg, rgba(245, 197, 67, 1), rgba(222, 83, 71, 1) 100%);
}

.background-gradient--copper-yellow {
    background : linear-gradient(90deg, rgba(118, 34, 16, 1), rgba(201, 165, 100, 1) 100%);
}

.background-gradient--lightblue-darkteal {
    background : linear-gradient(90deg, rgba(223, 242, 242, 1), rgba(41, 97, 99, 1) 100%);
}

.background-gradient--sapphire-white-blue {
    background : linear-gradient(90deg, rgba(20, 84, 121, 1), rgba(216, 245, 255, 1) 100%);
}

// three color gradients
.background-gradient--copper-pink-orange {
    background: linear-gradient(90deg, rgba(142, 26, 0, 1) 0%, rgba(165, 83, 98, 1) 49%, rgba(176, 81, 43, 1) 100%);
}

.background-gradient--celadon-green-teal {
    background: linear-gradient(90deg, rgba(162, 223, 172, 1) 0%, rgba(59, 105, 65, 1) 49%, rgba(87, 147, 136, 1) 100%);
}

.background-gradient--brown-lightbrown-darkblue {
    background: linear-gradient(90deg, rgba(160, 46, 11, 1) 0%, rgba(205, 138, 72, 1) 49%, rgba(5, 51, 75, 1) 100%);
}

#privacy,
#terms,
#accessibility,
#cpra,
#warranty {
    ul,
    ol {
        li {
            @include media-breakpoint-up(lg) {       
                font-size: $paragraph-lg-font-size;
                line-height: $paragraph-lg-line-height;
            }
        }
    }
}

.add-to-cart-product-option {
    @include button(primary);
}