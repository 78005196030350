@import "bootstrap/scss/functions";
@import "utilities/icons";
/*
* COLORS
*/
$brand-off-white: #F9F8F6;
$brand-white: #FFFFFF;
$brand-black: #1C1C1C;
$brand-neutral: #BAB6AC;
$pure-black: #000000;
$accent-red: #A7071E;
$neutral-light: #E0DED9;
// Gray added here to resolve high level brand var dependency error 
$lighter-gray: #F6F5F3;

// High Level Vars
$brand-primary: $brand-black;
$brand-secondary: $lighter-gray;
$brand-tertiary: $light-gray;

// Grayscale
$white: #FFFFFF;
$off-white: #E0DED9;
$light-gray: #939393;
$gray: #777777;
$dark-gray: #3B3B3B;
$darker-gray: #1F2527;
$black: #010101;

// Notification / Sale / Promo
$green: #364D3B;
$red: #8D4636;
$yellow: #C79F70;
$blue: #253A63;
$success: $green ;
$danger: $red;
$warning: $yellow;
$info: $blue;
$sale: $red;
$alert-success-color: $green;

$colors: (
    brand-primary: $brand-primary,
    brand-secondary: $brand-secondary,
    brand-tertiary: $brand-tertiary,
    white: $white,
    off-white: $off-white,
    light-gray: $light-gray,
    lighter-gray: $lighter-gray,
    gray: $gray,
    dark-gray: $dark-gray,
    darker-gray: $darker-gray,
    black: $black,
    green: $green,
    red: $red,
    yellow: $yellow,
    blue: $blue,
    success: $success,
    danger: $danger,
    warning: $warning,
    info: $info,
    sale: $sale,
    accent-red: $accent-red,
    pure-black: $pure-black
);

/*
* GRID SYSTEM
*/
// These values should match the Breakpoints values in scripts/constants/SiteConstants.js
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 769px,
    lg: 992px,
    xl: 1200px
) !default;

// Containers are set to higher values than corresponding breakpoints to allow them to be full width
$container-max-widths: (
    md: 1024px,
    lg: 1200px,
    xl: 1400px
);

/*
* MISC
*/
$border-radius: 0px !default;
$spacer-sm: 5px !default;

/*
* FONTS (setup is handled in utilities/_fonts.scss)
*/
$primary-font: "Swiss 721", sans-serif;
$secondary-font: $primary-font;
$tertiary-font: $primary-font;
$header-font: $primary-font;
$body-font: $tertiary-font;
$link-color: inherit;
$base-font-color: $brand-black;

$fonts: (
    primary: $primary-font,
    secondary: $secondary-font,
    tertiary: $tertiary-font,
    header: $header-font,
    body: $body-font
);

/*
* TEXT
*/
// Stand Alone Text Defaults
$default-text-font: $primary-font;
$default-text-font-size: 15px;
$default-text-line-height: 24.75px;
$default-text-font-weight: 200;
$default-text-letter-spacing: normal;

// Paragraphs
$paragraph-font-size: $default-text-font-size; // paragraph size on mobile
$paragraph-line-height: $default-text-line-height;
$paragraph-lg-font-size: $default-text-font-size; // paragraph size on desktop
$paragraph-lg-line-height: $default-text-line-height;
$paragraph-font-size-large: $default-text-font-size; // large paragraph size on mobile
$paragraph-line-height-large: $default-text-line-height;
$paragraph-lg-font-size-large: $default-text-font-size; // large paragraph size on desktop
$paragraph-lg-line-height-large: $default-text-line-height;
$paragraph-font-size-small: $default-text-font-size; // small paragraph size on mobile
$paragraph-line-height-small: $default-text-line-height;
$paragraph-lg-font-size-small: $default-text-font-size; // small paragraph size on desktop
$paragraph-lg-line-height-small: $default-text-line-height;
$paragraph-letter-spacing: $default-text-letter-spacing;

// Links
$link-color: $brand-white !default;
$link-color-hover: $brand-white !default;
$link-text-decoration-hover: underline !default;
$link-dark-theme-color: $brand-black !default;
$link-dark-theme-color-hover: $white !default;
$link-color-disabled: $lighter-gray !default;
$link-font-weight: 200;
$link-text-decoration: underline !default;
$link-font-size: 15px;
$link-line-height: 24.75px !default;
$link-secondary-font-weight: 200 !default;
$link-secondary-decoration: underline !default;
$link-secondary-font-size: 15px !default;
$link-secondary-line-height: 24.75px !default;
$link-letter-spacing: normal !default;
// BS variable (odd naming convention)
$link-hover-color: $link-color-hover !default;

/*
* HEADERS
*/
// Mobile header values
$h1-font-family: $header-font;
$h1-font-size: 22px;
$h1-line-height: 36.3px;
$h1-font-weight: 700;
$h1-letter-spacing: 4.4px;
$h1-text-transform: uppercase;
$h1-font-style: normal;

$h2-font-family: $header-font;
$h2-font-size: 20px;
$h2-line-height: 33px;
$h2-font-weight: 700;
$h2-letter-spacing: 4px;
$h2-text-transform: uppercase;
$h2-font-style: normal;

$h3-font-family: $header-font;
$h3-font-size: 15px;
$h3-line-height: 24.75px;
$h3-font-weight: 700;
$h3-letter-spacing: normal;
$h3-text-transform: uppercase;
$h3-font-style: normal;

$h4-font-family: $header-font;
$h4-font-size: 13px;
$h4-line-height: 21.45px;
$h4-font-weight: 700;
$h4-letter-spacing: 2.6px;
$h4-text-transform: uppercase;
$h4-font-style: normal;

$h5-font-family: $header-font;
$h5-font-size: 10px;
$h5-line-height: 21.45px;
$h5-font-weight: 700;
$h5-letter-spacing: 2.6px;
$h5-text-transform: uppercase;
$h5-font-style: normal;

$h6-font-family: $default-text-font;
$h6-font-size: $default-text-font-size;
$h6-line-height: $default-text-line-height;
$h6-font-weight: $default-text-font-weight;
$h6-letter-spacing: $default-text-letter-spacing;
$h6-text-transform: none;
$h6-font-style: normal;

// Medium desktop header values
$h1-md-font-size: $h1-font-size;
$h1-md-line-height: $h1-line-height;
$h1-md-font-weight: $h1-font-weight;
$h1-md-letter-spacing: $h1-letter-spacing;
$h1-md-text-transform: $h1-text-transform;
$h1-md-font-style: $h1-font-style;

$h2-md-font-size: $h2-font-size;
$h2-md-line-height: $h2-line-height;
$h2-md-font-weight: $h2-font-weight;
$h2-md-letter-spacing: $h2-letter-spacing;
$h2-md-text-transform: $h2-text-transform;
$h2-md-font-style: $h2-font-style;

$h3-md-font-size: $h3-font-size;
$h3-md-line-height: $h3-line-height;
$h3-md-font-weight: $h3-font-weight;
$h3-md-letter-spacing: $h3-letter-spacing;
$h3-md-text-transform: $h3-text-transform;
$h3-md-font-style: $h3-font-style;

$h4-md-font-size: $h4-font-size;
$h4-md-line-height: $h4-line-height;
$h4-md-font-weight: $h4-font-weight;
$h4-md-letter-spacing: $h4-letter-spacing;
$h4-md-text-transform: $h4-text-transform;
$h4-md-font-style: $h4-font-style;

$h5-md-font-size: $h5-font-size;
$h5-md-line-height: $h5-line-height;
$h5-md-font-weight: $h5-font-weight;
$h5-md-letter-spacing: $h5-letter-spacing;
$h5-md-text-transform: $h5-text-transform;
$h5-md-font-style: $h5-font-style;

$h6-md-font-size: $h6-font-size;
$h6-md-line-height: $h6-line-height;
$h6-md-font-weight: $h6-font-weight;
$h6-md-letter-spacing: $h6-letter-spacing;
$h6-md-text-transform: $h6-text-transform;
$h6-md-font-style: $h6-font-style;

// Large desktop header values
$h1-lg-font-size: $h1-font-size;
$h1-lg-line-height: $h1-line-height;
$h1-lg-font-weight: $h1-font-weight;
$h1-lg-letter-spacing: $h1-letter-spacing;
$h1-lg-text-transform: $h1-text-transform;
$h1-lg-font-style: $h1-font-style;

$h2-lg-font-size: $h2-font-size;
$h2-lg-line-height: $h2-line-height;
$h2-lg-font-weight: $h2-font-weight;
$h2-lg-letter-spacing: $h2-letter-spacing;
$h2-lg-text-transform: $h2-text-transform;
$h2-lg-font-style: $h2-font-style;

$h3-lg-font-size: $h3-font-size;
$h3-lg-line-height: $h3-line-height;
$h3-lg-font-weight: $h3-font-weight;
$h3-lg-letter-spacing: $h3-letter-spacing;
$h3-lg-text-transform: $h3-text-transform;
$h3-lg-font-style: $h3-font-style;

$h4-lg-font-size: $h4-font-size;
$h4-lg-line-height: $h4-line-height;
$h4-lg-font-weight: $h4-font-weight !default;
$h4-lg-letter-spacing: $h4-letter-spacing !default;
$h4-lg-text-transform: $h4-text-transform !default;
$h4-lg-font-style: $h4-font-style !default;

$fonts: (
    primary: $primary-font,
    secondary: $secondary-font,
    tertiary: $tertiary-font,
    header: $header-font,
    body: $body-font
) !default;

/*
* TEXT
*/
// Stand Alone Text Defaults
$default-text-font: $primary-font;
$default-text-font-size: 15px;
$default-text-line-height: 24.75px;
$default-text-font-weight: 200;
$default-text-letter-spacing: normal;

// Paragraphs
$paragraph-font-size: $default-text-font-size; // paragraph size on mobile
$paragraph-line-height: $default-text-line-height;
$paragraph-lg-font-size: $default-text-font-size; // paragraph size on desktop
$paragraph-lg-line-height: $default-text-line-height;
$paragraph-font-size-large: $default-text-font-size; // large paragraph size on mobile
$paragraph-line-height-large: $default-text-line-height;
$paragraph-lg-font-size-large: $default-text-font-size;  // large paragraph size on desktop
$paragraph-lg-line-height-large: $default-text-line-height;
$paragraph-font-size-small: $default-text-font-size; // small paragraph size on mobile
$paragraph-line-height-small: $default-text-line-height;
$paragraph-lg-font-size-small: $default-text-font-size; // small paragraph size on desktop
$paragraph-lg-line-height-small: $default-text-line-height;
$paragraph-letter-spacing: $default-text-letter-spacing;

// Links
$link-color: $white !default;
$link-color-hover: $white !default;
$link-text-decoration-hover: underline !default;
$link-light-theme-color: $black !default;
$link-light-theme-color-hover: $black !default;
$link-color-disabled: $lighter-gray !default;
$link-font-weight: $default-text-font-weight;
$link-text-decoration: underline;
$link-font-size: $default-text-font-size;
$link-line-height: $default-text-line-height !default;
$link-secondary-font-weight: $default-text-font-weight;
$link-secondary-decoration: underline;
$link-secondary-font-size: $default-text-font-size !default;
$link-secondary-line-height: $default-text-line-height !default;
$link-text-opacity: 0.6;

/*
* HEADERS
*/
// Mobile header values
$h1-font-family: $header-font;
$h1-font-size: 22px;
$h1-line-height: 36.3px;
$h1-font-weight: 700;
$h1-letter-spacing: 4.4px;
$h1-text-transform: uppercase;
$h1-font-style: normal;

$h2-font-family: $header-font;
$h2-font-size: 20px;
$h2-line-height: 33px;
$h2-font-weight: 700;
$h2-letter-spacing: 4px;
$h2-text-transform: uppercase;
$h2-font-style: normal;

$h3-font-family: $header-font;
$h3-font-size: 15px;
$h3-line-height: 24.75px;
$h3-font-weight: 700;
$h3-letter-spacing: normal;
$h3-text-transform: uppercase;
$h3-font-style: normal;

$h4-font-family: $header-font;
$h4-font-size: 13px;
$h4-line-height: 21.45px;
$h4-font-weight: 700;
$h4-letter-spacing: 2.6px;
$h4-text-transform: uppercase;
$h4-font-style: normal;

$h5-font-family: $header-font;
$h5-font-size: 10px;
$h5-line-height: 21.45px;
$h5-font-weight: 700;
$h5-letter-spacing: 2.6px;
$h5-text-transform: uppercase;
$h5-font-style: normal;

$h6-font-family: $default-text-font;
$h6-font-size: $default-text-font-size;
$h6-line-height: $default-text-line-height;
$h6-font-weight: $default-text-font-weight;
$h6-letter-spacing: $default-text-letter-spacing;
$h6-text-transform: none;
$h6-font-style: normal;

// Medium desktop header values
$h1-md-font-size: $h1-font-size;
$h1-md-line-height: $h1-line-height;
$h1-md-font-weight: $h1-font-weight;
$h1-md-letter-spacing: $h1-letter-spacing;
$h1-md-text-transform: $h1-text-transform;
$h1-md-font-style: $h1-font-style;

$h2-md-font-size: $h2-font-size;
$h2-md-line-height: $h2-line-height;
$h2-md-font-weight: $h2-font-weight;
$h2-md-letter-spacing: $h2-letter-spacing;
$h2-md-text-transform: $h2-text-transform;
$h2-md-font-style: $h2-font-style;

$h3-md-font-size: $h3-font-size;
$h3-md-line-height: $h3-line-height;
$h3-md-font-weight: $h3-font-weight;
$h3-md-letter-spacing: $h3-letter-spacing;
$h3-md-text-transform: $h3-text-transform;
$h3-md-font-style: $h3-font-style;

$h4-md-font-size: $h4-font-size;
$h4-md-line-height: $h4-line-height;
$h4-md-font-weight: $h4-font-weight;
$h4-md-letter-spacing: $h4-letter-spacing;
$h4-md-text-transform: $h4-text-transform;
$h4-md-font-style: $h4-font-style;

$h5-md-font-size: $h5-font-size;
$h5-md-line-height: $h5-line-height;
$h5-md-font-weight: $h5-font-weight;
$h5-md-letter-spacing: $h5-letter-spacing;
$h5-md-text-transform: $h5-text-transform;
$h5-md-font-style: $h5-font-style;

$h6-md-font-size: $h6-font-size;
$h6-md-line-height: $h6-line-height;
$h6-md-font-weight: $h6-font-weight;
$h6-md-letter-spacing: $h6-letter-spacing;
$h6-md-text-transform: $h6-text-transform;
$h6-md-font-style: $h6-font-style;

// Large desktop header values
$h1-lg-font-size: $h1-font-size;
$h1-lg-line-height: $h1-line-height;
$h1-lg-font-weight: $h1-font-weight;
$h1-lg-letter-spacing: $h1-letter-spacing;
$h1-lg-text-transform: $h1-text-transform;
$h1-lg-font-style: $h1-font-style;

$h2-lg-font-size: $h2-font-size;
$h2-lg-line-height: $h2-line-height;
$h2-lg-font-weight: $h2-font-weight;
$h2-lg-letter-spacing: $h2-letter-spacing;
$h2-lg-text-transform: $h2-text-transform;
$h2-lg-font-style: $h2-font-style;

$h3-lg-font-size: $h3-font-size;
$h3-lg-line-height: $h3-line-height;
$h3-lg-font-weight: $h3-font-weight;
$h3-lg-letter-spacing: $h3-letter-spacing;
$h3-lg-text-transform: $h3-text-transform;
$h3-lg-font-style: $h3-font-style;

$h4-lg-font-size: $h4-font-size;
$h4-lg-line-height: $h4-line-height;
$h4-lg-font-weight: $h4-font-weight !default;
$h4-lg-letter-spacing: $h4-letter-spacing !default;
$h4-lg-text-transform: $h4-text-transform !default;
$h4-lg-font-style: $h4-font-style !default;

$h5-lg-font-size: $h5-font-size;
$h5-lg-line-height: $h5-line-height;
$h5-lg-font-weight: $h5-font-weight;
$h5-lg-letter-spacing: $h5-letter-spacing;
$h5-lg-text-transform: $h5-text-transform;
$h5-lg-font-style: $h5-font-style;

$h6-lg-font-size: $h6-font-size;
$h6-lg-line-height: $h6-line-height;
$h6-lg-font-weight: $h6-font-weight;
$h6-lg-letter-spacing: $h6-letter-spacing;
$h6-lg-text-transform: $h6-text-transform;
$h6-lg-font-style: $h6-font-style;

$headers: (
    h1 $h1-font-family $h1-font-size $h1-line-height $h1-font-weight $h1-letter-spacing $h1-text-transform $h1-font-style $h1-md-font-size $h1-md-line-height
        $h1-md-font-weight $h1-md-letter-spacing $h1-md-text-transform $h1-md-font-style $h1-lg-font-size $h1-lg-line-height
        $h1-lg-font-weight $h1-lg-letter-spacing $h1-lg-text-transform $h1-lg-font-style,
    h2 $h2-font-family $h2-font-size $h2-line-height $h2-font-weight $h2-letter-spacing $h2-text-transform $h2-font-style $h2-md-font-size $h2-md-line-height
        $h2-md-font-weight $h2-md-letter-spacing $h2-md-text-transform $h2-md-font-style $h2-lg-font-size $h2-lg-line-height
        $h2-lg-font-weight $h2-lg-letter-spacing $h2-lg-text-transform $h2-lg-font-style,
    h3 $h3-font-family $h3-font-size $h3-line-height $h3-font-weight $h3-letter-spacing $h3-text-transform $h3-font-style $h3-md-font-size $h3-md-line-height
        $h3-md-font-weight $h3-md-letter-spacing $h3-md-text-transform $h3-md-font-style $h3-lg-font-size $h3-lg-line-height
        $h3-lg-font-weight $h3-lg-letter-spacing $h3-lg-text-transform $h3-lg-font-style,
    h4 $h4-font-family $h4-font-size $h4-line-height $h4-font-weight $h4-letter-spacing $h4-text-transform $h4-font-style $h4-md-font-size $h4-md-line-height
        $h4-md-font-weight $h4-md-letter-spacing $h4-md-text-transform $h4-md-font-style $h4-lg-font-size $h4-lg-line-height
        $h4-lg-font-weight $h4-lg-letter-spacing $h4-lg-text-transform $h4-lg-font-style,
    h5 $h5-font-family $h5-font-size $h5-line-height $h5-font-weight $h5-letter-spacing $h5-text-transform $h5-font-style $h5-md-font-size $h5-md-line-height
        $h5-md-font-weight $h5-md-letter-spacing $h5-md-text-transform $h5-md-font-style $h5-lg-font-size $h5-lg-line-height
        $h5-lg-font-weight $h5-lg-letter-spacing $h5-lg-text-transform $h5-lg-font-style,
    h6 $h6-font-family $h6-font-size $h6-line-height $h6-font-weight $h6-letter-spacing $h6-text-transform $h6-font-style $h6-md-font-size $h6-md-line-height
        $h6-md-font-weight $h6-md-letter-spacing $h6-md-text-transform $h6-md-font-style $h6-lg-font-size $h6-lg-line-height
        $h6-lg-font-weight $h6-lg-letter-spacing $h6-lg-text-transform $h6-lg-font-style
);

/*
* BUTTONS
*/
$button-font: $primary-font;
$button-font-size: 15px;
$button-tertiary-font-size: $button-font-size;
$button-line-height: 24.75px;
$button-font-weight: 200;
$button-padding-top: 14px;
$button-padding-bottom: 14px;
$button-padding-left: 28px;
$button-padding-right: 28px;
$button-letter-spacing: normal;
$button-text-transform: uppercase;
$button-border-radius: $border-radius !default;
$button-border-width: 1px;
$button-icon-size: 12px !default;
$button-text-align: center !default;
$button-opacity: 1;

// Primary button
$button-primary-font: $button-font;
$button-primary-font-weight: $button-font-weight;
$button-primary-color: $brand-primary;
$button-primary-color-hover: $white;
$button-primary-background: $white;
$button-primary-background-hover: $brand-primary;
$button-primary-border-color-hover: $white;
$button-primary-border-color: $brand-primary;
$button-primary-dark-theme-color: $white;
$button-primary-dark-theme-color-hover: $brand-primary;
$button-primary-dark-theme-background-hover: $white;
$button-primary-border-radius: $button-border-radius;
$button-primary-border-width: $button-border-width;
$button-primary-border-color-disabled: $neutral-light;
$button-primary-background-color-disabled: $neutral-light;
$button-primary-color-disabled: $dark-gray;
$button-primary-text-transform: uppercase;
$button-primary-padding-top: $button-padding-top;
$button-primary-padding-right: $button-padding-right;
$button-primary-padding-bottom: $button-padding-bottom;
$button-primary-padding-left: $button-padding-left;

// Secondary button
$button-secondary-font: $button-font;
$button-secondary-font-weight: $button-font-weight;
$button-secondary-color: $white;
$button-secondary-color-hover: $brand-primary;
$button-secondary-light-theme-color: $brand-primary;
$button-secondary-light-theme-color-hover: $white;
$button-secondary-dark-theme-color: $white;
$button-secondary-dark-theme-color-hover: $brand-primary;
$button-secondary-background: transparent;
$button-secondary-background-hover: $white;
$button-secondary-dark-theme-background-hover: $button-secondary-dark-theme-color;
$button-secondary-color-disabled: $dark-gray;
$button-secondary-background-color-disabled: $brand-primary;
$button-secondary-border-color: $white;
$button-secondary-border-color-disabled: $dark-gray;
$button-secondary-border-color-hover: $brand-primary;
$button-secondary-text-decoration: none;
$button-secondary-text-decoration-hover: none;
$button-secondary-border-radius: $button-border-radius;
$button-secondary-border-width: $button-border-width;
$button-secondary-border-color-disabled: $brand-neutral;
$button-secondary-text-transform: capitalize;
$button-secondary-padding-top: 5px;
$button-secondary-padding-right: 20px;
$button-secondary-padding-bottom: 5px;
$button-secondary-padding-left: 20px;

// Accent Red Button
$button-accent-red-font: $button-font;
$button-accent-red-font-weight: $button-font-weight;
$button-accent-red-color: $white;
$button-accent-red-color-hover: $white;
$button-accent-red-background: $accent-red;
$button-accent-red-background-hover: $accent-red;
$button-accent-red-border-color-hover: $accent-red;
$button-accent-red-border-color: $accent-red;
$button-accent-red-dark-theme-color: $white;
$button-accent-red-dark-theme-color-hover: $white;
$button-accent-red-border-radius: $button-border-radius;
$button-accent-red-border-width: $button-border-width;
$button-accent-red-border-color-disabled: $neutral-light;
$button-accent-red-background-color-disabled: $neutral-light;
$button-accent-red-color-disabled: $dark-gray;
$button-accent-red-text-transform: uppercase;
$button-accent-red-padding-top: $button-padding-top;
$button-accent-red-padding-right: $button-padding-right;
$button-accent-red-padding-bottom: $button-padding-bottom;
$button-accent-red-padding-left: $button-padding-left;
$button-accent-red-text-decoration: none;
$button-accent-red-text-align: $button-text-align;
$button-accent-red-text-decoration-hover: none;

// Solid Black Button
$button-solid-black-font: $button-font;
$button-solid-black-font-weight: $button-font-weight;
$button-solid-black-color: $white;
$button-solid-black-color-hover: $brand-primary;
$button-solid-black-background: $brand-primary;
$button-solid-black-background-hover: $white;
$button-solid-black-border-color-hover: $brand-primary;
$button-solid-black-border-color: $brand-primary;
$button-solid-black-dark-theme-color: $white;
$button-solid-black-dark-theme-color-hover: $brand-primary;
$button-solid-black-border-radius: $button-border-radius;
$button-solid-black-border-width: $button-border-width;
$button-solid-black-border-color-disabled: $neutral-light;
$button-solid-black-background-color-disabled: $neutral-light;
$button-solid-black-color-disabled: $dark-gray;
$button-solid-black-text-transform: uppercase;
$button-solid-black-padding-top: $button-padding-top;
$button-solid-black-padding-right: $button-padding-right;
$button-solid-black-padding-bottom: $button-padding-bottom;
$button-solid-black-padding-left: $button-padding-left;
$button-solid-black-text-decoration: none;
$button-solid-black-text-align: $button-text-align;
$button-solid-black-text-decoration-hover: none;

// Link Button
$button-link-font: $button-font;
$button-link-font-weight: $button-font-weight;
$button-link-color: $brand-primary;
$button-link-color-hover: $brand-primary;
$button-link-background: transparent;
$button-link-background-hover: transparent;
$button-link-border-color-hover: transparent;
$button-link-border-color: transparent;
$button-link-dark-theme-color: $white;
$button-link-dark-theme-color-hover: $white;
$button-link-border-radius: unset;
$button-link-border-width: unset;
$button-link-border-color-disabled: $neutral-light;
$button-link-background-color-disabled: $neutral-light;
$button-link-color-disabled: $dark-gray;
$button-link-text-transform: unset;
$button-link-padding-top: $button-padding-top;
$button-link-padding-right: $button-padding-right;
$button-link-padding-bottom: $button-padding-bottom;
$button-link-padding-left: $button-padding-left;
$button-link-text-decoration: underline;
$button-link-text-align: left;
$button-link-text-decoration-hover: underline;

// Small Button
$button-sm-font-size: 14px !default;
$button-sm-line-height: 18px !default;
$button-sm-padding-top: $spacer-sm !default;
$button-sm-padding-bottom: $spacer-sm !default;
$button-sm-padding-left: 20px !default;
$button-sm-padding-right: 20px !default;
$button-sm-letter-spacing: 0.05em !default;
$button-sm-icon-size: 22px !default;
$button-sm-primary-padding-top: $button-sm-padding-top !default;
$button-sm-primary-padding-bottom: $button-sm-padding-bottom !default;
$button-sm-primary-padding-left: $button-sm-padding-left !default;
$button-sm-primary-padding-right: $button-sm-padding-right !default;
$button-sm-secondary-padding-top: $button-sm-padding-top !default;
$button-sm-secondary-padding-bottom: $button-sm-padding-bottom !default;
$button-sm-secondary-padding-left: $button-sm-padding-left !default;
$button-sm-secondary-padding-right: $button-sm-padding-right !default;
$button-sm-accent-red-padding-top: $button-sm-padding-top !default;
$button-sm-accent-red-padding-bottom: $button-sm-padding-bottom !default;
$button-sm-accent-red-padding-left: 0 !default;
$button-sm-accent-red-padding-right: 0 !default;
$button-sm-solid-black-padding-top: $button-sm-padding-top !default;
$button-sm-solid-black-padding-bottom: $button-sm-padding-bottom !default;
$button-sm-solid-black-padding-left: 0 !default;
$button-sm-solid-black-padding-right: 0 !default;
$button-sm-link-padding-top: $button-sm-padding-top !default;
$button-sm-link-padding-bottom: $button-sm-padding-bottom !default;
$button-sm-link-padding-left: 0 !default;
$button-sm-link-padding-right: 0 !default;

/*
* FORMs
*/
// Inputs
$input-font: $primary-font;
$input-text-color: $dark-gray;
$input-border-width: 1px;
$input-border-color: $brand-neutral;
$input-border-color-focus: $black;
$input-border-color-hover: $black;

// Select dropdowns
$select-line-height: 22px;
$select-color: $brand-primary;
$select-border-color: $white;
$select-background: $white;
$select-padding-top: 13px !default;
$select-padding-bottom: 13px !default;
$select-padding-left: 15px !default;
$select-padding-right-sm: 30px !default;
$select-padding-right: 45px !default;
$select-icon-size: 12px !default;
$select-width: 227px;
// $select-padding-right: $input-padding-right + $select-icon-size + $spacer-sm !default;
// Small dropdown
$select-sm-icon-size: 10px !default;
// $select-sm-padding-right: $input-sm-padding-right + $select-sm-icon-size + $spacer-sm !default;

// Quantity stepper
$quantity-stepper-icon-size: 10px !default;
$quantity-stepper-icon-size-small: 8px !default;

// Inline Button + Input group
$inline-input-button-background-color: $white;


/** DROPDOWNS */
$dropdown-item-padding: 8px;
$dropdown-item-hover-color: $link-color-hover;

/*
* NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
*/
$nav-link-letter-spacing: 2px !default;

/*
* BREADCRUMBS
*/
$breadcrumb-font-size: 13px;
$breadcrumb-line-height: 30px;
$breadcrumb-font-weight: 200;
$breadcrumb-divider-color: currentColor;
$breadcrumb-item-color: inherit;
$breadcrumb-item-color-hover: inherit;

/*
* TOOLTIPS
*/
$tooltip-text-color: $brand-primary;

/*
* ALERTS (also affects toast messages)
*/
$alert-font-family: $primary-font;
$alert-font-weight: 700;

/*
* NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
*/
$nav-link-font-family: $primary-font;
$nav-link-font-size: 15px;
$nav-link-font-weight: 200;
$nav-link-line-height: 24.75px;
$nav-link-letter-spacing: normal;
$nav-link-text-transform: uppercase;
$nav-link-color: $brand-primary !default;
$nav-link-text-decoration: none !default;
$nav-link-text-decoration-hover: none !default;
$nav-sub-link-font-size: 14px !default;
$nav-link-secondary-font-family: $primary-font;
$nav-link-secondary-font-size: 15px !default;
$nav-link-secondary-font-weight: 200;
$nav-link-secondary-line-height: 24.75px !default;
$nav-link-tertiary-font-family: $primary-font !default;
$nav-link-tertiary-font-size: 15px !default;
$nav-link-tertiary-font-weight: 200;
$nav-link-tertiary-line-height: 24.75px !default;


// Header
//promo bar
$header-promo-bar-background: transparent;
$header-height-lg: 80px;
$header-navbar-color: $brand-black;
$header-navbar-background-hover: transparent;
$header-navbar-color-hover: $brand-black;
$header-promo-bar-color: $brand-white;
$header-background: transparent;
$header-link-color: $nav-link-color !default;
// $header-link-color-hover: $white;
$header-link-color-hover: inherit;
// $header-search-desktop-border-width: 0;
$header-search-desktop-border-width: 1px;
$header-link-dropdown-menu-background: $brand-black;
$header-link-dropdown-menu-color: $white;
$header-search-icon-color: $header-link-color;
$header-search-icon-color-hover: $brand-secondary;

// Menu
$megamenu-background: $brand-black;
// Megamenu
//Megamenu Left nav — Styles for mega-left megamenu only
$megamenu-left-nav-font-color: $brand-primary;
$megamenu-left-nav-font-size: 17px;
$megamenu-left-nav-border-color-hover: $white;
$megamenu-secondary-color: $black;
$megamenu-secondary-color-hover: $brand-primary;
$megamenu-left-nav-tertiary-title-font-size: 20px;
$megamenu-left-nav-tertiary-title-font-family: $tertiary-font;
$megamenu-left-nav-tertiary-title-line-height: 24px;
$megamenu-secondary-color: $neutral-light;
$megamenu-secondary-font: $primary-font;
$megamenu-secondary-font-weight: 200;

// Links
$link-color: $white !default;
$link-color-hover: $brand-primary;
$link-text-decoration: underline !default;
$link-text-decoration-hover: underline;
// $link-color: $brand-primary !default;
// $link-color-hover: $brand-primary !default;
// $link-text-decoration-hover: underline !default;
// $link-dark-theme-color: $white !default;
// $link-dark-theme-color-hover: $white !default;
// $link-color-disabled: $lighter-gray !default;
// $link-font-weight: 500 !default;
// $link-text-decoration: none !default;
// $link-font-size: 13px !default;
// $link-line-height: 24px !default;
// $link-secondary-font-weight: normal !default;
// $link-secondary-decoration: underline !default;
// $link-secondary-font-size: 12px !default;
// $link-secondary-line-height: 27px !default;


// Megamenu
//Megamenu Left nav — Styles for mega-left megamenu only
$megamenu-left-nav-font-color: $brand-primary;
$megamenu-left-nav-font-size: 17px;
$megamenu-left-nav-border-color-hover: $white;
$megamenu-secondary-color: $black;
$megamenu-secondary-color-hover: $brand-primary;
$megamenu-left-nav-tertiary-title-font-size: 20px;
$megamenu-left-nav-tertiary-title-font-family: $tertiary-font;
$megamenu-left-nav-tertiary-title-line-height: 24px;
$megamenu-secondary-color: $neutral-light;
$megamenu-secondary-font: $primary-font;
$megamenu-secondary-font-weight: 200;


// Footer
$footer-background: #010101;
$footer-border-top-width: 0;
$footer-link-color: $white;
$footer-link-color-hover: $white;
$footer-title-font-color: $white;
$footer-title-font-family: $primary-font;
$footer-title-font-size: 13px;
$footer-title-line-height: 21.45px;
$footer-title-font-weight: 700;
$footer-icon-size: 35px;
$footer-padding-top: 50px;

// PRODUCT TILES (product grid, recommended sliders)
$product-tile-title-font-size: 25px;
$product-tile-title-font-size-mobile: calc($base-font-size) !default;
$product-tile-title-font-size-desktop: $product-tile-title-font-size !default;
$product-tile-title-line-height: 30px;
$product-tile-title-color: $black;
$product-tile-wishlist-link-icon: $icon-favorite;
$product-tile-wishlist-link-icon-selected: $icon-favorite-selected;
$product-tile-outer-spacer:  28px;
$product-tile-title-font-weight: 700;
$product-tile-price-color: $brand-black;
$product-tile-wishlist-link-color: $brand-black;
$product-tile-wishlist-selected-link-color: $brand-black;
$product-tile-badge-color: $brand-black;
$product-option-background-color: $lighter-gray;
$product-option-modal-header-background-color: $lighter-gray;

/*
* HERO ASSETS
*/
$hero-subtitle-font: $primary-font;
$hero-subtitle-font-size: 21px;

/*
* SLIDER
*/
$slider-thumbnail-width-lg: 97px;
$slider-dark-button-background-hover: $pure-black;

/*
* PRODUCT
*/
// PDP
$pdp-name-header-value: 1;
$pdp-promo-color: map-get($colors, accent-red);

// PDP STICKY ADD TO CART
$pdp-sticky-addtocart-background: $brand-off-white;


$header-logo-width: 140px;
$header-logo-width-md: $header-logo-width;
$header-logo-width-lg: 160px;
// Main header
$header-height: 80px;
$header-height-md: $header-height;
$header-height-lg: $header-height;

// transparent header styles
$header-transparent-text-color-light: $white;
$header-transparent-text-color-dark: $black;
$header-transparent-minicart-count-background-color-light: $black;
$header-transparent-minicart-count-background-color-dark: $black;
$header-minicart-count-text-color-light: $white;
$header-minicart-count-background-color-light: $black;

/*
* CONTENT TILES
*/
$content-tile-category-font: $primary-font;
$content-tile-category-font-size: 18px;
$content-tile-category-font-weight: 300;
$content-tile-category-color: $brand-black;
$content-tile-name-font: $primary-font;
$content-tile-name-font-size: 20px;
$content-tile-name-font-weight: 200;
$content-tile-name-color: $brand-black;
$content-tile-name-margin-bottom: 7px;
$content-tile-date-font: $primary-font;
$content-tile-date-font-size: 20px;
$content-tile-date-font-weight: 300;
$content-tile-date-text-transform: normal;
$content-tile-date-color: $brand-black;
$content-tile-date-margin-bottom: 16px;
$content-tile-description-font: $primary-font;
$content-tile-description-font-size: 15px;
$content-tile-description-font-weight: 200;
$content-tile-description-color: $brand-black;


/*
* CARDS
*/
// $card-border-radius: $border-radius !default;
$card-border-color: currentColor;
$card-header-background: $lighter-gray;
$card-header-border-color: currentColor;
$card-header-color: inherit;
$card-header-padding-top: 38px !default;
$card-header-padding-right: 32px !default;
$card-header-padding-bottom: 15px !default;
$card-header-padding-left: 32px !default;
$card-header-font-family: $primary-font;
$card-header-font-size: 28px !default;
$card-header-line-height: 32px !default;
$card-header-letter-spacing: .01em !default;
$card-header-text-transform: none !default;
$card-header-font-weight: normal !default;
// $card-body-font-size: $paragraph-lg-font-size !default;
// $card-body-line-height: $paragraph-lg-line-height !default;
// $card-body-letter-spacing: $default-text-letter-spacing !default;
$card-body-background: $lighter-gray;
$card-body-border-color: currentColor;
$card-body-color: inherit;
$card-body-padding-bottom: 15px !default;
$card-body-padding-left: 32px !default;
$card-body-padding-right: 32px !default;
$card-body-padding-top: 0 !default;
$card-no-header-body-padding-top: 20px !default;
$card-footer-background: $lighter-gray;
$card-footer-color: inherit;
$card-footer-border-color: currentColor;
// $card-footer-padding: 15px $card-header-padding-right $card-header-padding-bottom $card-header-padding-left !default;
$card-ghost-opacity: 0.5 !default;
// $card-form-value-color: $dark-gray !default;
$card-header-border-bottom-width: 0 !default;
// $card-margin-bottom: $spacer !default;
$card-wishlist-remove-btn-margin-top: 5px !default;
$card-wishlist-remove-btn-margin-right: 0px !default;
$card-wishlist-remove-btn-margin-bottom: 0px !default;
$card-wishlist-remove-btn-margin-left: 20px !default;
$card-wishlist-border-bottom: 1px solid currentColor !default;
$card-wishlist-margin-bottom: 20px !default;
$card-wishlist-padding-bottom: 20px !default;

// 'aside' card variation - used on PLP refinements
$card-aside-header-padding-top: 20px !default;
// $card-aside-header-padding-right: $card-header-padding-right !default;
$card-aside-header-padding-bottom: 20px !default;
$card-aside-header-padding-left: 0 !default;
$card-aside-header-font-size: 13px !default;
$card-aside-header-font-weight: bold !default;
$card-aside-header-line-height: 18px !default;
$card-aside-body-padding: 0 !default;
$card-aside-border-width: 1px 0 !default;
$card-aside-border-color: currentColor;
$card-aside-body-border: none !default;
$card-aside-footer-border: none !default;
/*
* COLLAPSIBLE
*/
$collapsible-border-color: currentColor;
$collapsible-header-background: transparent;
$collapsible-header-color: inherit;
$collapsible-header-icon-color: inherit;
$collapsible-header-icon-size: 28px !default;
$collapsible-body-background: transparent;
$collapsible-body-color: inherit;
// $collapsible-header-icon-expand: $icon-plus !default;
// $collapsible-header-icon-close: $icon-minus !default;
$collapsible-font-size: 18px !default;
$collapsible-padding-top: 10px !default;
$collapsible-padding-bottom: 10px !default;
$collapsible-padding-left: 0 !default;



// PDP
$pdp-name-header-value: 2 !default;
$pdp-name-header-color: $black !default;
$pdp-price-font-size: 24px !default;
$pdp-price-font-size-sm: 18px !default;
$pdp-price-color: inherit;
$pdp-price-font: $body-font !default;
$pdp-product-number-font-size: 13px !default;
$pdp-product-number-color: inherit;
$pdp-rating-star-size: 14px !default;
$pdp-rating-star-size-large: 20px !default;
// $pdp-rating-star-size-large-md: $pdp-rating-star-size-large !default;
$pdp-reviews-link-display: none !default;
$pdp-reviews-link-font-size: 13px !default;
$pdp-reviews-link-color: inherit;
$pdp-reviews-link-color-hover: inherit;
// $pdp-right-column-spacing-md: $spacer !default; // desktop only (additional space between images and data
$pdp-right-column-spacing-lg: 28px !default; // ""
$pdp-right-column-spacing-xl: 28px !default; // ""
$pdp-attribute-vertical-spacing: 24px !default;
$pdp-attribute-vertical-spacing-md: 24px !default;
$pdp-attribute-vertical-spacing-lg: 44px !default;
$pdp-color-swatch-size: 18px !default;
$pdp-color-swatch-spacing: 20px !default;
$pdp-color-swatch-thumbnail-size: 44px !default;
$pdp-color-swatch-thumbnail-spacing: 1px !default;
$pdp-non-color-swatch-min-width: 44px !default;
$pdp-non-color-swatch-spacing: 8px !default;
// $pdp-add-to-cart-button-icon: $icon-content-blank !default;
$pdp-promo-color: inherit;
$pdp-social-icon-color: inherit;
$pdp-social-icon-color-hover: inherit;
$pdp-social-icon-size: 30px !default;
$pdp-social-icon-spacing: 30px !default;
$pdp-description-detail-header-value: 6 !default;
$pdp-description-detail-divider: 1px solid currentColor; // only applies when these are collapsible
// $pdp-description-detail-vertical-spacing: $spacer !default;
$pdp-description-detail-vertical-spacing-md: 30px !default;
$pdp-description-detail-vertical-spacing-lg: 60px !default;
// $pdp-tabs-vertical-spacing: $spacer !default;
$pdp-tabs-vertical-spacing-md: 30px !default;
$pdp-tabs-vertical-spacing-lg: 60px !default;
$pdp-asset-max-width: 700px !default;
$pdp-tabs-header-value: 6 !default;

// // PDP STICKY ADD TO CART
// $pdp-sticky-addtocart-background: inherit;
// $pdp-sticky-addtocart-opacity: 1 !default;
// $pdp-sticky-addtocart-open-transition: transform $transition-speed linear !default;
// $pdp-sticky-addtocart-shadow-mobile: 0 -2px 2px rgba($black, 0.2) !default;
// $pdp-sticky-addtocart-shadow: $dropdown-menu-shadow !default;
// $pdp-sticky-addtocart-name-header-value: 4 !default;
// $pdp-sticky-addtocart-color-swatch-size: $pdp-color-swatch-size !default;
// $pdp-sticky-addtocart-color-swatch-spacing: 10px !default;
// $pdp-sticky-addtocart-color-swatch-thumbnail-size: $input-sm-height !default;
// $pdp-sticky-addtocart-color-swatch-thumbnail-spacing: $pdp-color-swatch-thumbnail-spacing !default;
// $pdp-sticky-addtocart-attribute-spacing: $spacer !default;
// $pdp-sticky-addtocart-attribute-select-font-size: $input-sm-font-size !default;
// $pdp-sticky-addtocart-attribute-select-line-height: $input-sm-font-line-height !default;
// $pdp-sticky-addtocart-attribute-select-padding: $input-sm-padding-top $select-sm-padding-right $input-sm-padding-bottom $input-sm-padding-left !default;
// $pdp-sticky-addtocart-attribute-select-height: $input-sm-height !default;
// $pdp-sticky-addtocart-button-font-size: $button-sm-font-size !default;
// $pdp-sticky-addtocart-button-line-height: $button-sm-line-height !default;
// $pdp-sticky-addtocart-button-padding: $button-sm-padding-top $button-sm-padding-right $button-sm-padding-bottom $button-sm-padding-left !default;
// $pdp-sticky-addtocart-button-letter-spacing: $button-sm-letter-spacing !default;


// Main header
$header-link-color: inherit !default;

// Search field border radius
$search-field-border-radius: 40px;

// Flexible Grid Padding
$flex-grid-padding: 12px;
$flex-grid-padding-xl: 15.5px;

$checkout-font-family: $primary-font;

// Burger Menu
$burger-menu-background-color: $white;