
.navbar-nav-secondary,
.navbar-nav-secondary.navbar-nav {
    .nav-link,
    .dropdown-link {
        @include burger-link;
        display: flex;
        align-items: center;
        height: 100%;
    }
}

.navbar-nav-secondary {
    .nav-item {
        .nav-link {
            white-space: nowrap;
        }
    }
}

// hide duplicate menu items from main nav
.main-menu {
    .float-menu-item-right {
        display: none;
    }
}
