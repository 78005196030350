.pdp {
    &.page-designer {
        [class*='experience-commerce_layouts'] + [class*='experience-commerce_layouts'] {
            > div {
                margin-top: 0;

                .card {
                    margin-bottom: 0;
                }
            }
        }
        
        .experience-commerce_layouts-productReviews,
        .experience-commerce_layouts-productDetailsDetails,
        .experience-commerce_layouts-productRecommendations,
        .experience-commerce_layouts-productLinkedTo,
        .experience-commerce_layouts-productLinkedFrom {
            .collapsible-content-group {
                &.card {
                    margin-bottom: 0;
                }
            }

            .container {
                &.collapsible-content-container {
                    padding-left: 0;
                    padding-right: 0;

                    .card {
                        .card-header,
                        .card-body {
                            padding-left: 20px;
                            padding-right: 20px;
                            @include media-breakpoint-up(lg) {
                                padding-left: 24px;
                                padding-right: 24px;
                            }
                        }
                    }
                    
                }
            }
        }

    }
}

/** Product reviews should be black on white no matter the theme **/
.experience-commerce_layouts-productReviews,
.dark-theme.pdp .experience-commerce_layouts-productReviews,
.collapsible-content-container-reviews,
.dark-theme.pdp .collapsible-content-container-reviews {
    background-color: $white;
    color: $black;

    .card-header,
    .card-body {
        background-color: inherit;
        color: inherit;
    }
}
