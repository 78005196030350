@import "~core/cart/cart";

.cart-page {
    .coupon-price-adjustment { 
        .remove-coupon {
            background-color: transparent;
            padding: 0;
            margin: 0;
            top: auto;
            left: auto;
            bottom: 13px;
            right: 20px;
        }
    }

    .totals {
        .estimated-total {
            p {
                font-size: $checkout-totals-font-size;
                font-family: $primary-font;
                font-weight: $checkout-totals-font-weight;
                text-transform: $checkout-totals-text-transform;
            }
        }
    }
}