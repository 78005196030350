@import "~core/components/blog";

.blog-detail-header {
    margin-bottom: 24px;

    @include media-breakpoint-up(sm) {
        margin-bottom: 48px;
    }

    .page-header-author {
        margin-top: 0;
    }

    .page-header-title {
        margin-top: 48px;
        margin-bottom: 26px;

        @include media-breakpoint-up(sm) {
            margin-top: 80px;
            margin-bottom: 40px;
        }
    }

    .page-header-subtitle {
        font-size: 12px;

        .blog-creation-date {
            &:not(:only-child) {
                &::after {
                    content: '|';
                    padding: 0 6px;
                }
            }
        }
    }
}

.blog-detail-header {
    margin-top: 10px;
}

.blog {
    &.dark-theme {
        background-color: $pure-black;
        color: $white;

        // ensure dark-theme styles dont cascade to blog nav
        // which we dont want to influence with dark theme styles
        .blog-nav .nav {
            a {
                color: $black;
            }
        }

        .page-header-title {
            color: $accent-gold;
        }
    }
}