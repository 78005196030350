@import "~org_citizen/account/account";

.account-page, .login-page {
    a {
        letter-spacing: normal;
    }

    .form-group {
        label {
            font-family: $primary-font;
        }
    }

    .validator-container {
        color: $pure-black;
    }
}

.account-page, .rewards-page {
    .card {
        .card-header {
            h2, h3 {
                font-family: $primary-font;
            }
        }
    }
}

.account-page {
    .btn-primary {
        &:hover {
            opacity: $button-opacity;
        }
    }

    .btn-secondary, .btn-outline-primary {
        &:hover, &:focus {
            background-color: $pure-black;
        }
    }
}

.login-page {
    .card-header {
        &.track-order-header {
            font-family: $primary-font;
        }
    }

    .login-nav-tabs {
        .nav-link {
            font-family: $primary-font;
        }
    }
}

.account-page, 
.login-page, 
.order-return-page, 
.cart-page, 
.wishlist-page {
    .breadcrumb-styling {
        margin-bottom: 0;
    }
}

.product-list-enhancements-modal-show-lists-header-back,
.product-list-enhancements-modal-show-lists-header-close {
    &::after {
        color: $brand-black;
        font-weight: 700;
        font-size: 16px;
    }
}

.product-list-enhancements-card-product-name,
.product-list-enhancements-card-product-form p,
.product-list-enhancements-modal-show-lists-toggle-lists .toggle.selected div {
    color: $brand-black;
}

.product-list-enhancements-toast-message-wrapper {
    .message {
        color: $brand-white;
    }
}

.product-list-enhancements-modal-show-lists-toggle-lists .toggle.selected {
    &:hover {
        &:before,
        div {
            color: $brand-white;
        }
    }
}

.product-list-enhancements-modal-show-lists-toggle-lists .toggle img,
.product-list-enhancements-modal-show-lists-toggle-lists .toggle div {
    margin-left: 5px;
}

.account-page.receipt {
    .card.checkout-order-total-summary {
        border-bottom: none;
    }
}