
.burger-menu-toggler {
    position: absolute;
    text-decoration: none;
    width: 40px;
    height: 30px;
    top: 25px;
    left: 30px;
    z-index: 300;
    opacity: 1;
    transition: opacity 200ms ease-in;

    @include media-breakpoint-up(lg) {
        top: 35px;
    }
    
    &.burger-menu-toggler-fixed {
        position: fixed;
    }

    &.open {
        &.burger-menu-toggler-remote-button {
            opacity: 0;
        }
    }

    .burger-bar-container {
        display: block;
        width: 23px;
        height: 12px;
    }

    .burger-bar {
        position: relative;
        display: block;
        width: 23px;
        height: 2px;
        background-color: currentColor;
        top: 5px;
        transition: background-color .5s,height .5s .2s;
        transition-duration: .8s;
        box-shadow: 0 1px 1px RGB(0 0 0 / 50%);

        &:before,
        &:after {
            content: "";
            width: 23px;
            height: 2px;
            position: absolute;
            background-color: currentColor;
            display: block;
            left: 0;

            transition: background-color .5s,top .5s .5s,transform .5s;
            box-shadow: 0 1px 1px RGB(0 0 0 / 50%);
        }

        &:before {
            top: -5px;
        }
        &:after {
            top: 5px;
        }
    }

    &.open {
        .burger-bar {
            background-color: $black;
            height: 0;
            box-shadow: 0 1px 1px RGB(0 0 0 / 0%);

            &:before,
            &:after {
                background-color: $black;
                transition: background-color .5s, top .5s,transform .5s .5s;
                top: 0;
                box-shadow: 0 1px 1px RGB(0 0 0 / 0%);
            }

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }
        }
    }
}

.burger-menu-toggler-fixed {
    position: fixed;       
    z-index: 1000;
    top: 25px;
    left: 30px;

    @include media-breakpoint-up(md) {
        top: 20px;
    }

    @include media-breakpoint-up(lg) {
        top: 35px;
    }
}


body {
    #global-ticker {
        ~ .burger-menu-toggler:not(.burger-menu-toggler-fixed) {
            top: 65px;
            @include media-breakpoint-up(md) {
                top: 60px;
            }
            @include media-breakpoint-up(lg) {
                top: 65px;
            }
        }
    }
}

.burger-menu {
    .burger-menu-innner-wrap {
        position: relative;
    }

    .burger-menu-toggler {
        position: absolute;
        left: 14px;
        top: 25px;
    }
}