@import "~core/components/contentTiles";

.content-tile {
    /** hide content-tile-cta by default **/
    .content-tile-cta {
        display: none;
    }

    .content-tile-name,
    .content-tile-date,
    .content-tile-description {
        font-family: $primary-font;
    }
}

/** Blog Content in PD Dynamic Content slider **/
.experience-commerce_layouts-sliderContent {
    .content-tile {
        @include media-breakpoint-up(lg) {
            display: flex;           
        }
        /** Blog Image **/
        .content-tile-image-wrapper {
            margin-bottom: 0;

            @include media-breakpoint-up(lg) {
                width: 66.666666%;
                flex-basis: 66.666666%;
                order: 2;
            }
            // ensure image is full hieight/width of component
            .content-tile-image {
                min-width: 100%;
                min-height: 100%;
            }
        }

        /** Blog Name, Description, CTA **/
        .content-tile-body {
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 30px 24px;

            @include media-breakpoint-up(lg) {
                width: 33.333333%;
                flex-basis: 33.333333%;
                order: 1;
                padding: 54px 40px;
            }
            // date - hide date
            .content-tile-date {
                display: none;   
            }

            // name
            // .content-tile-name {}

            // author - hide author
            .content-tile-author {
                display: none;
            }

            // description
            // .content-tile-description {}

            // cta
            .content-tile-cta {
                display: block;
                margin-top: 24px;
            }

            // no margin on last child
            [class^=content-tile] {
                color: inherit;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }   

    // make slider pagination dots area larger to be more clickable
    .slider-container {
        &.pagination-style-shortLines,
        &.pagination-style-dots {
            .tns-nav {
                max-width: none;
                button {
                    height: 24px;
                    margin: 0;
                    position: relative;
                    background: transparent;
                    opacity: 1;
                    border-radius: 0;

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        transition: background $transition-speed ease-out;
                    }

                    &.tns-nav-active,
                    &:hover:not(.tns-nav-active) {
                        transition: unset;
                        height: 24px;
                        background: transparent;
                    }

                    &.tns-nav-active {
                        height: 24px;

                        &:after {
                            background: $pure-black;
                        }
                    }
                }
            }
        }

        // dots 
        &.pagination-style-dots {
            .tns-nav {
                button {
                    width: 28px;

                    &:after {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                    }

                    &.tns-nav-active {
                        transform: scale(1.25);   
                    }

                    &.tns-nav-active,
                    &:hover:not(.tns-nav-active) {
                        width: 28px;
                    }
                }
            }
        }

        // short lines
        &.pagination-style-shortLines {
            .tns-nav {
                button {
                    width: 32px;

                    &:after {
                        width: 20px;
                        height: 2px;
                    }

                    &.tns-nav-active {
                        &:after {
                            height: 4px;
                        }
                    }

                    &.tns-nav-active,
                    &:hover:not(.tns-nav-active) {
                        width: 32px;
                    }
                }
            }
        }

        // dark-theme
        &.dark {
            &.pagination-style-shortLines,
            &.pagination-style-dots {
                .tns-nav {
                    button {
                        transition: unset;
                        background: transparent;

                        &:after {
                            background: rgba(0,0,0,0.5);
                        }
                    }
                }
            }
        }

        // light-theme
        &.light {
            background-color: $black;

            &.pagination-style-shortLines,
            &.pagination-style-dots {
                .tns-nav {
                    button {
                        transition: unset;
                        background: transparent;

                        &:after {
                            background: rgba(255,255,255,0.5);
                        }

                        &.tns-nav-active,
                        &:hover:not(.tns-nav-active) {
                            background: transparent;
                            &:after {
                                background: $white;
                            }
                        }
                    }
                }
            }
        }
    }
}
