@import "~org_citizen/components/contentTiles";

.content-tile {
    transition: .3s all ease;

    &:hover {
        opacity: 0.8;

        .content-tile-image-wrapper {
            &:after {
                content: none;
            }
        }
    }
    
    .content-tile-image-wrapper {
        margin-bottom: 20px;

        .content-tile-image {
            background-position-x: 0;
            background-position-y: 0;
            background-repeat: no-repeat;
            background-size: 100% auto;
        }
    }

    .content-tile-name {
        line-height: 1.6;

        @include media-breakpoint-up(sm) {
            font-size: 22px;
        }
    }

    .content-tile-author {
        font-size: $content-tile-description-font-size;
        text-transform: uppercase;
        display: block;
        font-weight: 300;
        margin-bottom: 15px;
    }
}

/** Alpina Specific Blog Content in PD Dynamic Content slider **/
.experience-commerce_layouts-sliderContent {
    .content-tile {
        /** Blog Name, Description, CTA **/
        .content-tile-body {
            background-color: $black;
            color: $white;

            // name
            .content-tile-name {
                color: $accent-red;
                margin-bottom: 24px;
            }
        }
    }
}
