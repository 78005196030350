@import "~core/components/categoryTiles";

.category-tile {
    &.text-placement-below {
        .category-tile-text {
            margin-top: 0;
        }
    }
    &.aspect-ratio-landscape .image-wrapper {
        @include aspect-ratio($aspect-ratio-four-three);
    }
    &.aspect-ratio-portrait .image-wrapper {
        @include aspect-ratio($aspect-ratio-three-four);
    }

    &.text-placement-inside-rotated {
        &::after {
            content: '';
        }

        &:hover {
            opacity: 1;
        }

        .category-tile-text {
            .primary-text {
                &:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6):not(.b1):not(.b2):not(.b3):not(.b4):not(.custom) {
                    font-size: 28px;
                    font-weight: 400;
                    color: $white;
                    text-transform: uppercase;
                    letter-spacing: 5.04px;
                    text-align: center;
                    z-index: 1;
                    transform: rotate(-90deg) translate(55%, -475%);

                    @media (min-width: 385px) {
                        transform: rotate(-90deg) translate(55%, -550%);
                    }

                    @media (min-width: 425px) {
                        transform: rotate(-90deg) translate(55%, -675%);
                    }
                    
                    @include media-breakpoint-up(sm) {
                        transform: rotate(-90deg) translate(55%, -900%);
                    }

                    @media (min-width: 625px) {
                        transform: rotate(-90deg) translate(55%, -1050%);
                    }

                    @media (min-width: 675px) {
                        transform: rotate(-90deg) translate(55%, -1150%);
                    }

                    @include media-breakpoint-up(md) {
                        transform: rotate(-90deg) translate(55%, -625%);
                    }

                    @media (min-width: 890px) {
                        transform: rotate(-90deg) translate(55%, -735%);
                    }
                    
                    @include media-breakpoint-up(lg) {
                        transform: rotate(-90deg) translate(55%, -360%);
                        font-size: 20px;
                    }

                    @include media-breakpoint-up(xl) {
                        transform: rotate(-90deg) translate(55%, -425%);
                        font-size: 28px;
                    }
                }
            }
        }
    }
}
