@import "~core/components/social";

// footer social media links
.social-links {
    .fa-x-twitter {
        font-size: 28px;
    }

    .fa-youtube-square {
        font-size: $footer-icon-size + round($footer-icon-size / 10);
    }
    
    .fa-linkedin-square {
        font-size: 33px;
    }
}