@import "~core/components/imageTextBlock";

.image-text-block {
    height: 100%;
    display: flex;
    flex-direction: column;
    
    .image-text-block-text {
        margin-top: 0;
    }

    .image-cropper {
        flex-grow: 1;
    
        .image-wrapper {
            height: 100%;
        }
    }

    &:hover {
        opacity: initial;
    }

    &.show-on-hover {
        .image-text-block-text {
            opacity: 0;
            visibility: hidden;
        }

        &:hover {
            .image-text-block-text {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &.text-placement-inside {
        &:after {
            content: none;
            background-color: transparent;
        }
    }

    .image-text-block-text {
        transition: opacity 200ms ease-in;
    }

    .image-text-block-text,
    &.text-placement-inside .image-text-block-text {

        &.text--black {
            color: $black;
        }

        .text-block {
            // text widths
            @include media-breakpoint-up(md) {
                &.width-1-4 {
                    max-width: 25%;
                }
                &.width-1-3 {
                    max-width: 33.3333%;
                }
                &.width-1-2 {
                    max-width: 50%;
                }
                &.width-2-3 {
                    max-width: 66.6666%;
                }
                &.width-3-4 {
                    max-width: 75%;
                }
                &.width-1 {
                    max-width: 100%;
                }
            }
        }
    }

    .animation-initial-state {
        &:not(.disable-mobile-animation) {
            opacity: 0;
            transition: opacity 200ms ease-in;
        }
        &.disable-mobile-animation {
            @include media-breakpoint-up(lg) {
                opacity: 0;
                transition: opacity 200ms ease-in;
            }
        }
    }
}
