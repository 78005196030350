@import "~core/components/quickView";

.quick-view-dialog {
    .modal-header {
        border-bottom: 0;
        position: absolute;
        z-index: 1;
        width: 100%;
        background: transparent;

        .close {
            right: 10px;
            top: 10px;
            z-index: 1;
            opacity: 1;
            transition: all 300ms ease-out;

            &:before {
                font-size: 30px;
            }
        }
        
        .modal-title,
        .full-pdp-link{
            display: none;
        }
    }

    .modal-body {
        padding: 0;

        .primary-images {
            background-color: $brand-secondary;
            padding: 0;

            display: flex;

            .primary-images-backdrop {
                align-self: center;
                justify-self: center;
            }
        }

        .primary-images,
        .product-data {
            @include media-breakpoint-up(md) {
                padding-top: 44px;
                padding-bottom: 44px;
            }
        }

        .full-pdp-link {
            font-weight: 600;   
        }

        .product-detail {
            .promotions .promotion {
                color: $info;
    
                &:last-of-type {
                    padding-bottom: 15px;
                }
            }

            .primary-images-backdrop {       
                @include media-breakpoint-up(md) {
                    max-height: none;
                    position: static;
                    top: auto;
                    width: 100%;
                }
            }
        }
    }

    .modal-footer {
        border-top: none;
        display: none;
    }
}

.modal {
    &:not(.show) {
        .close {
            opacity: 0;
        }
    }
}
