@import "~core/components/modal";

.modal {
    // prevents misalignment of video modals in mobile viewport
    // https://stackoverflow.com/questions/32862394/bootstrap-modals-keep-adding-padding-right-to-body-after-closed
    padding-right: 0px !important;
}

.modal-launch-button {
    padding: $modal-launch-button-padding-top $modal-launch-button-padding-right $modal-launch-button-padding-bottom $modal-launch-button-padding-left;
    font-size: $modal-launch-button-font-size;
    font-weight: $modal-launch-button-font-weight;
    font-family: $modal-launch-button-font-family;
}

// gift card balance modal
.modal {
    &.gift-card-balance-modal {
        .modal-content {
            background-color: $dark-gray;
        }

        .modal-header,
        .modal-body,
        .modal-footer {
            background-color: inherit;
            color: inherit;
        }
        
        .modal-header {
            .close {
                color: inherit;
                margin-top: 10px;
                margin-left: auto;
                &::before {
                    color: inherit;
                    font-size: 24px;
                }
            }
        }
        
        .modal-body {
            .gift-card-balance-image {
                margin-bottom: 16px;
            }
            .gift-card-balance-amount {
                font-size: 36px;
                text-align: center;
                font-family: $primary-font;
                display: block;
                width: 100%;
                margin-top: 10px;
            }
        }
    }

    &.modal-reviews {
        .modal-header {
            background-color: $white;
        }   
    }

    &.show {
        &.product-option-modal {
            &.fade {
                .modal-dialog {
                    transform: translate(0, 0);
                }    
            }
        }    
    }

    &.product-option-modal {
        &.fade {
            .modal-dialog {
                transform: translate(100%, 0);
            }    
        }

        .product-option {
            text-align: center;
        }
        
        .options-select {
            background-color: $white;
        }

        .modal-dialog {
            background-color: $product-option-background-color;
            height: 100vh;
            margin: 0 0 0 auto;

            .modal-content {
                height: 100%;
                display: flex;
                flex-direction: column;

                .modal-header {
                    background-color: $product-option-modal-header-background-color;
                }

                .modal-body {
                    flex-grow: 1;
                    background-color: transparent;
                }
            }
        }
    }
}
