@import '~core/components/pricing';

.product-detail,
.quick-view-dialog {
    .price {
        .strike-through {
            display: inline-block;
            font-weight: 600;
            color: $product-strike-through-price-color;
            text-decoration: line-through;

        }

        del {
            text-decoration: none;
        }

        del ~ .sales {
            margin-right: 0;
            float: none;
            font-weight: 600;
        }
    }
}